import { Button, Container, Title, Text, Group, Flex } from '@mantine/core'
import { useState, useEffect } from 'react'
import { isLocalhost, isPR } from 'utils/localhost'
import { Trans, useTranslation } from 'react-i18next'
import { useTenantNotFoundStyles } from './tenant-not-found.styles'

export function TenantNotFound() {
  const { classes } = useTenantNotFoundStyles()
  const { t } = useTranslation()
  const [currentTenant, setCurrentTenant] = useState<string | null>(null)

  useEffect(() => {
    // Get the attempted tenant slug
    const tenantSlug = isLocalhost() || isPR() ? localStorage.getItem('tenant-slug') : location.host.split('.')[0]
    setCurrentTenant(tenantSlug || '')
  }, [])

  return (
    <Container className={classes.root}>
      <Title className={classes.title}>{t('error.tenant-not-found.title')}</Title>
      <Flex align="center" justify="center">
        <Trans
          i18nKey="error.tenant-not-found.message"
          components={{
            b: <Text ml={5} mr={5} fw={700} />,
          }}
          values={{ tenant: currentTenant }}
        />
      </Flex>

      <Text size="md" className={classes.description}>
        {t('error.tenant-not-found.help-text')}
      </Text>
    </Container>
  )
}
