import { Box, Button, Drawer, Group, Text, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { Category, CategoryFactory } from 'api/domain/entities/category'
import { useDeleteCategory, useUpdateCategory } from 'api/query/category'
import { DeleteCategoryModal } from 'components/modal/delete.category'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface EditCategoryProps {
  opened: boolean
  close: () => void
  category?: Category
}

type PartialCategory = {
  name?: string
  description?: string
}

export function EditCategoryDrawer({ opened, close, category }: EditCategoryProps) {
  const { t } = useTranslation()
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false)
  const { mutateAsync: updateCategory } = useUpdateCategory()
  const { mutateAsync: deleteCategory } = useDeleteCategory()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const form = useForm({
    initialValues: {
      ...category,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.name || values.name.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.description || values.description.length < 1) {
        errors.description = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(partialCategory: PartialCategory) {
    setIsSubmitting(true)
    const updateCategoryDto = CategoryFactory.toDto(partialCategory)
    try {
      await updateCategory(updateCategoryDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    close()
    form.reset()
    setIsSubmitting(false)
  }

  async function onDelete() {
    if (!category) return

    setIsDeleting(true)

    const deleteCategoryDto = CategoryFactory.toDto(category)
    try {
      await deleteCategory(deleteCategoryDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    closeDeleteModal()
    close()
    form.reset()

    setIsDeleting(false)
  }

  useEffect(() => {
    form.setValues({ ...category })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('category.edit')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('category.edit-description')}</Text>
              <TextInput
                mt={10}
                withAsterisk
                w="100%"
                label={t('category.fields.name')}
                {...form.getInputProps('name')}
              />
              <Textarea
                w="100%"
                withAsterisk
                mt={10}
                rows={3}
                label={t('category.fields.description')}
                {...form.getInputProps('description')}
              />
            </Box>

            <Box
              pt={20}
              display="flex"
              sx={{
                borderTop: '1px solid #cecece',
                justifyContent: 'space-between',
              }}
              flex={0}
            >
              <Button
                disabled={!form.isValid() || isSubmitting || isDeleting}
                loading={isDeleting}
                color="red"
                size="xs"
                onClick={openDeleteModal}
              >
                {t('common.delete')}
              </Button>
              <Button
                disabled={!form.isValid() || isSubmitting || isDeleting}
                loading={isSubmitting}
                type="submit"
                size="xs"
              >
                {t('common.update')}
              </Button>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>

      <DeleteCategoryModal
        isOpen={deleteModalOpened}
        onClose={closeDeleteModal}
        category={category}
        onDelete={onDelete}
      />
    </>
  )
}
