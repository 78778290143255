import { Select, SelectProps } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useGetUserMe, useUpdateUserMe } from 'api/query/user'
import { VisibilityStatus } from 'api/domain/entities/visibility'

export function CommunicationLanguageSelector(props: Omit<SelectProps, 'data' | 'value' | 'onChange'>) {
  const { t } = useTranslation()
  const { data: user } = useGetUserMe()
  const updateUser = useUpdateUserMe()

  const handleChange = (value: string | null) => {
    if (value) {
      updateUser.mutate({
        ...user,
        visibilityStatus: user?.visibilityStatus || VisibilityStatus.PRIVATE,
        communicationLanguage: value,
      })
    }
  }

  return (
    <Select
      label={t('user.communicationLanguage')}
      description={t('user.communicationLanguageDesc')}
      value={user?.communicationLanguage || 'en'}
      onChange={handleChange}
      data={[
        { value: 'en', label: t('languages.english') },
        { value: 'fr', label: t('languages.french') },
      ]}
      {...props}
    />
  )
}
