import { Affix, ActionIcon, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus } from '@tabler/icons-react'
import { DocumentList } from 'components/tenant/documents/document-list'
import { UploadDocumentModal } from 'components/tenant/documents/upload-document-modal'
import { useTranslation } from 'react-i18next'

export function DocumentsPage() {
  const { t } = useTranslation()
  const [opened, { open, close }] = useDisclosure(false)

  return (
    <>
      <Title mb={30} order={2}>
        {t('document.title')}
      </Title>
      <DocumentList />
      <UploadDocumentModal opened={opened} onClose={close} />

      <Affix bottom={20} right={20}>
        <ActionIcon radius={100} display={opened ? 'none' : 'block'} size="xl" onClick={open}>
          <IconPlus size={17} />
        </ActionIcon>
      </Affix>
    </>
  )
}
