import { Box, Button, Group, Modal, Text, Stack, Textarea, Select, Alert } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { IconInfoCircle } from '@tabler/icons-react'
import { PaymentType } from 'api/domain/entities/membership/payment-type'
import { usePayInvoiceOutOfBand } from 'api/query/invoice'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface PayInvoiceOutOfBandProps {
  opened: boolean
  onClose: () => void
  invoiceId?: string
}

interface PaymentFormValues {
  note: string
  paymentType: PaymentType
  lastPaidAt: Date | null
}

export function PayInvoiceOutOfBandModal({ opened, onClose, invoiceId }: PayInvoiceOutOfBandProps) {
  const { t } = useTranslation()
  const { mutateAsync: payInvoiceOutOfBand, isPending: isLoading } = usePayInvoiceOutOfBand()

  const form = useForm<PaymentFormValues>({
    initialValues: {
      note: '',
      paymentType: PaymentType.MANUAL,
      lastPaidAt: new Date(),
    },
    validate: {
      note: (value) => (value.trim().length === 0 ? t('invoice.payment-note-placeholder') : null),
    },
  })

  // Reset form state when modal opens/closes
  useEffect(() => {
    if (!opened) {
      form.reset()
    }
  }, [opened, form])

  const handleSubmit = async (values: PaymentFormValues) => {
    await payInvoiceOutOfBand({
      invoiceId,
      note: values.note,
      paymentType: values.paymentType,
      lastPaidAt: values.lastPaidAt ? values.lastPaidAt.toISOString() : undefined,
    })
    onClose()
  }

  return (
    <Modal title={t('invoice.pay')} size="lg" onClose={onClose} opened={opened}>
      <Modal.Body>
        <Alert icon={<IconInfoCircle size={16} />} color="blue" mb={20}>
          <Text size="sm">{t('invoice.manual-payment-explanation')}</Text>
        </Alert>

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack gap="md">
            <Select
              {...form.getInputProps('paymentType')}
              label={t('invoice.payment-type')}
              data={[
                { value: PaymentType.MANUAL, label: t('membership.paid-by.manual') },
                { value: PaymentType.CHECK, label: t('membership.paid-by.check') },
              ]}
            />

            <DateInput
              {...form.getInputProps('lastPaidAt')}
              label={t('invoice.payment-date')}
              placeholder={t('member.membership.date-placeholder')}
              clearable
            />

            <Textarea
              {...form.getInputProps('note')}
              label={t('invoice.payment-note')}
              placeholder={t('invoice.payment-note-placeholder')}
              minRows={3}
              withAsterisk
            />
          </Stack>

          <Box mt={30} flex={0}>
            <Group justify="space-between">
              <Button color="red" variant="light" disabled={isLoading} size="xs" onClick={onClose} type="button">
                {t('common.cancel')}
              </Button>
              <Button type="submit" loading={isLoading} disabled={!form.isValid()} size="xs" variant="light">
                {t('invoice.pay')}
              </Button>
            </Group>
          </Box>
        </form>
      </Modal.Body>
    </Modal>
  )
}
