import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Modal,
  Paper,
  Select,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useGetUserMe, useUpdateUserMe } from 'api/query/user'
import { Loading } from 'components/loading/loading'
import { CommunicationLanguageSelector } from './communication-language-selector'
import { Language, NS } from 'i18n'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface UserProfileModalProps {
  isOpen: boolean
  onClose: () => void
}

export function UserProfileModal({ isOpen, onClose }: UserProfileModalProps) {
  const { t, i18n } = useTranslation()
  const { data: user, isLoading: isLoadingUser } = useGetUserMe()
  const { mutateAsync: updateUser } = useUpdateUserMe()
  const form = useForm({
    mode: 'uncontrolled',
  })
  const [isLoading, setIsLoading] = useState(false)

  const currentLanguage = i18n.language.split('-')[0]
  const languages = Object.values(Language).map((value) => ({ value, label: t(`language.${value}`) }))

  useEffect(() => {
    if (user) {
      form.setValues({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  async function onSubmit(values: any) {
    setIsLoading(true)
    const { firstName, lastName } = values
    await updateUser({ firstName, lastName })
    setIsLoading(false)
    onClose()
  }

  return (
    <Modal title={t('common.profile')} size="lg" opened={isOpen} onClose={onClose}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Modal.Body
          mih={500}
          display="flex"
          sx={{
            flexDirection: 'column',
          }}
        >
          {isLoadingUser && <Loading size="lg" height="500px" />}
          {!isLoadingUser && (
            <Box flex={1}>
              <Center mb={20}>
                <Flex direction="column" align="center">
                  {user?.profilePicture ? (
                    <Avatar size={100} src={user.profilePicture} alt={user.fullName} />
                  ) : (
                    <Avatar size={100}>{user?.initials}</Avatar>
                  )}
                  <Box>
                    <Text fw={700} size="lg" mt={10}>
                      {user?.fullName}
                    </Text>
                  </Box>
                </Flex>
              </Center>
              <Flex mb={10} gap={10}>
                <TextInput label={t('user.fields.firstName')} w="100%" {...form.getInputProps('firstName')} />
                <TextInput label={t('user.fields.lastName')} w="100%" {...form.getInputProps('lastName')} />
              </Flex>

              <TextInput mb={10} label={t('user.fields.email')} disabled {...form.getInputProps('email')} />

              <Title order={4} mt={30} mb={10}>
                {t('user.preferences')}
              </Title>
              <Divider my="sm" />

              <Box mb={20}>
                <Select
                  label={t('common.language')}
                  description={t('common.language')}
                  data={languages}
                  value={currentLanguage}
                  onChange={(value) => value && i18n.changeLanguage(value)}
                  mb={20}
                />

                <CommunicationLanguageSelector />
              </Box>
            </Box>
          )}

          <Box flex={0}>
            <Group justify="right">
              <Button
                disabled={!form.isValid() || isLoading}
                loading={isLoading}
                size="sm"
                type="submit"
                variant="light"
              >
                {t('common.update')}
              </Button>
            </Group>
          </Box>
        </Modal.Body>
      </form>
    </Modal>
  )
}
