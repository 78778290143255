import { PublicMemberDto } from 'api/dto/member/public-member-dto'
import { Contact } from '../contact/contact'
import { Address } from './member'

export class PublicMember {
  id: string
  name: string
  address: Address
  phone: string
  logo?: string
  contact: Contact

  users?: Contact[]

  constructor(publicMember: PublicMemberDto) {
    this.id = publicMember.id
    this.name = publicMember.name
    this.address = publicMember.address
    this.phone = publicMember.phone
    this.logo = publicMember.logo
    this.contact = new Contact(publicMember.contact)
    this.users = publicMember.users?.map((u) => new Contact(u))
  }
}
