import { Member } from 'api/domain/entities/member'
import { MemberDto } from './member-dto'
import { ContactFactory } from '../contact/contact-factory'

export class MemberFactory {
  static fromDto(memberDto: MemberDto) {
    return new Member(memberDto)
  }

  static toDto(member: Partial<Member>): Partial<MemberDto> {
    return {
      id: member.id,
      tenantId: member.tenantId,
      name: member.name,
      address: member.address,
      phone: member.phone?.toString(),
      memberSince: member.memberSince?.toISOString(),
      categoryIds: member.categoryIds,
      contact: member.contact ? ContactFactory.toDto(member.contact) : undefined,
      status: member.status,
      accountingEmail: member.accountingEmail,
      logo: member.logo,
      note: member.note,
      createdAt: member.createdAt,
      discount: member.discount,
      visibilityStatus: member.visibilityStatus,
      externalId: member.externalId,
    }
  }
}
