/* eslint-disable @typescript-eslint/naming-convention */
import { showNotification } from '@mantine/notifications'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'config/axios'
import { useCallback, useState } from 'react'
import { globalQueryClient } from './client'
import { PlanDto } from 'api/dto'
import { Plan } from 'api/domain/entities/plan'
import { isStripeAddressError, getAddressErrorMessage } from 'utils/stripe-errors'

export const useListPlans = () =>
  useQuery<PlanDto[], Error, Plan[]>({
    queryKey: ['plans'],
    queryFn: async () => {
      const response = await axios.get('/plans')
      return response.data
    },
    select: useCallback(
      (plans: PlanDto[]) => plans.map((p) => new Plan(p, plans)).sort((a, b) => a.order - b.order),
      [],
    ),
  })

export const useCreatePlan = () =>
  useMutation({
    mutationFn: async (plan: Partial<PlanDto>) => {
      const response = await axios.post('/plans', plan)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Plan created',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['plans'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['plans'],
      })
    },
  })

export const useUpdatePlan = () =>
  useMutation({
    mutationFn: async (plan: Partial<PlanDto>) => {
      const response = await axios.put(`/plans/${plan.id}`, plan)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Plan updated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['plans'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['plans'],
      })
    },
  })

export const useUpdatePlanOrder = () =>
  useMutation({
    mutationFn: async (plan: Partial<PlanDto>) => {
      const response = await axios.put(`/plans/${plan.id}`, plan)
      return response.data
    },
    onMutate: ({ id, order }: Partial<Plan>) => {
      const previousPlans = globalQueryClient.getQueryData<PlanDto[]>(['plans'])
      if (previousPlans) {
        globalQueryClient.setQueryData<PlanDto[]>(
          ['plans'],
          previousPlans.map((p) => (p.id === id ? { ...p, order: order || 0 } : p)),
        )
      }
    },
  })

export const invalidatePlans = () => {
  return globalQueryClient.invalidateQueries({
    queryKey: ['plans'],
  })
}

export const useDeletePlan = () =>
  useMutation({
    mutationFn: async (plan: Partial<PlanDto>) => {
      const response = await axios.delete(`/plans/${plan.id}`)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Plan deleted',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['plans'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['plans'],
      })
    },
  })

export const useSubscribePlan = () => {
  // Just track if there's an address error
  const [isAddressError, setIsAddressError] = useState(false)

  const mutation = useMutation({
    mutationFn: async ({ planId, priceId, memberId }: { planId: string; priceId: string; memberId?: string }) => {
      const response = await axios.post('/payment/checkout', { planId, priceId, memberId })
      // we receive a redirect url to the payment gateway
      //we should redirect the user to this url
      window.open(response.data, '_blank')
      return { url: response.data }
    },
    onSuccess: () => {
      setIsAddressError(false) // Clear any previous errors
      showNotification({
        title: 'Success',
        message: 'Subscription created',
        color: 'green',
      })
    },
    onError: (error) => {
      const hasAddressError = isStripeAddressError(error)
      setIsAddressError(hasAddressError)

      // Show notification with user-friendly message
      showNotification({
        title: hasAddressError ? 'Address Validation Error' : 'Payment Error',
        message: hasAddressError ? getAddressErrorMessage() : error.message,
        color: 'red',
      })
    },
    onSettled: (_, __, { memberId }) => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['members', memberId],
      })
    },
  })

  return {
    ...mutation,
    isAddressError,
  }
}

export const useManageSubscription = () =>
  useMutation({
    mutationFn: async ({ memberId }: { memberId?: string }) => {
      const response = await axios.post(
        '/payment/manage',
        {},
        {
          params: {
            memberId,
          },
        },
      )
      // we receive a redirect url to the payment gateway
      //we should redirect the user to this url
      window.open(response.data, '_blank')
    },
  })

export const useUpdateSubscription = () => {
  // Just track if there's an address error
  const [isAddressError, setIsAddressError] = useState(false)

  const mutation = useMutation({
    mutationFn: async ({ memberId, planId, priceId }: { memberId: string; planId: string; priceId: string }) => {
      await axios.post('/payment/update', { memberId, planId, priceId })
    },
    onSuccess: (_, { memberId }) => {
      setIsAddressError(false) // Clear any previous errors
      showNotification({
        title: 'Success',
        message: 'Subscription updated',
        color: 'green',
      })

      void globalQueryClient.invalidateQueries({
        queryKey: ['members', memberId],
      })
    },
    onError: (error) => {
      const hasAddressError = isStripeAddressError(error)
      setIsAddressError(hasAddressError)

      // Show notification with user-friendly message
      showNotification({
        title: hasAddressError ? 'Address Validation Error' : 'Payment Error',
        message: hasAddressError ? getAddressErrorMessage() : error.message,
        color: 'red',
      })
    },
  })

  return {
    ...mutation,
    isAddressError,
  }
}
