/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Text, Box, Center, Switch, Space, Title, Alert, Group, Badge, Paper } from '@mantine/core'
import { IconFileInvoice, IconAddressBook, IconAlertTriangle } from '@tabler/icons-react'
import { Plan } from 'api/domain/entities/plan'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { MembershipStatus } from 'api/domain/entities/membership/membership-status'
import { useGetMember, useUpdateMembershipStatus } from 'api/query/member'
import { useListPlans, useSubscribePlan, useUpdateSubscription } from 'api/query/plan'
import { Loading } from 'components/loading/loading'
import { ExternalMembershipModal } from 'components/modal/external-membership-modal'
import { UpdateSubscriptionModal } from 'components/modal/update-subscription'
import { MembershipStatusChangeModal } from 'components/modal/membership-status-change-modal'
import { MembershipFeedback } from 'components/plan/membership-feedback'
import { PlanTilesContainer } from 'components/plan/plan-tiles-container'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { DateTimeFormat } from 'utils/date-time'
import { PaymentType } from 'api/domain/entities/membership/payment-type'
import { getAddressErrorMessage } from 'utils/stripe-errors'

interface MembershipProps {
  redirectToInfos: {
    href?: string
    byTab?: () => void
  }
}

export function Membership({ redirectToInfos }: MembershipProps) {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { data: member } = useGetMember(id || user?.memberId)
  const { data: plans, isLoading: isLoadingPlans } = useListPlans()
  const [intervalType, setIntervalType] = useState(PriceInterval.MONTHLY)
  const [showReloadAlert, setShowReloadAlert] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>(undefined)
  const [showExternalModal, setShowExternalModal] = useState(false)
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false)
  const [showAddressError, setShowAddressError] = useState(false)

  const {
    mutateAsync: subscribeToPlan,
    isPending: isSubscribing,
    isAddressError: subscribeAddressError,
  } = useSubscribePlan()
  const {
    mutateAsync: updateSubscription,
    isPending: isUpdating,
    isAddressError: updateAddressError,
  } = useUpdateSubscription()
  const { mutateAsync: updateMembershipStatus, isPending: isUpdatingStatus } = useUpdateMembershipStatus()

  // Update address error state when subscription errors occur
  useEffect(() => {
    setShowAddressError(subscribeAddressError || updateAddressError)
  }, [subscribeAddressError, updateAddressError])

  const handleStatusChange = async (
    newStatus: MembershipStatus,
    note: string,
    startDate?: string,
    endDate?: string,
    planId?: string,
    priceId?: string,
    lastPaidAt?: string,
    paymentType = PaymentType.MANUAL,
  ) => {
    if (!member) return

    try {
      await updateMembershipStatus({
        memberId: id || member.id,
        operation: newStatus === MembershipStatus.CANCELED ? 'cancel' : 'markPaid',
        note,
        startDate,
        endDate,
        planId,
        priceId,
        lastPaidAt,
        paymentType,
      })

      // The modal state is now managed by the component itself
      setShowStatusChangeModal(false)
    } catch (error) {
      console.error('Failed to update membership status:', error)
    }
  }

  const membership = member?.membership
  const currentPlanId = membership?.planId
  const currentPlan = plans?.find((p) => p.id === currentPlanId)
  const currentPriceId = membership?.priceId
  const currentPrice = currentPlan
    ? currentPlan.monthlyPrice?.paymentProviderId === currentPriceId
      ? currentPlan.monthlyPrice
      : currentPlan.yearlyPrice?.paymentProviderId === currentPriceId
        ? currentPlan.yearlyPrice
        : undefined
    : undefined
  const newPlan = plans?.find((p) => p.id === membership?.newPlanId)

  useEffect(() => {
    if (membership && plans) {
      const plan = plans.find((p) => p.id === membership.planId)
      if (!plan) {
        return
      }

      // Determine the interval type based on price ID
      if (plan.monthlyPrice?.paymentProviderId === membership.priceId) {
        setIntervalType(PriceInterval.MONTHLY)
      } else if (plan.yearlyPrice?.paymentProviderId === membership.priceId) {
        setIntervalType(PriceInterval.YEARLY)
      }
    }
  }, [membership, plans])

  if (isLoadingPlans) {
    return <Loading size="lg" />
  }

  if (user?.isMemberUser()) {
    return (
      <Center display="flex" sx={{ flexDirection: 'column' }}>
        <Title ta="center" maw={700}>
          {t('member.plans.not-allowed')}
        </Title>
        <Title ta="center" maw={600}>
          {t('member.plans.not-allowed-contact')}
        </Title>
      </Center>
    )
  }

  return (
    <Box pos="relative" mt={30}>
      <MembershipFeedback />

      {!showAddressError && (
        <Alert
          mb={20}
          color="red"
          title="Address Validation Error"
          icon={<IconAlertTriangle />}
          withCloseButton
          onClose={() => setShowAddressError(false)}
        >
          <Text>{getAddressErrorMessage()}</Text>
          {member && (
            <Button
              leftSection={<IconAddressBook size={16} />}
              variant="light"
              color="blue"
              mt={10}
              onClick={() => {
                const { href, byTab } = redirectToInfos
                if (href) {
                  navigate(href)
                } else {
                  byTab?.()
                }
              }}
            >
              <Text>{t('member.plans.update-address')}</Text>
            </Button>
          )}
        </Alert>
      )}

      {showReloadAlert && (
        <Alert mb={20}>
          <Center>
            <Text size="md">{t('member.plans.alert-title')}</Text>

            <Button
              data-testid="reload-button"
              ml={20}
              size="xs"
              variant="link"
              onClick={() => {
                setShowReloadAlert(false)
                window.location.reload()
              }}
            >
              {t('member.plans.reload')}
            </Button>
          </Center>
        </Alert>
      )}

      <Title ta="center">{t('member.plans.choose')}</Title>

      <Center mt={20} mb={currentPlan ? 10 : 60}>
        <Text size="md" mr={5} fw={intervalType === PriceInterval.MONTHLY ? 900 : 300}>
          {t('plan.interval.monthly')}
        </Text>
        <Switch
          size="lg"
          checked={intervalType === PriceInterval.YEARLY}
          onChange={(e) => setIntervalType(e.target.checked ? PriceInterval.YEARLY : PriceInterval.MONTHLY)}
          styles={{
            track: {
              padding: 10,
            },
          }}
        />
        <Text size="md" ml={5} fw={intervalType === PriceInterval.YEARLY ? 900 : 300}>
          {t('plan.interval.yearly')}
        </Text>
      </Center>

      {currentPlan && (
        <Text ta="center" mb={40}>
          {membership?.subscriptionScheduleId && (
            <Trans
              i18nKey="plan.change-plan-next-billing-period"
              components={{
                space: <Space />,
                newPlan: <Text span fw={700} />,
                date: <Text span fw={700} />,
              }}
              values={{
                nextBillingPeriod: membership?.nextBillingPeriod?.toFormat(DateTimeFormat.Human),
                newPlan: newPlan?.name,
              }}
            />
          )}
          <Trans
            i18nKey="plan.current-plan"
            components={{
              plan: <Text ml={3} mr={3} span inherit fw={700} />,
              price: <Text ml={3} span inherit fw={700} />,
              interval: <Text size="xs" mr={3} span fw={400} c="gray.7" inherit />,
              space: <Space />,
            }}
            values={{
              plan: currentPlan?.name,
              price: currentPrice?.amount,
              interval: t(`plan.interval.${currentPrice?.interval.toLowerCase()}`).toLowerCase(),
            }}
          />
          {membership?.hasUnpaidMembership ? (
            <Trans
              i18nKey="plan.unpaid-membership"
              components={{
                inactive: <Text span fw={700} inherit />,
              }}
            />
          ) : (
            <Trans
              i18nKey="plan.next-billing-period"
              components={{
                date: <Text span fw={700} inherit />,
              }}
              values={{
                date: membership?.nextBillingPeriod?.toFormat(DateTimeFormat.Human),
              }}
            />
          )}

          {membership?.invoiceUrl && (
            <>
              <Space h="md" />
              <Button
                component="a"
                href={membership.invoiceUrl}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                variant={membership.hasUnpaidMembership ? 'filled' : 'light'}
              >
                <>
                  <IconFileInvoice size={16} style={{ marginRight: 5 }} />
                  <span>{membership.hasUnpaidMembership ? t('invoice.pay') : t('invoice.view')}</span>
                </>
              </Button>
            </>
          )}

          {/* Action button for external memberships - only visible to tenant admins */}
          {user?.isTenantAdmin() && membership && membership.isExternal && (
            <>
              <Space h="md" />
              <Group justify="center" gap="sm">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setShowStatusChangeModal(true)
                  }}
                >
                  {t('member.membership.button.manage')}
                </Button>
              </Group>
            </>
          )}
        </Text>
      )}

      <Box mt={30}>
        <PlanTilesContainer
          plans={plans || []}
          currentPlan={currentPlan}
          currentPriceId={currentPriceId}
          memberId={id || member?.id}
          interval={intervalType}
          isExternalMembership={membership?.isExternal}
          selectPlan={(plan) => {
            // Check if this is an external member
            if (membership?.isExternal) {
              setSelectedPlan(plan)
              setShowExternalModal(true)
            } else {
              setSelectedPlan(plan)
            }
          }}
        />
      </Box>
      <UpdateSubscriptionModal
        isOpen={!!selectedPlan && !showExternalModal}
        onClose={() => {
          setSelectedPlan(undefined)
          setShowAddressError(false) // Clear any errors when closing the modal
        }}
        loading={isSubscribing || isUpdating}
        onUpdatePlan={async (plan) => {
          const priceWithInterval = plan.getPriceWithInterval(intervalType)

          if (!priceWithInterval || !member) {
            return
          }

          if (currentPlan) {
            await updateSubscription({
              planId: plan.id,
              priceId: priceWithInterval.paymentProviderId,
              memberId: id || member.id,
            })
          } else {
            await subscribeToPlan({
              planId: plan.id,
              priceId: priceWithInterval.paymentProviderId,
              memberId: id || member.id,
            })
            setShowReloadAlert(true)
          }
        }}
        currentPlan={currentPlan}
        plan={selectedPlan}
        plans={plans}
      />

      <ExternalMembershipModal
        isOpen={showExternalModal}
        onClose={() => {
          setShowExternalModal(false)
          setSelectedPlan(undefined)
          setShowAddressError(false) // Clear any errors when closing the modal
        }}
        loading={isSubscribing}
        onSubscribePlan={async (plan) => {
          const priceWithInterval = plan.getPriceWithInterval(intervalType)

          if (!priceWithInterval || !member) {
            return
          }

          await subscribeToPlan({
            planId: plan.id,
            priceId: priceWithInterval.paymentProviderId,
            memberId: id || member.id,
          })
          setShowReloadAlert(true)
        }}
        currentPlan={currentPlan}
        plan={selectedPlan}
      />

      {/* Use the consolidated status change modal component */}
      <MembershipStatusChangeModal
        isOpen={showStatusChangeModal}
        onClose={() => {
          setShowStatusChangeModal(false)
        }}
        onStatusChange={handleStatusChange}
        isLoading={isUpdatingStatus}
        currentPlan={currentPlan}
        plans={plans}
      />
    </Box>
  )
}
