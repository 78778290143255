import { Group, Stack, Box, Text, Image, Divider, Breadcrumbs, Anchor, Table, Center } from '@mantine/core'
import { usePublicMember } from 'api/query/public'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import classes from './public-member-details.module.css'
import { DateTimeFormat } from 'utils/date-time'
import { Loading } from 'components/loading/loading'
import { LanguageSelector } from 'components/language/language-selector'
import { useMemo } from 'react'
import { IconBuilding } from '@tabler/icons-react'

export function PublicMemberDetails() {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data: member, isLoading } = usePublicMember(id)

  if (isLoading) {
    return <Loading size="lg" />
  }

  const breadcrumbItems = [
    { title: t('public.nav.members'), href: '/public-info/members' },
    { title: member?.name || '', href: `/public-info/members/${id}` },
  ].map((item, index) => (
    <Anchor component={Link} to={item.href} key={index}>
      {item.title}
    </Anchor>
  ))

  return (
    <Stack mt={30} gap="lg" maw={1200} mx="auto">
      <Group justify="space-between" align="center">
        <Breadcrumbs>{breadcrumbItems}</Breadcrumbs>
        <LanguageSelector />
      </Group>

      {member && (
        <Stack gap="lg">
          {/* Company Profile Section */}
          <Box className={classes.section}>
            <Text className={classes.sectionTitle}>{t('member.modal.companyProfile')}</Text>
            <Group align="flex-start" gap="xl">
              <Box w={120} h={120}>
                {member.logo ? (
                  <Image src={member.logo} alt={member.name} radius="md" fit="contain" />
                ) : (
                  <Center h="100%" bg="gray.1" style={{ borderRadius: 8 }}>
                    <IconBuilding size={48} color="gray" />
                  </Center>
                )}
              </Box>
              <Stack gap="xs" style={{ flex: 1 }}>
                <Group justify="space-between" align="flex-start">
                  <Stack gap={4}>
                    <Text size="xl" fw={600}>
                      {member.name}
                    </Text>
                  </Stack>
                </Group>
                <Divider />
                <Group grow align="flex-start">
                  <Stack gap="xs">
                    <Text className={classes.infoLabel}>{t('member.modal.address')}</Text>
                    <Text className={classes.infoValue}>{member.address.formatted}</Text>
                  </Stack>
                  <Stack gap="xs">
                    <Text className={classes.infoLabel}>{t('member.modal.contact')}</Text>
                    <Text className={classes.infoValue}>{member.phone}</Text>
                  </Stack>
                </Group>
              </Stack>
            </Group>
          </Box>

          {/* Primary Contact Section */}
          {member.contact && (
            <Box className={classes.section}>
              <Text className={classes.sectionTitle}>{t('member.modal.primaryContact')}</Text>
              <Group grow>
                <Stack gap="xs">
                  <Text className={classes.infoLabel}>{t('member.modal.name')}</Text>
                  <Text className={classes.infoValue}>{`${member.contact.firstName} ${member.contact.lastName}`}</Text>
                </Stack>
                {member.contact.jobTitle && (
                  <Stack gap="xs">
                    <Text className={classes.infoLabel}>{t('member.modal.position')}</Text>
                    <Text className={classes.infoValue}>{member.contact.jobTitle}</Text>
                  </Stack>
                )}
                <Stack gap="xs">
                  <Text className={classes.infoLabel}>{t('member.modal.email')}</Text>
                  <Text className={classes.infoValue}>{member.contact.email}</Text>
                </Stack>
              </Group>
            </Box>
          )}

          {/* Additional Contacts Section */}
          {member.users && member.users.length > 0 && (
            <Box className={classes.section}>
              <Text className={classes.sectionTitle}>{t('member.modal.contacts')}</Text>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>{t('member.modal.name')}</Table.Th>
                    <Table.Th>{t('member.modal.email')}</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {member.users.map((contact, index) => (
                    <Table.Tr key={index}>
                      <Table.Td>{`${contact.firstName} ${contact.lastName}`}</Table.Td>
                      <Table.Td>{contact.email}</Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  )
}
