export enum UserSource {
  IMPORTED = 'imported',
  ADMIN = 'admin',
  MEMBER = 'member',
  SELF_REGISTERED = 'self-registered',

  // TODO: Remove these legacy sources
  LEGACY_MEMBRICS = 'Membrics',
  LEGACY_WILD_APRICOT = 'WildApricot',
}

export enum MemberSource {
  IMPORTED = 'imported',
  ADMIN = 'admin',
  SELF_REGISTERED = 'self-registered',
}
