import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import axios, { AxiosResponse } from 'axios'
import { PublicMemberDto } from 'api/dto/member/public-member-dto'
import { PublicMember } from 'api/domain/entities/member/public-member'
import { PlanDto } from 'api/dto'
import { Plan } from 'api/domain/entities/plan'

export const usePublicMembers = () => {
  return useQuery<AxiosResponse<PublicMemberDto[]>, Error, PublicMember[]>({
    queryKey: ['public', 'members'],
    queryFn: async () => axios.get<PublicMemberDto[]>('/public/members'),
    select: useCallback((res: AxiosResponse<PublicMemberDto[]>) => res.data.map((m) => new PublicMember(m)), []),
    staleTime: Infinity,
  })
}

export const usePublicMember = (id?: string) => {
  return useQuery<AxiosResponse<PublicMemberDto>, Error, PublicMember>({
    queryKey: ['public', 'member', id],
    queryFn: async () => axios.get<PublicMemberDto>(`/public/members/${id}`),
    staleTime: Infinity,
    select: (res) => new PublicMember(res.data),
    enabled: !!id,
  })
}

export const usePublicTenantPlans = () => {
  return useQuery<PlanDto[], Error, Plan[]>({
    queryKey: ['public', 'plans'],
    queryFn: async () => {
      const response = await axios.get('/public/plans')
      return response.data
    },
    select: useCallback(
      (plans: PlanDto[]) => plans.map((p) => new Plan(p, plans)).sort((a, b) => a.order - b.order),
      [],
    ),
    staleTime: Infinity,
  })
}
