import { Menu, Avatar, Flex, Box, Text } from '@mantine/core'
import { IconUsersGroup, IconQuestionMark, IconLogout, IconUser } from '@tabler/icons-react'
import { useGetUserMe } from 'api/query/user'
import { ReportBug } from 'components/bug/report-bug'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export function UserAvatar({
  openRequestModal,
  openUserModal,
}: {
  openRequestModal: () => void
  openUserModal: () => void
}) {
  const { t } = useTranslation()
  const { logout } = useAuth()
  const navigate = useNavigate()
  const { data: user, isLoading } = useGetUserMe()

  return (
    <Menu width={300} shadow="md" position="bottom" withArrow>
      <Menu.Target>
        <Avatar
          data-testid="user-avatar"
          size="2.3rem"
          ml={10}
          bd="2px solid #ccc"
          sx={{
            cursor: 'pointer',
          }}
        >
          {user?.initials}
        </Avatar>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <Flex align="center">
            <Avatar size="2rem">{user?.initials}</Avatar>
            <Box ml={10}>
              <Text fw={700} size="sm">
                {user?.fullName}
              </Text>
              <Text truncate maw={220} size="xs">
                {user?.email}
              </Text>
            </Box>
          </Flex>
        </Menu.Label>
        <Menu.Divider />
        <Menu.Label>{t('common.help')}</Menu.Label>
        <ReportBug />
        <Menu.Item leftSection={<IconQuestionMark size="1rem" />} onClick={openRequestModal}>
          {t('feature.request')}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Label>{t('common.settings')}</Menu.Label>
        <Menu.Item leftSection={<IconUser size="1rem" />} onClick={openUserModal}>
          {t('common.profile')}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item leftSection={<IconLogout size="1rem" />} onClick={logout}>
          {t('common.logout')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
