import { Title } from '@mantine/core'
import { MemberDocumentList } from 'components/member/documents/document-list'
import { useTranslation } from 'react-i18next'

export function MemberDocumentsPage() {
  const { t } = useTranslation()

  return (
    <>
      <Title mb={30} order={2}>
        {t('document.member-documents')}
      </Title>
      <MemberDocumentList />
    </>
  )
}
