import { UserDto } from 'api/dto'
import { AccessType } from 'api/dto/access'
import { VisibilityStatus } from '../visibility'
import { UserSource } from 'api/dto/source'

export enum UserRole {
  TENANT_ADMIN = 'tenant-admin',
  MEMBER_MANAGER = 'member-manager',
  MEMBER_USER = 'member-user',
}

export class User {
  id: string
  email: string
  firstName: string
  lastName: string
  accessType: AccessType
  tenantId: string
  memberId?: string
  role: UserRole
  profilePicture?: string
  visibilityStatus: VisibilityStatus
  communicationLanguage?: string

  source?: UserSource

  constructor(userDto: UserDto) {
    this.id = userDto.id
    this.email = userDto.email
    this.firstName = userDto.firstName
    this.lastName = userDto.lastName
    this.accessType = userDto.accessType
    this.role = userDto.role
    this.tenantId = userDto.tenantId
    this.memberId = userDto.memberId
    this.profilePicture = userDto.profilePicture
    this.visibilityStatus = userDto.visibilityStatus
    this.communicationLanguage = userDto.communicationLanguage
    this.source = userDto.source
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get isTenantAdmin() {
    return this.role === UserRole.TENANT_ADMIN
  }

  get initials() {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`.toUpperCase()
  }
}
