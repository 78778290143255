import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'config/axios'
import { useCallback } from 'react'
import { globalQueryClient } from './client'
import { showNotification } from '@mantine/notifications'
import { Member } from 'api/domain/entities/member'
import { MemberDto } from 'api/dto'
import { useTranslation } from 'react-i18next'
import { PaymentType } from 'api/domain/entities/membership/payment-type'

export const useListMembers = () =>
  useQuery({
    queryKey: ['members'],
    queryFn: async () => {
      const response = await axios.get('/members')
      return response.data
    },
    select: useCallback((members: MemberDto[]) => {
      return members.map((member) => new Member(member))
    }, []),
  })

export const useSendBulkActivationEmails = () => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async () => {
      const response = await axios.post('/tenant/send-bulk-activation-emails')
      return response.data
    },
    onSuccess: (data) => {
      const succeededCount = data.succeeded?.length || 0
      const failedCount = data.failed?.length || 0

      showNotification({
        title: t('member.bulk-email.success-title'),
        message: t('member.bulk-email.success-message', {
          succeeded: succeededCount,
          failed: failedCount,
        }),
        color: 'green',
      })
    },
    onError: (error) => {
      showNotification({
        title: t('member.bulk-email.error-title'),
        message: error.message,
        color: 'red',
      })
    },
  })
}

export const useSendActivationEmail = () => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async ({ memberId, userId }: { memberId: string; userId: string }) => {
      const response = await axios.post(`/members/${memberId}/resend-activation-email`, { userId })
      return response.data
    },
    onSuccess: (data) => {
      showNotification({
        title: t('member.activation-email.success-title'),
        message: t('member.activation-email.success-message'),
        color: 'green',
      })
    },
    onError: (error) => {
      showNotification({
        title: t('member.activation-email.error-title'),
        message: error.message,
        color: 'red',
      })
    },
  })
}

export const useGetMember = (id?: string) =>
  useQuery<MemberDto, Error, Member>({
    queryKey: ['members', id],
    queryFn: async () => {
      const response = await axios.get(`/members/${id}`)
      return response.data
    },
    select: useCallback((member: MemberDto) => new Member(member), []),
    enabled: !!id,
  })

export const useCreateMember = () =>
  useMutation({
    mutationFn: async (member: Partial<MemberDto>) => {
      const response = await axios.post('/members', member)
      return response.data
    },

    onMutate: (member) => {
      globalQueryClient.setQueryData(['members'], (old: MemberDto[] | undefined) => {
        if (!old) {
          return []
        }
        return [...old, member]
      })
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Member created',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })

export const useUpdateMember = () =>
  useMutation({
    mutationFn: async (member: Partial<MemberDto>) => {
      const response = await axios.put(`/members/${member.id}`, member)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Update successful',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })

export const useDeleteMember = () =>
  useMutation({
    mutationFn: async (member: Partial<MemberDto>) => {
      const response = await axios.delete(`/members/${member.id}`)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Member deleted',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })

export const useDeactivateMember = () =>
  useMutation({
    mutationFn: async (memberId: string) => {
      const response = await axios.put(`/members/${memberId}/deactivate`)
      return response.data
    },
    onSuccess: (_, memberId) => {
      showNotification({
        title: 'Success',
        message: 'Member deactivated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members', memberId],
      })
    },
    onError: (error, memberId) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })

export const useActivateMember = () =>
  useMutation({
    mutationFn: async (memberId: string) => {
      const response = await axios.put(`/members/${memberId}/activate`)
      return response.data
    },
    onSuccess: (_, memberId) => {
      showNotification({
        title: 'Success',
        message: 'Member activated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members', memberId],
      })
    },
    onError: (error, memberId) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members', memberId],
      })
    },
  })

export const useUpdateMembershipStatus = () => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async ({
      memberId,
      operation,
      note,
      startDate,
      endDate,
      planId,
      priceId,
      lastPaidAt,
      paymentType,
    }: {
      memberId: string
      operation: 'cancel' | 'markPaid'
      note: string
      startDate?: string
      endDate?: string
      planId?: string
      priceId?: string
      lastPaidAt?: string
      paymentType?: PaymentType
    }) => {
      const response = await axios.put(`/members/${memberId}/membership/status`, {
        operation,
        note,
        startDate,
        endDate,
        planId,
        priceId,
        lastPaidAt,
        paymentType,
      })
      return response.data
    },
    onSuccess: (_, variables) => {
      const messageKey =
        variables.operation === 'cancel'
          ? 'member.membership.cancel.success-message'
          : 'member.membership.mark-paid.success-message'

      showNotification({
        title: t('member.membership.status-update.success-title'),
        message: t(messageKey),
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members', variables.memberId],
      })
    },
    onError: (error) => {
      showNotification({
        title: t('member.membership.status-update.error-title'),
        message: error.message,
        color: 'red',
      })
    },
  })
}
