/**
 * Simple utility to detect Stripe address validation errors
 */

/**
 * Checks if an error is related to Stripe address validation
 */
export function isStripeAddressError(error: unknown): boolean {
  // Check if it's an Axios error with a response
  const axiosError = error as { isAxiosError?: boolean; response?: { data?: { type?: string; message?: string } } }

  if (axiosError.isAxiosError && axiosError.response?.data) {
    return (
      axiosError.response.data.type === 'StripeInvalidRequestError' &&
      // eslint-disable-next-line quotes
      !!axiosError.response.data.message?.includes("customer's location isn't recognized")
    )
  }

  // Check standard JS error
  const jsError = error as Error
  // eslint-disable-next-line quotes
  return !!jsError.message?.includes("customer's location isn't recognized")
}

/**
 * Gets a user-friendly message for the address validation error
 */
export function getAddressErrorMessage(): string {
  return 'Your address information could not be validated for tax calculation. Please update your address with complete and accurate information.'
}
