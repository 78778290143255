import { Plan } from 'api/domain/entities/plan'
import { PlanDto } from './plan-dto'

export class PlanFactory {
  static fromDto(planDto: PlanDto): Plan {
    return new Plan(planDto)
  }

  static toDto(plan: Partial<Plan>): Partial<PlanDto> {
    return {
      id: plan.id,
      tenantId: plan.tenantId,
      name: plan.name,
      paymentProviderId: plan.paymentProviderId,
      description: plan.description,
      monthlyPrice: plan.monthlyPrice,
      yearlyPrice: plan.yearlyPrice,
      availableCommitmentTypes: plan.availableCommitmentTypes,
      order: plan.order,
      mostPopular: plan.mostPopular,
      includedPlanId: plan.includedPlanId,
      features: plan.features,
    }
  }
}
