import { User } from 'api/domain/entities/user'
import { UserDto } from './user-dto'

export class UserFactory {
  static fromDto(userDto: UserDto): User {
    return new User(userDto)
  }

  static toDto(user: Partial<User>): Partial<UserDto> {
    return {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      accessType: user.accessType,
      role: user.role,
      tenantId: user.tenantId,
      memberId: user.memberId,
      profilePicture: user.profilePicture,
      visibilityStatus: user.visibilityStatus,
    }
  }
}
