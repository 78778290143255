import { MembershipStatus } from 'api/domain/entities/membership/membership-status'

export function compareMembershipStatus(statusA: MembershipStatus, statusB: MembershipStatus): number {
  const order = [
    MembershipStatus.PENDING,
    MembershipStatus.IN_PROGRESS,
    MembershipStatus.CANCELED,
    MembershipStatus.SUBSCRIBED,
    MembershipStatus.NEEDS_VALIDATION,
  ]

  return order.indexOf(statusA) - order.indexOf(statusB)
}
