/* eslint-disable @typescript-eslint/naming-convention */
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'config/axios'
import { useCallback } from 'react'
import { globalQueryClient } from './client'
import { showNotification } from '@mantine/notifications'
import { DocumentDto, CreateDocumentDto, UpdateDocumentDto } from 'api/dto/document-dto'
import { Document } from 'api/domain/entities/document'

// List all documents for the current tenant
export const useListDocuments = () => {
  return useQuery<DocumentDto[], Error, Document[]>({
    queryKey: ['documents'],
    queryFn: async () => {
      const response = await axios.get('/documents')
      return response.data
    },
    select: useCallback((dtos: DocumentDto[]) => dtos.map((dto) => new Document(dto)), []),
  })
}

// Create a new document
export const useCreateDocument = () => {
  return useMutation({
    mutationFn: async (data: { document: CreateDocumentDto; file: File }) => {
      // First create the document to get the upload URL
      const createResponse = await axios.post('/documents', {
        ...data.document,
        fileName: data.file.name,
        contentType: data.file.type,
      })

      // Then upload the file using the pre-signed URL
      const { documentId, uploadUrl } = createResponse.data

      // Use fetch for the upload since axios might transform the request
      await fetch(uploadUrl, {
        method: 'PUT',
        body: data.file,
        headers: {
          'Content-Type': data.file.type,
        },
      })

      return documentId
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Document uploaded successfully',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['documents'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
    },
  })
}

// Update a document
export const useUpdateDocument = () => {
  return useMutation({
    mutationFn: async ({ id, document }: { id: string; document: UpdateDocumentDto }) => {
      const response = await axios.put(`/documents/${id}`, document)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Document updated successfully',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['documents'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
    },
  })
}

// Delete a document
export const useDeleteDocument = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await axios.delete(`/documents/${id}`)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Document deleted successfully',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['documents'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
    },
  })
}

// Get document download URL
export const useGetDocumentUrl = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await axios.get(`/documents/${id}/download`)
      return response.data.downloadUrl
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: `Failed to generate download link: ${error.message}`,
        color: 'red',
      })
    },
  })
}

// List all documents available to the current member
export const useListMemberDocuments = () => {
  return useQuery<DocumentDto[], Error, Document[]>({
    queryKey: ['member-documents'],
    queryFn: async () => {
      const response = await axios.get('/members/me/documents')
      return response.data
    },
    select: useCallback((dtos: DocumentDto[]) => dtos.map((dto) => new Document(dto)), []),
  })
}

// Get document download URL for members
export const useGetMemberDocumentUrl = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await axios.get(`/documents/${id}/download`)
      return response.data.downloadUrl
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: `Failed to generate download link: ${error.message}`,
        color: 'red',
      })
    },
  })
}
