import { DocumentDto, DocumentAccess, DocumentStatus } from 'api/dto/document-dto'

export class Document {
  id: string
  tenantId: string
  name: string
  description?: string
  fileSize: number
  accessType: DocumentAccess
  accessIds: string[]
  downloadUrl?: string
  createdAt: string
  updatedAt?: string
  status: DocumentStatus

  constructor(dto: DocumentDto) {
    this.id = dto.id
    this.tenantId = dto.tenantId
    this.name = dto.name
    this.description = dto.description
    this.fileSize = dto.fileSize
    this.accessType = dto.accessType
    this.accessIds = dto.accessIds
    this.downloadUrl = dto.downloadUrl
    this.createdAt = dto.createdAt
    this.updatedAt = dto.updatedAt
    this.status = dto.status
  }

  // Helper method to format file size
  getFormattedFileSize(): string {
    if (this.fileSize < 1024) {
      return `${this.fileSize} B`
    } else if (this.fileSize < 1024 * 1024) {
      return `${(this.fileSize / 1024).toFixed(2)} KB`
    } else {
      return `${(this.fileSize / (1024 * 1024)).toFixed(2)} MB`
    }
  }

  // Helper method to get access type display name
  getAccessTypeDisplay(): string {
    switch (this.accessType) {
      case DocumentAccess.ALL_MEMBERS:
        return 'All Members'
      case DocumentAccess.SPECIFIC_MEMBERS:
        return 'Specific Members'
      case DocumentAccess.SPECIFIC_MEMBERSHIPS:
        return 'Specific Memberships'
      default:
        return this.accessType
    }
  }
}
