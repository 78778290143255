import { PlanDto } from 'api/dto'
import { CommitmentType } from './commitment-type'
import { Price } from './price'
import { PriceInterval } from './price-interval'

export class Plan {
  id: string
  tenantId: string
  name: string
  description: string
  paymentProviderId?: string
  monthlyPrice?: Price
  yearlyPrice?: Price
  availableCommitmentTypes: CommitmentType[]
  order: number
  mostPopular?: boolean
  includedPlanId?: string
  features?: string[]

  includedPlanName?: string

  constructor(planDto: PlanDto, plans?: PlanDto[]) {
    this.id = planDto.id
    this.tenantId = planDto.tenantId
    this.name = planDto.name
    this.description = planDto.description
    this.monthlyPrice = planDto.monthlyPrice
    this.yearlyPrice = planDto.yearlyPrice
    this.availableCommitmentTypes = planDto.availableCommitmentTypes || []
    this.paymentProviderId = planDto.paymentProviderId
    this.order = planDto.order || 0
    this.mostPopular = planDto.mostPopular
    this.includedPlanId = planDto.includedPlanId
    this.features = planDto.features

    if (this.includedPlanId && plans) {
      const includedPlan = plans.find((plan) => plan.id === this.includedPlanId)
      this.includedPlanName = includedPlan?.name
    }
  }

  getPriceWithInterval(interval: PriceInterval): Price | undefined {
    if (interval === PriceInterval.MONTHLY) {
      return this.monthlyPrice
    } else if (interval === PriceInterval.YEARLY) {
      return this.yearlyPrice
    }

    return undefined
  }
}
