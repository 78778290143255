import './i18n'
import '@mantine/core/styles.css'
import '@mantine/charts/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css'
import { createRoot } from 'react-dom/client'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { theme } from 'theme/theme'
import { App } from 'app'
import { setDefaultUrl } from 'config/axios'
import { QueryClientProvider } from '@tanstack/react-query'
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion'
import { globalQueryClient } from 'api/query/client'
import { environment } from 'config/environment'
import * as Sentry from '@sentry/react'
import * as Types from '@sentry/core'
import { useGetTenant } from 'api/query/tenant'
import { Loading } from 'components/loading/loading'
import { TenantNotFound } from 'pages/tenant-not-found'

const container = document.getElementById('root')
const root = createRoot(container!)

setDefaultUrl(import.meta.env.VITE_API_BASE_URL)

if (!environment.isLocal) {
  const integrations: Types.Integration[] = [
    Sentry.browserTracingIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'system',
      autoInject: false,
    }),
  ]

  if (environment.isProd) {
    integrations.push(
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    )
  }

  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations,
    debug: !environment.isProd,
    environment: environment.envName,
    tracesSampleRate: environment.isProd ? 0.4 : 0.7,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
    // eslint-disable-next-line max-len
    replaysSessionSampleRate: environment.isProd ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracePropagationTargets: ['localhost', /^https:\/\/membrics\.co\/api/],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.event_id) {
        Sentry.showReportDialog({ eventId: event.event_id })
      }
      return event
    },
  })
}

const Index = () => {
  const { data: tenant, isLoading, error, isSuccess } = useGetTenant()

  if (isLoading) {
    return (
      <MantineProvider forceColorScheme="light" stylesTransform={emotionTransform}>
        <MantineEmotionProvider>
          <Loading />
        </MantineEmotionProvider>
      </MantineProvider>
    )
  }

  if (error || !isSuccess) {
    return (
      <MantineProvider forceColorScheme="light" stylesTransform={emotionTransform}>
        <MantineEmotionProvider>
          <TenantNotFound />
        </MantineEmotionProvider>
      </MantineProvider>
    )
  }

  return (
    <MantineProvider theme={theme(tenant)} forceColorScheme="light" stylesTransform={emotionTransform}>
      <MantineEmotionProvider>
        <App />
        <Notifications />
      </MantineEmotionProvider>
    </MantineProvider>
  )
}

root.render(
  <QueryClientProvider client={globalQueryClient}>
    <Index />
  </QueryClientProvider>,
)
