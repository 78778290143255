import { useEffect, useMemo, useState, ReactNode } from 'react'
import { Box, Table as MantineTable, Text, TextInput, Select, ActionIcon, ActionIconProps } from '@mantine/core'
import { TableColumnManager, useTableConfig } from './table-column-manager'
import {
  useReactTable,
  Row,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  FilterFn,
  TableState,
  Column,
  Table as ReactTable,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { useTableStyles } from './table.styles'
import { useDebouncedState } from '@mantine/hooks'
import {
  IconArrowUp,
  IconArrowDown,
  IconSearch,
  IconTableExport,
  IconSettings,
  IconDownload,
} from '@tabler/icons-react'
import { DatePickerInput } from '@mantine/dates'
import dayjs from 'dayjs'
import { exportToCsv } from 'utils/export'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { DraggableTableRow } from './draggable-row'
import { rankItem } from '@tanstack/match-sorter-utils'

export interface Data extends Record<string, any> {
  id: string
}

export type ColumnWithFilter<T> = ColumnDef<T> & {
  filter?: (row: any, columnId: string, value: any) => boolean
}

function TableEmptyState({ message }: { message?: string }) {
  const { t } = useTranslation()
  return (
    <Box
      py={50}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Text size="sm" fw={500}>
        {message || t('common.no-data-available')}
      </Text>
    </Box>
  )
}

interface ITableProps<T extends Data> {
  tableId?: string
  columns: ColumnWithFilter<T>[]
  data: T[]
  untaintedData: T[]
  updateOrders: (data: any[]) => void
  setFilteredRows?: (rows: T[]) => void
  defaultSort?: { id: string; desc: boolean }
  hiddenColumns?: Record<string, boolean>
  columnVisibilityOverrides?: Record<string, boolean>
  actionButtons?: (rows: Row<T>[]) => ReactNode[]
  onRowClick?: (row: T) => void
  searchable?: boolean
  hidePagination?: boolean

  title?: React.ReactNode
  multiple?: boolean

  exportTable?: {
    filename: string
    customParser?: (row: any) => any
  }

  emptyStateMessage?: string
}

interface GlobalFilterProps<T extends object> {
  filteredRows: Row<T>[]
  globalFilter: string | number | readonly string[] | undefined
  setGlobalFilter: (value: string) => void
}

// Define a default UI for filtering
function DnDGlobalFilter<T extends object>(props: GlobalFilterProps<T>) {
  const { t } = useTranslation()
  const { classes } = useTableStyles({ hasFilters: false })
  const { globalFilter, setGlobalFilter, filteredRows: preGlobalFilteredRows } = props
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const [debounced, setDebouncedVal] = useDebouncedState('', 200, undefined)

  useEffect(() => {
    setGlobalFilter(debounced)
  }, [setGlobalFilter, debounced])

  return (
    <Box className={classes.search}>
      <Box className={classes.searchInput}>
        <IconSearch color="#cecece" />
        <TextInput
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            setDebouncedVal(e.target.value)
          }}
          placeholder={t('common.search')}
        />
      </Box>
      <Box className={classes.result}>
        <Text>{count}</Text>
        <Text ml={3}>{t('common.results')}</Text>
      </Box>
    </Box>
  )
}

export function DnDTable<T extends Data>(props: ITableProps<T>) {
  const { t } = useTranslation()
  const [globalFilter, setGlobalFilter] = useState('')
  const [filters, setFilters] = useState<Record<string, string[]>>({})
  const [rowSelection, setRowSelection] = useState({})
  const [columnManagerOpened, setColumnManagerOpened] = useState(false)

  const { classes, cx } = useTableStyles({
    hasFilters: Object.keys(filters).length > 0,
  })
  const {
    tableId,
    columns,
    data,
    untaintedData,
    updateOrders,
    actionButtons,
    defaultSort,
    hiddenColumns,
    columnVisibilityOverrides,
    onRowClick,
    searchable = true,
    multiple,
    exportTable,
    hidePagination,
    title,
  } = props

  // Set up table configuration with the useTableConfig hook
  const [tableConfig, { setColumnVisibility, setColumnOrder }] = useTableConfig({
    tableId,
    columnVisibilityOverrides,
    columns,
    defaultSort,
  })
  const currentData = useMemo(() => data || [], [data])

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}))

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
      itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const table = useReactTable<T>({
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: multiple,
    columns,
    data: currentData,
    state: {
      globalFilter,
      rowSelection,
      columnVisibility: tableConfig.columnVisibility,
      columnOrder: tableConfig.columnOrder,
    },
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: 'fuzzy',
    initialState: {
      pagination: { pageSize: !hidePagination ? 10 : currentData.length },
      columnVisibility: hiddenColumns,
      sorting: defaultSort ? [defaultSort] : [],
    },
  })

  const {
    getHeaderGroups,
    getFilteredRowModel: filteredRows,
    getCanPreviousPage,
    getCanNextPage,
    nextPage,
    previousPage,
    getState,
    setPageSize,
  } = table

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const oldIndex = untaintedData.findIndex((r) => r.id === active.id)
      const newIndex = untaintedData.findIndex((r) => r.id === over.id)
      const moved = arrayMove(untaintedData, oldIndex, newIndex)
      updateOrders(moved)
    }
  }

  const columnWithSize = useMemo(
    () =>
      columns
        .filter((c) => !!c.id)
        .map((c) => ({ [c.id!]: c }))
        .reduce((prev, val) => Object.assign(prev, val), {}),
    [columns],
  )

  const ExportButton = ({ ...props }: ActionIconProps) => {
    return (
      <ActionIcon
        disabled={filteredRows().rows.length === 0}
        onClick={() =>
          exportToCsv(
            filteredRows().rows.map((row) => {
              let currentRow: any = { ...row.original }

              if (exportTable?.customParser) {
                currentRow = exportTable.customParser(currentRow)
              }

              return currentRow
            }),
            `${exportTable?.filename}.csv`,
          )
        }
        {...props}
      >
        <IconDownload size={20} />
      </ActionIcon>
    )
  }

  return (
    <>
      {searchable && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DnDGlobalFilter
            filteredRows={filteredRows().rows}
            globalFilter={getState().globalFilter}
            setGlobalFilter={setGlobalFilter}
          />

          {exportTable && <ExportButton size={40} ml={10} />}
          {/* Column Manager Button */}
          <ActionIcon ml={10} size={40} onClick={() => setColumnManagerOpened(true)} title={t('common.manage-columns')}>
            <IconSettings size={20} />
          </ActionIcon>
          {actionButtons && (
            <Box
              ml={10}
              display="flex"
              sx={{
                alignItems: 'center',
              }}
            >
              {actionButtons(filteredRows().rows)}
            </Box>
          )}
        </Box>
      )}
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <Box data-testid="test-table" className={classes.container}>
          {title && (
            <Box display="flex" sx={{ justifyContent: 'space-between' }}>
              {title}
              {exportTable && <ExportButton size="xs" h={44} mt={10} mr={10} />}
            </Box>
          )}
          <MantineTable sx={{ borderSpacing: 0 }} highlightOnHover>
            <MantineTable.Thead>
              {getHeaderGroups().map((headerGroup) => (
                <MantineTable.Tr className={classes.header} key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <MantineTable.Th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        minWidth: columnWithSize[header.id].minSize,
                        maxWidth: columnWithSize[header.id].maxSize,
                        width: columnWithSize[header.id].size,
                      }}
                    >
                      <Box display="flex" sx={{ flexDirection: 'column' }}>
                        <Box
                          {...{
                            style: header.column.getCanSort()
                              ? {
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  userSelect: 'none',
                                }
                              : {},
                            className: classes.headerText,
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <IconArrowUp style={{ marginLeft: '.375rem' }} />,
                            desc: <IconArrowDown style={{ marginLeft: '.375rem' }} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </Box>
                      </Box>
                    </MantineTable.Th>
                  ))}
                </MantineTable.Tr>
              ))}
            </MantineTable.Thead>
            {filteredRows().rows.length > 0 ? (
              <MantineTable.Tbody>
                <SortableContext items={data} strategy={verticalListSortingStrategy}>
                  {filteredRows().rows.map((row) => {
                    return (
                      <DraggableTableRow
                        key={row.original.id}
                        row={row}
                        onRowClick={() => onRowClick && onRowClick(row.original)}
                      />
                    )
                  })}
                </SortableContext>
              </MantineTable.Tbody>
            ) : (
              <MantineTable.Tbody>
                <MantineTable.Tr>
                  <MantineTable.Td colSpan={table.getAllColumns().length}>
                    <TableEmptyState message={props.emptyStateMessage} />
                  </MantineTable.Td>
                </MantineTable.Tr>
              </MantineTable.Tbody>
            )}
          </MantineTable>
        </Box>
      </DndContext>

      {!props.hidePagination && (
        <Box className={classes.pagination}>
          <Box className={cx(classes.footerBox, 'start')}>
            <Box className={classes.footerBox}>
              <Text
                className={classes.footerText}
              >{`${getRowPosition(true, getState(), filteredRows().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('common.to')}
              </Text>
              <Text
                ml={3}
                className={classes.footerText}
              >{`${getRowPosition(false, getState(), filteredRows().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('common.of')}
              </Text>
              <Text ml={3} className={classes.footerText}>
                {filteredRows().rows.length}
              </Text>
            </Box>
            <Box>
              <Select
                size="sm"
                value={getState().pagination.pageSize.toString()}
                data={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                ]}
                onChange={(e) => setPageSize(Number(e))}
              />
            </Box>
          </Box>
          <Box className={classes.footerBox}>
            <Text
              className={cx(classes.footerText, classes.footerTextButton, getCanPreviousPage() ? 'active' : 'inactive')}
              sx={getCanPreviousPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanPreviousPage()) {
                  return
                }
                previousPage()
              }}
            >
              {t('common.previous')}
            </Text>
            <Text
              ml={21}
              className={cx(classes.footerText, classes.footerTextButton, getCanNextPage() ? 'active' : 'inactive')}
              sx={getCanNextPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanNextPage()) {
                  return
                }
                nextPage()
              }}
            >
              {t('common.next')}
            </Text>
          </Box>
        </Box>
      )}

      {/* Column Manager Modal */}
      <TableColumnManager
        table={table}
        columns={columns}
        columnOrder={tableConfig.columnOrder}
        opened={columnManagerOpened}
        onClose={() => setColumnManagerOpened(false)}
        onColumnOrderChange={setColumnOrder}
      />
    </>
  )
}

function getRowPosition(firstRow: boolean, tableState: TableState, totalRowCount: number) {
  const {
    pagination: { pageIndex, pageSize },
  } = tableState

  const index = +pageIndex + 1

  if (index === 1) {
    if (totalRowCount === 0) {
      return 0
    }

    return firstRow ? 1 : index * pageSize > totalRowCount ? totalRowCount : index * pageSize
  }

  return firstRow ? pageIndex * pageSize : index * pageSize > totalRowCount ? totalRowCount : index * pageSize
}

function Filter({ column, table }: { column: Column<any, any>; table: ReactTable<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  if (typeof firstValue === 'string' && firstValue.includes('-') && dayjs(firstValue).isValid()) {
    return (
      <DatePickerInput
        mt={3}
        pr={6}
        size="xs"
        value={column.getFilterValue() as any}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={'DD-MM-YYYY'}
      />
    )
  }

  return typeof firstValue === 'number' ? (
    <Box mt={3} display="flex" pr={6}>
      <TextInput
        w={50}
        size="xs"
        type="number"
        value={((column.getFilterValue() as any)?.[0] ?? '') as string}
        onChange={(e) => column.setFilterValue((old: any) => [e.target.value, old?.[1]])}
      />
      <TextInput
        w={50}
        type="number"
        size="xs"
        ml={3}
        value={((column.getFilterValue() as any)?.[1] ?? '') as string}
        onChange={(e) => column.setFilterValue((old: any) => [old?.[0], e.target.value])}
      />
    </Box>
  ) : (
    <TextInput
      mt={3}
      pr={6}
      size="xs"
      type="text"
      value={(column.getFilterValue() ?? '') as string}
      onChange={(e) => {
        column.setFilterValue(e.target.value)
      }}
      placeholder={'Search...'}
    />
  )
}
