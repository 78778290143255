import {
  Button,
  Group,
  Modal,
  Select,
  Stack,
  TextInput,
  Textarea,
  Text,
  Progress,
  Alert,
  MultiSelect,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { DocumentAccess } from 'api/dto/document-dto'
import { useCreateDocument } from 'api/query/document'
import { useListPlans } from 'api/query/plan'
import { Dropzone, FileRejection } from '@mantine/dropzone'
import { IconUpload, IconAlertCircle } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useMemo } from 'react'

interface UploadDocumentModalProps {
  opened: boolean
  onClose: () => void
}

export function UploadDocumentModal({ opened, onClose }: UploadDocumentModalProps) {
  const { t } = useTranslation()
  const createDocument = useCreateDocument()
  const { data: plans, isLoading: plansLoading } = useListPlans()
  const [file, setFile] = useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [fileError, setFileError] = useState<string | null>(null)
  const membershipOptions = useMemo<{ value: string; label: string }[]>(
    () =>
      (plans || []).map((plan) => ({
        value: plan.id,
        label: plan.name,
      })),
    [plans],
  )

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      accessType: DocumentAccess.ALL_MEMBERS,
      accessIds: [],
    },
    validate: {
      name: (value) => (value ? null : t('common.required')),
    },
  })

  const handleFileRejected = (rejections: FileRejection[]) => {
    const rejection = rejections[0]
    if (rejection) {
      if (rejection.file.size > 5 * 1024 * 1024) {
        setFileError(t('document.error.file-too-large', { maxSize: '5MB' }))
        // Show file name in the dropzone but with an error indicator
        setFile(rejection.file)
      } else {
        setFileError(t('document.error.invalid-file-type'))
      }
    }
  }

  const handleFileDrop = (files: File[]) => {
    setFile(files[0])
    setFileError(null)
  }

  const handleSubmit = form.onSubmit((values) => {
    if (!file) return

    setUploadProgress(0)

    // Create a custom mutate function to track progress
    const trackUploadProgress = async () => {
      // First create the document to get the upload URL
      const createResponse = await fetch('/documents', {
        method: 'POST',
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...values,
          fileName: file.name,
          contentType: file.type,
        }),
      })

      const { documentId, uploadUrl } = await createResponse.json()

      // Use XMLHttpRequest to track upload progress
      return new Promise<string>((resolve, reject) => {
        const xhr = new XMLHttpRequest()

        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100)
            setUploadProgress(progress)
          }
        })

        xhr.addEventListener('load', () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(documentId)
          } else {
            reject(new Error(`Upload failed with status ${xhr.status}`))
          }
        })

        xhr.addEventListener('error', () => {
          reject(new Error('Upload failed'))
        })

        xhr.open('PUT', uploadUrl)
        xhr.setRequestHeader('Content-Type', file.type)
        xhr.send(file)
      })
    }

    createDocument.mutate(
      {
        document: values,
        file,
        trackProgress: trackUploadProgress,
      } as any, // Using any because we're extending the expected parameters
      {
        onSuccess: () => {
          setUploadProgress(100)
          onClose()
          form.reset()
          setFile(null)
        },
      },
    )
  })

  return (
    <Modal opened={opened} onClose={onClose} title={t('document.upload')} size="lg">
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextInput
            label={t('document.fields.name')}
            placeholder={t('document.fields.name')}
            required
            {...form.getInputProps('name')}
          />

          <Textarea
            label={t('document.fields.description')}
            placeholder={t('document.fields.description')}
            {...form.getInputProps('description')}
          />

          <Select
            label={t('document.fields.accessType')}
            placeholder={t('document.fields.accessType')}
            data={[
              { value: DocumentAccess.ALL_MEMBERS, label: t('document.access.all-members') },
              { value: DocumentAccess.SPECIFIC_MEMBERSHIPS, label: t('document.access.specific-memberships') },
            ]}
            required
            onChange={(value) => {
              form.setFieldValue('accessType', value as DocumentAccess)
              // Reset accessIds when changing access type
              form.setFieldValue('accessIds', [])
            }}
            value={form.values.accessType}
          />

          {form.values.accessType === DocumentAccess.SPECIFIC_MEMBERSHIPS && (
            <>
              {plansLoading ? (
                <Text size="sm" color="dimmed">
                  Loading memberships...
                </Text>
              ) : (
                <MultiSelect
                  label={t('document.fields.memberships')}
                  placeholder={t('document.fields.select-memberships')}
                  data={membershipOptions}
                  required
                  {...form.getInputProps('accessIds')}
                />
              )}
            </>
          )}

          {fileError && (
            <Alert icon={<IconAlertCircle size="1rem" />} title={t('document.error.title')} color="red" mb="md">
              {fileError}
            </Alert>
          )}

          <Dropzone
            onDrop={handleFileDrop}
            onReject={handleFileRejected}
            maxSize={5 * 1024 * 1024} // 5MB
            accept={['application/pdf']}
            multiple={false}
            styles={{ root: { cursor: 'pointer' } }}
          >
            <Group justify="center" gap="xl" style={{ minHeight: 100 }}>
              <IconUpload size="3.2rem" stroke={1.5} />
              <div>
                <Text size="xl" inline>
                  {file ? file.name : t('document.dropzone.drag')}
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  {t('document.dropzone.description')}
                </Text>
              </div>
            </Group>
          </Dropzone>

          {createDocument.isPending && (
            <Stack mt="md">
              <Text size="sm" fw={500}>
                {uploadProgress < 100
                  ? t('document.uploading', { progress: uploadProgress })
                  : t('document.processing')}
              </Text>
              <Progress value={uploadProgress} size="lg" radius="xl" striped animated />
            </Stack>
          )}

          <Group justify="flex-end">
            <Button variant="outline" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button type="submit" loading={createDocument.isPending} disabled={!file}>
              {t('document.upload')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}
