import { Box, Button, Drawer, Group, Select, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { UserRole } from 'api/domain/entities/user'
import { VisibilityStatus } from 'api/domain/entities/visibility'
import { UserFactory } from 'api/dto/user'
import { useCreateTenantUser } from 'api/query/user'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AddTenantUserProps {
  opened: boolean
  close: () => void
}

type PartialUser = {
  firstName: string
  lastName: string
  visibilityStatus: VisibilityStatus
  email: string
}

export function AddTenantUserDrawer({ opened, close }: AddTenantUserProps) {
  const { t } = useTranslation()
  const { mutateAsync: createTenantUser } = useCreateTenantUser()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      visibilityStatus: VisibilityStatus.PUBLIC,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.firstName || values.firstName.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.lastName || values.lastName.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.email || values.email.length < 1) {
        errors.name = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(partialUser: PartialUser) {
    setIsSubmitting(true)
    const createTenantUserDto = UserFactory.toDto({
      ...partialUser,
      role: UserRole.TENANT_ADMIN,
    })
    try {
      await createTenantUser(createTenantUserDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    close()
    form.reset()
    setIsSubmitting(false)
  }

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('user.add')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('user.add-description')}</Text>
              <Box mt={10} display="flex">
                <TextInput
                  withAsterisk
                  w="100%"
                  label={t('user.fields.firstName')}
                  {...form.getInputProps('firstName')}
                />
              </Box>
              <Box mt={10} display="flex">
                <TextInput
                  w="100%"
                  withAsterisk
                  label={t('user.fields.lastName')}
                  {...form.getInputProps('lastName')}
                />
              </Box>
              <Box mt={10} display="flex">
                <TextInput
                  w="100%"
                  withAsterisk
                  type="email"
                  label={t('user.fields.email')}
                  {...form.getInputProps('email')}
                />
              </Box>

              <Select
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.visibility')}
                data={[
                  { value: VisibilityStatus.PRIVATE, label: t('user.visibility.private') },
                  { value: VisibilityStatus.PUBLIC, label: t('user.visibility.public') },
                ]}
                {...form.getInputProps('visibilityStatus')}
              />
            </Box>

            <Box>
              <Group mt={20} justify="flex-end">
                <Button disabled={!form.isValid() || isSubmitting} type="submit" size="xs" loading={isSubmitting}>
                  {t('common.create')}
                </Button>
              </Group>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}
