import { Box, Flex } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import { Plan } from 'api/domain/entities/plan'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { PlanTile } from './plan-tile'

interface PlanTilesContainerProps {
  plans: Plan[]
  memberId?: string
  currentPlan?: Plan
  currentPriceId?: string
  interval: PriceInterval
  selectPlan: (plan: Plan) => void
  isExternalMembership?: boolean
  isPublic?: boolean
}

export function PlanTilesContainer({
  plans,
  memberId,
  currentPlan,
  currentPriceId,
  interval,
  selectPlan,
  isExternalMembership,
  isPublic,
}: PlanTilesContainerProps) {
  const [maxDescriptionHeight, setMaxDescriptionHeight] = useState<number>(0)
  const [maxIncludesHeight, setMaxIncludesHeight] = useState<number>(0)
  const [isHeightMeasured, setIsHeightMeasured] = useState<boolean>(false)

  // Refs for each plan tile's elements
  const descriptionRefs = useRef<(HTMLDivElement | null)[]>([])
  const includesRefs = useRef<(HTMLDivElement | null)[]>([])

  // Initialize refs array and reset measurements when plans or interval changes
  useEffect(() => {
    descriptionRefs.current = descriptionRefs.current.slice(0, plans.length)
    includesRefs.current = includesRefs.current.slice(0, plans.length)

    // Reset measurement state when plans or interval changes
    setIsHeightMeasured(false)
  }, [plans, interval])

  // Measure heights after render
  useEffect(() => {
    if (isHeightMeasured || !plans.length) return

    // Use requestAnimationFrame to ensure DOM has rendered
    requestAnimationFrame(() => {
      let maxDescHeight = 0
      let maxInclHeight = 0

      // Find max heights
      descriptionRefs.current.forEach((ref) => {
        if (ref) {
          const height = ref.scrollHeight
          maxDescHeight = Math.max(maxDescHeight, height)
        }
      })

      includesRefs.current.forEach((ref) => {
        if (ref) {
          const height = ref.scrollHeight
          maxInclHeight = Math.max(maxInclHeight, height)
        }
      })

      setMaxDescriptionHeight(maxDescHeight)
      setMaxIncludesHeight(maxInclHeight)
      setIsHeightMeasured(true)
    })
  }, [isHeightMeasured, plans])

  return (
    <Flex wrap="wrap" gap="xl" justify="center" align="stretch">
      {plans.map((plan, index) => (
        <PlanTile
          key={plan.id}
          index={index}
          plan={plan}
          memberId={memberId}
          currentPlan={currentPlan}
          currentPriceId={currentPriceId}
          interval={interval}
          selectPlan={() => selectPlan(plan)}
          isExternalMembership={isExternalMembership}
          isPublic={isPublic}
          descriptionRef={(el: HTMLDivElement | null) => (descriptionRefs.current[index] = el)}
          includesRef={(el: HTMLDivElement | null) => (includesRefs.current[index] = el)}
          descriptionHeight={isHeightMeasured ? maxDescriptionHeight : undefined}
          includesHeight={isHeightMeasured ? maxIncludesHeight : undefined}
        />
      ))}
    </Flex>
  )
}
