import { Alert, Button, Center, Group, Modal, Space, Text } from '@mantine/core'
import { CommitmentType, Plan } from 'api/domain/entities/plan'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { useGetMember } from 'api/query/member'
import { useManageSubscription } from 'api/query/plan'
import { useAuth } from 'hooks/useAuth'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface UpdateSubscriptionModalProps {
  isOpen: boolean
  onClose: () => void
  onUpdatePlan: (plan: Plan) => Promise<void>
  loading?: boolean
  currentPlan?: Plan
  plan?: Plan
  memberId?: string
  plans?: Plan[]
}

export function UpdateSubscriptionModal({
  isOpen,
  currentPlan,
  plan,
  onClose,
  memberId,
  onUpdatePlan,
  plans,
  loading,
}: UpdateSubscriptionModalProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data: member } = useGetMember(memberId || user?.memberId)

  const { mutateAsync: manageSubscription, isPending: isOpeningManage } = useManageSubscription()

  const newPlan = useMemo(() => {
    if (member?.membership?.newPlanId) {
      return plans?.find((plan) => plan.id === member?.membership?.newPlanId)
    }
  }, [member?.membership?.newPlanId, plans])

  // Determine if the selected plan has a yearly commitment with monthly payments
  const hasYearlyCommitmentWithMonthlyPayment = useMemo(() => {
    if (!plan) return false

    // Check if the plan has the MONTHLY_WITH_YEARLY_COMMITMENT option
    const hasCommitmentType = plan.availableCommitmentTypes?.includes(CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT)

    // And has a monthly price (we assume the user is selecting monthly price option)
    const hasMonthlyPrice = !!plan.monthlyPrice

    return hasCommitmentType && hasMonthlyPrice
  }, [plan])

  return (
    <Modal
      title={currentPlan ? t('plan.update-subscription') : t('plan.subscribe')}
      opened={isOpen}
      onClose={onClose}
      size="lg"
    >
      {currentPlan ? (
        <Text size="sm">
          <Trans
            i18nKey="plan.update-subscription-explanations"
            components={{ upgrade: <Text span fw={700} />, downgrade: <Text span fw={700} /> }}
          />
        </Text>
      ) : (
        <Text size="sm">
          <Trans i18nKey="plan.buy-subscription-explanations" />
        </Text>
      )}

      {member?.canChangeSubscription ? (
        currentPlan ? (
          <Text mt={20} size="sm">
            <Trans
              i18nKey="plan.are-you-sure-change-current-subscription"
              components={{ plan: <Text span fw={700} />, space: <Space /> }}
              values={{ currentPlan: currentPlan?.name, plan: plan?.name }}
            />
          </Text>
        ) : (
          <Text mt={20} size="sm">
            <Trans
              i18nKey="plan.are-you-sure-change-new-subscription"
              components={{ plan: <Text span fw={700} /> }}
              values={{ plan: plan?.name }}
            />
          </Text>
        )
      ) : (
        <Text mt={20} size="sm">
          {member?.hasUnpaidMembership ? (
            <Trans
              i18nKey="plan.unpaid-membership-manage"
              components={{
                space: <Space />,
                unpaid: <Text span fw={700} />,
                manage: (
                  <Center mt={10}>
                    <Button
                      size="sm"
                      color="green"
                      loading={isOpeningManage}
                      onClick={() => manageSubscription({ memberId: member.id })}
                    >
                      {t('member.plans.manage')}
                    </Button>
                  </Center>
                ),
              }}
            />
          ) : (
            <Trans
              i18nKey="plan.change-plan-next-billing-period"
              components={{
                space: <Space />,
                newPlan: <Text span fw={700} />,
                date: <Text span fw={700} />,
              }}
              values={{ nextBillingPeriod: member?.membership?.nextBillingPeriod, newPlan: newPlan?.name }}
            />
          )}
        </Text>
      )}

      <Text mt={20} size="sm">
        {t('plan.credit-card-required')}
      </Text>

      {hasYearlyCommitmentWithMonthlyPayment && (
        <Alert mt={20} color="yellow" title={t('plan.yearly-commitment.title')}>
          <Text size="sm">
            <Trans
              i18nKey="plan.yearly-commitment.warning"
              components={{
                highlight: <Text span fw={700} />,
              }}
              values={{
                planName: plan?.name,
              }}
            />
          </Text>
        </Alert>
      )}

      <Group mt={20} justify="flex-end">
        <Button variant="outline" size="xs" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button
          data-testid="subscribe-now"
          variant="outline"
          disabled={!plan || member?.hasUnpaidMembership}
          loading={loading}
          size="xs"
          color="green"
          onClick={async () => {
            if (plan) {
              try {
                await onUpdatePlan(plan)
              } catch (error) {
                // eslint-disable-next-line no-console
              }
            }
            onClose()
          }}
        >
          {currentPlan ? t('plan.update-subscription') : t('plan.subscribe')}
        </Button>
      </Group>
    </Modal>
  )
}
