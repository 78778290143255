import { ActionIcon, Affix, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus, IconPencil } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { Category } from 'api/domain/entities/category'
import { useListCategories } from 'api/query/category'
import { AddCategoryDrawer } from 'components/drawer/add.category'
import { EditCategoryDrawer } from 'components/drawer/edit.category'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { AugmentedColumn } from 'components/table/column'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function Categories() {
  const { t } = useTranslation()
  const [opened, { open, close }] = useDisclosure(false)
  const { data: categories, isLoading: isLoadingCategory } = useListCategories()
  const [categoryToEdit, setCategoryToEdit] = useState<Category | undefined>()

  const columns: AugmentedColumn<Category>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: t('category.columns.name'),
      cell: (col) => <Text size="xs">{col.getValue() as string}</Text>,
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: t('category.columns.description'),
      cell: (col) => <Text size="xs">{col.getValue() as string}</Text>,
    },
    {
      id: 'actions',
      header: t('category.columns.actions'),
      maxSize: 50,
      cell: (p) => (
        <ActionIcon
          data-testid={`edit-category-${p.row.original.id}`}
          onClick={() => setCategoryToEdit(p.row.original)}
          title={t('common.edit')}
        >
          <IconPencil size={16} />
        </ActionIcon>
      ),
    },
  ]

  if (isLoadingCategory) {
    return <Loading size="lg" />
  }

  return (
    <>
      <Title mb={30} order={2}>
        {t('category.title')}
      </Title>
      <Table
        columns={columns}
        data={categories}
        hidePagination
        exportTable={{
          filename: 'categories',
        }}
      />
      <Affix bottom={20} right={20}>
        <ActionIcon
          data-testid="add-category"
          radius={100}
          display={opened ? 'none' : 'block'}
          size="xl"
          onClick={open}
        >
          <IconPlus size={17} />
        </ActionIcon>
      </Affix>
      <AddCategoryDrawer opened={opened} close={close} />
      <EditCategoryDrawer
        opened={!!categoryToEdit}
        close={() => setCategoryToEdit(undefined)}
        category={categoryToEdit}
      />
    </>
  )
}
