import { MembershipStatus } from 'api/domain/entities/membership/membership-status'
import { ManageImportedMembershipModal } from './manage-imported-membership-modal'
import { useState, useEffect } from 'react'
import { Plan } from 'api/domain/entities/plan'

// Explicitly define props interface with consistent naming conventions
interface MembershipStatusChangeModalProps {
  isOpen: boolean
  onClose: () => void
  onStatusChange: (
    status: MembershipStatus,
    note: string,
    startDate?: string,
    endDate?: string,
    planId?: string,
    priceId?: string,
    lastPaidAt?: string,
  ) => Promise<void>
  isLoading?: boolean
  initialNote?: string
  currentPlan?: Plan
  plans?: Plan[]
}

/**
 * Controller component for managing imported memberships
 */
export function MembershipStatusChangeModal({
  isOpen,
  onClose,
  onStatusChange,
  isLoading = false,
  currentPlan,
  plans = [],
}: MembershipStatusChangeModalProps) {
  const [showModal, setShowModal] = useState(false)

  // Update modal visibility when parent props change
  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])

  const handleClose = () => {
    setShowModal(false)
    onClose()
  }

  return (
    <ManageImportedMembershipModal
      isOpen={showModal}
      onClose={handleClose}
      onStatusChange={onStatusChange}
      isLoading={isLoading}
      currentPlan={currentPlan}
      plans={plans}
    />
  )
}
