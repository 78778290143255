import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  NumberInput,
  Select,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconArrowDown, IconArrowUp, IconInfoCircle, IconPlus, IconX } from '@tabler/icons-react'
import { CommitmentType, Plan } from 'api/domain/entities/plan'
import { Price } from 'api/domain/entities/plan/price'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { PlanFactory } from 'api/dto/plan'
import { useListPlans, useUpdatePlan } from 'api/query/plan'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface EditPlanProps {
  opened: boolean
  close: () => void
  plan?: Plan
}

type PartialPlan = {
  name?: string
  description?: string
  monthlyPrice?: Price
  yearlyPrice?: Price
  availableCommitmentTypes: CommitmentType[]
  mostPopular?: boolean
  includedPlanId?: string
  features?: string[]
  order: number
}

export function EditPlanDrawer({ opened, close, plan }: EditPlanProps) {
  const { t } = useTranslation()
  const { mutateAsync: updatePlan } = useUpdatePlan()

  const { data: plans } = useListPlans()

  const [isSubmitting, setIsSubmitting] = useState(false)

  // Calculate available commitment types based on price selections
  const calculateCommitmentTypes = (
    hasMonthly: boolean,
    hasYearly: boolean,
    allowMonthlyWithYearlyCommitment: boolean = false,
  ) => {
    const types: CommitmentType[] = []

    if (hasMonthly) {
      types.push(CommitmentType.MONTHLY)
    }

    if (hasYearly) {
      types.push(CommitmentType.YEARLY)
    }

    // Only add this option if both monthly price exists and user has opted in to this option
    if (hasMonthly && allowMonthlyWithYearlyCommitment) {
      types.push(CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT)
    }

    return types
  }

  // Get the initial monthly commitment type from the plan
  const getInitialMonthlyCommitmentType = () => {
    if (plan?.availableCommitmentTypes?.includes(CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT)) {
      return CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT
    }
    return CommitmentType.MONTHLY
  }

  const form = useForm({
    initialValues: {
      id: plan?.id || '',
      tenantId: plan?.tenantId || '',
      name: plan?.name || '',
      description: plan?.description || '',
      mostPopular: plan?.mostPopular || false,
      includedPlanId: plan?.includedPlanId || '',
      monthlyPrice: plan?.monthlyPrice || {
        amount: 0,
        currency: 'CAD',
        interval: PriceInterval.MONTHLY,
        paymentProviderId: '',
        commitmentType: CommitmentType.MONTHLY,
      },
      monthlyCommitmentType: getInitialMonthlyCommitmentType(),
      hasMonthlyPrice: !!plan?.monthlyPrice,
      yearlyPrice: plan?.yearlyPrice || {
        amount: 0,
        currency: 'CAD',
        interval: PriceInterval.YEARLY,
        paymentProviderId: '',
        commitmentType: CommitmentType.YEARLY,
      },
      hasYearlyPrice: !!plan?.yearlyPrice,
      availableCommitmentTypes: plan?.availableCommitmentTypes || [],
      features: plan?.features || [],
      order: plan?.order || 0,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.name || values.name.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.description || values.description.length < 1) {
        errors.description = t('common.required')
      }

      if (values.features.length) {
        values.features.forEach((feature, index) => {
          if (feature.length < 1) {
            errors[`features.${index}`] = t('common.required')
          }
        })
      }

      if (!values.hasMonthlyPrice && !values.hasYearlyPrice) {
        errors.hasMonthlyPrice = t('common.required')
        errors.hasYearlyPrice = t('common.required')
      }

      if (values.availableCommitmentTypes.length === 0) {
        errors.availableCommitmentTypes = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(values: typeof form.values) {
    setIsSubmitting(true)

    // Calculate commitment types based on selected prices
    // Calculate commitment types based on selected prices and commitment type
    const types: CommitmentType[] = []

    if (values.hasMonthlyPrice) {
      types.push(values.monthlyCommitmentType)
    }

    if (values.hasYearlyPrice) {
      types.push(CommitmentType.YEARLY)
    }

    // Create the plan object with the appropriate structure
    const updatedPlan: PartialPlan & { id: string; tenantId: string } = {
      id: values.id,
      tenantId: values.tenantId,
      name: values.name,
      description: values.description,
      availableCommitmentTypes: types,
      mostPopular: values.mostPopular,
      includedPlanId: values.includedPlanId,
      features: values.features,
      order: values.order,
    }

    // Preserve existing prices from the original plan prop
    // Do not allow price updates or deletions
    if (values.hasMonthlyPrice && plan?.monthlyPrice) {
      updatedPlan.monthlyPrice = plan.monthlyPrice
    }

    if (values.hasYearlyPrice && plan?.yearlyPrice) {
      updatedPlan.yearlyPrice = plan.yearlyPrice
    }

    const updatePlanDto = PlanFactory.toDto(updatedPlan)
    try {
      await updatePlan(updatePlanDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    close()
    form.reset()

    setIsSubmitting(false)
  }
  // Effect to automatically update commitmentTypes when price options change
  useEffect(() => {
    const { hasMonthlyPrice, hasYearlyPrice, monthlyCommitmentType } = form.values
    // When form values change, calculate commitment types automatically
    const types: CommitmentType[] = []

    if (hasMonthlyPrice) {
      // Add the selected monthly commitment type
      types.push(monthlyCommitmentType)
    }

    if (hasYearlyPrice) {
      types.push(CommitmentType.YEARLY)
    }

    // Update available commitment types in the form whenever the price options change
    if (types.length > 0) {
      form.setFieldValue('availableCommitmentTypes', types)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.hasMonthlyPrice, form.values.hasYearlyPrice, form.values.monthlyCommitmentType])

  useEffect(() => {
    if (plan) {
      form.setValues({
        id: plan.id || '',
        tenantId: plan.tenantId || '',
        name: plan.name || '',
        description: plan.description || '',
        mostPopular: plan.mostPopular || false,
        includedPlanId: plan.includedPlanId || '',
        monthlyPrice: plan.monthlyPrice || {
          amount: 0,
          currency: 'CAD',
          interval: PriceInterval.MONTHLY,
          paymentProviderId: '',
          commitmentType: CommitmentType.MONTHLY,
        },
        hasMonthlyPrice: !!plan.monthlyPrice,
        yearlyPrice: plan.yearlyPrice || {
          amount: 0,
          currency: 'CAD',
          interval: PriceInterval.YEARLY,
          paymentProviderId: '',
          commitmentType: CommitmentType.YEARLY,
        },
        hasYearlyPrice: !!plan.yearlyPrice,
        availableCommitmentTypes: plan.availableCommitmentTypes || [],
        features: plan.features || [],
        order: plan.order || 0,
      })

      // Also update the allowMonthlyWithYearlyCommitment state when plan changes
      // Set the monthly commitment type based on the plan's available commitment types
      const monthlyCommitmentType = plan.availableCommitmentTypes?.includes(
        CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT,
      )
        ? CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT
        : CommitmentType.MONTHLY

      form.setFieldValue('monthlyCommitmentType', monthlyCommitmentType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan])

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('plan.edit')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('plan.edit-description')}</Text>
              <Box mt={10} display="flex">
                <TextInput withAsterisk w="100%" label={t('plan.fields.name')} {...form.getInputProps('name')} />
              </Box>

              <Textarea
                mt={10}
                rows={3}
                withAsterisk
                w="100%"
                label={t('plan.fields.description')}
                {...form.getInputProps('description')}
              />

              <Select
                mt={10}
                label={t('plan.fields.included-plan')}
                data={plans
                  ?.filter((p) => p.id !== plan?.id)
                  .map((plan) => ({
                    value: plan.id,
                    label: plan.name,
                  }))}
                {...form.getInputProps('includedPlanId')}
              />

              <Checkbox
                mt={20}
                label={t('plan.fields.most-popular')}
                {...form.getInputProps('mostPopular')}
                checked={form.values.mostPopular}
                onChange={(event) => form.setFieldValue('mostPopular', event.currentTarget.checked)}
              />

              <Box display="flex" pb={6} sx={{ alignItems: 'center' }} mt={20}>
                <Text size="xl" fw={600}>
                  {t('plan.features')}
                </Text>

                <ActionIcon
                  data-testid="add-feature"
                  ml={10}
                  size="sm"
                  onClick={() => {
                    form.insertListItem('features', '')
                  }}
                >
                  <IconPlus size={16} />
                </ActionIcon>
              </Box>
              <Text size="xs" fw={400} pb={10}>
                {t('plan.feature-explanations')}
              </Text>
              <Divider />
              {form.getValues().features.map((_, index) => (
                <Box mt={10} display="flex" sx={{ gap: 5, alignItems: 'center' }}>
                  <Box>
                    <ActionIcon
                      size="sm"
                      disabled={index === 0}
                      onClick={() => {
                        if (index > 0) {
                          form.reorderListItem('features', { from: index, to: index - 1 })
                        }
                      }}
                    >
                      <IconArrowUp size={16} />
                    </ActionIcon>
                    <ActionIcon
                      size="sm"
                      disabled={index === form.values.features.length - 1}
                      onClick={() => {
                        if (index < form.values.features.length - 1) {
                          form.reorderListItem('features', { from: index, to: index + 1 })
                        }
                      }}
                    >
                      <IconArrowDown size={16} />
                    </ActionIcon>
                  </Box>
                  <Textarea
                    data-testid={`feature-${index}`}
                    withAsterisk
                    rows={2}
                    w="100%"
                    {...form.getInputProps(`features.${index}`)}
                  />
                  <ActionIcon
                    onClick={() => {
                      form.removeListItem('features', index)
                    }}
                  >
                    <IconX size={20} />
                  </ActionIcon>
                </Box>
              ))}

              <Box component="section" mt={20}>
                <Text size="xl" fw={600} pb={10}>
                  {t('plan.pricing')}
                </Text>
                <Divider />
                <Alert
                  mt={15}
                  mb={10}
                  icon={<IconInfoCircle size={16} />}
                  color="blue"
                  title={t('plan.price-update-disabled-title') || 'Price Updates Disabled'}
                >
                  {t('plan.price-update-disabled-message') ||
                    'Price updates and deletions are currently disabled for existing plans.'}
                </Alert>

                <Text mt={5} mb={15} size="sm">
                  {t('plan.pricing-explanation')}
                </Text>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                  {/* Monthly Price Card */}
                  <Box sx={{ border: '1px solid #e9ecef', borderRadius: 8, padding: 16, background: 'white' }}>
                    <Text component="h4" fw={600} size="sm" pb={10}>
                      {t('plan.fields.monthly-price')}
                    </Text>

                    <Box display="flex" sx={{ gap: 10, alignItems: 'flex-start' }}>
                      <NumberInput
                        data-testid="monthly-price"
                        placeholder={t('plan.fields.price-placeholder')}
                        label={t('plan.fields.amount')}
                        disabled
                        value={form.values.monthlyPrice.amount > 0 ? form.values.monthlyPrice.amount : undefined}
                        sx={{ flex: 2 }}
                      />
                      <TextInput
                        data-testid="monthly-price-currency"
                        disabled
                        label={t('plan.fields.currency')}
                        value={form.values.monthlyPrice.currency}
                        sx={{ flex: 1 }}
                      />
                    </Box>

                    {/* Monthly Commitment Type Dropdown */}
                    {form.values.hasMonthlyPrice && (
                      <Box mt={15}>
                        <Select
                          data-testid="monthly-commitment-type"
                          label={
                            <Text fw={500} size="sm">
                              {t('plan.commitment.type') || 'Commitment Type'}
                            </Text>
                          }
                          description={
                            t('plan.commitment.type-description') || 'Select the commitment term for monthly payments'
                          }
                          data={[
                            {
                              value: CommitmentType.MONTHLY,
                              label: t('plan.commitment.monthly'),
                            },
                            {
                              value: CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT,
                              label: t('plan.commitment.monthly-with-yearly-commitment'),
                            },
                          ]}
                          value={form.values.monthlyCommitmentType}
                          onChange={(value) => {
                            if (value) {
                              form.setFieldValue('monthlyCommitmentType', value as CommitmentType)
                            }
                          }}
                        />
                      </Box>
                    )}
                  </Box>

                  {/* Yearly Price Card */}
                  <Box sx={{ border: '1px solid #e9ecef', borderRadius: 8, padding: 16, background: 'white' }}>
                    <Text component="h4" fw={600} size="sm" pb={10}>
                      {t('plan.fields.yearly-price')}
                    </Text>

                    <Box display="flex" sx={{ gap: 10, alignItems: 'flex-start' }}>
                      <NumberInput
                        data-testid="yearly-price"
                        placeholder={t('plan.fields.price-placeholder')}
                        label={t('plan.fields.amount')}
                        disabled
                        value={form.values.yearlyPrice.amount > 0 ? form.values.yearlyPrice.amount : undefined}
                        sx={{ flex: 2 }}
                      />
                      <TextInput
                        data-testid="yearly-price-currency"
                        disabled
                        label={t('plan.fields.currency')}
                        value={form.values.yearlyPrice.currency}
                        sx={{ flex: 1 }}
                      />
                    </Box>

                    {/* Yearly commitment badge */}
                    {form.values.hasYearlyPrice && (
                      <Box mt={15}>
                        <Text size="xs" fw={500} c="dimmed">
                          {t('plan.commitment.includes')}
                        </Text>
                        <Box mt={5}>
                          <Box
                            sx={{ padding: '4px 8px', background: '#e6fcf5', borderRadius: 4, display: 'inline-block' }}
                          >
                            <Text size="xs" fw={500}>
                              {t('plan.commitment.yearly')}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              pt={20}
              display="flex"
              sx={{
                borderTop: '1px solid #cecece',
                justifyContent: 'flex-end',
              }}
              flex={0}
            >
              <Button disabled={!form.isValid() || isSubmitting} loading={isSubmitting} type="submit" size="xs">
                {t('common.update')}
              </Button>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}
