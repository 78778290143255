import { Button, Group, Modal, Text } from '@mantine/core'
import { useDeleteMember } from 'api/query/member'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface DeleteMemberModalProps {
  memberId: string
  isOpen: boolean
  onClose: () => void
  memberName?: string
}

export function DeleteMemberModal({ memberId, isOpen, onClose, memberName }: DeleteMemberModalProps) {
  const { t } = useTranslation()
  const { mutateAsync: deleteMember, isPending: isDeleting } = useDeleteMember()
  const navigate = useNavigate()

  const handleDelete = async () => {
    try {
      await deleteMember({ id: memberId })
      // Navigate to members list after successful deletion
      navigate('/members')
    } catch (error) {
      // Error notification is handled by the mutation
      onClose()
    }
  }

  return (
    <Modal opened={isOpen} onClose={onClose} title={t('member.delete.confirm-title')} size="lg">
      <Text mb={20}>
        <Trans
          i18nKey="member.delete.confirm-message"
          values={{ memberName }}
          components={{ bold: <span style={{ fontWeight: 'bold' }} /> }}
        />
      </Text>

      <Group justify="flex-end">
        <Button variant="outline" onClick={onClose} size="sm">
          {t('common.cancel')}
        </Button>
        <Button color="red" onClick={handleDelete} loading={isDeleting} size="sm">
          {t('common.delete')}
        </Button>
      </Group>
    </Modal>
  )
}
