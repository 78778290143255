import { Text, Box, Center, Switch, Title } from '@mantine/core'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { usePublicTenantPlans } from 'api/query/public'
import { useGetTenant } from 'api/query/tenant'
import { Loading } from 'components/loading/loading'
import { PlanTilesContainer } from 'components/plan/plan-tiles-container'
import { getRegisterURL } from 'config/environment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isLocalhost } from 'utils/localhost'

export function PublicPlans() {
  const { t } = useTranslation()
  const { data: tenant } = useGetTenant()
  const { data: plans, isLoading: isLoadingPlans } = usePublicTenantPlans()
  const [intervalType, setIntervalType] = useState(PriceInterval.MONTHLY)

  // Redirects to the tenant's registration page
  const handlePlanSelection = () => {
    if (!tenant) {
      return
    }

    if (isLocalhost()) {
      if (window.parent) {
        window.parent.location.href = getRegisterURL()
      } else {
        window.location.href = getRegisterURL()
      }

      // Redirect to the tenant's registration page
      return
    }

    if (window.parent) {
      window.parent.location.href = getRegisterURL(tenant.slug)
    } else {
      window.location.href = getRegisterURL(tenant.slug)
    }
  }

  if (isLoadingPlans) {
    return <Loading size="lg" />
  }

  return (
    <Box pos="relative" mt={30} sx={{ padding: '0 20px' }}>
      <Title ta="center">{t('member.plans.choose')}</Title>

      <Center mt={20} mb={60}>
        <Text size="md" mr={5} fw={intervalType === PriceInterval.MONTHLY ? 900 : 300}>
          {t('plan.interval.monthly')}
        </Text>
        <Switch
          size="lg"
          checked={intervalType === PriceInterval.YEARLY}
          onChange={(e) => setIntervalType(e.target.checked ? PriceInterval.YEARLY : PriceInterval.MONTHLY)}
          styles={{
            track: {
              padding: 10,
            },
          }}
        />
        <Text size="md" ml={5} fw={intervalType === PriceInterval.YEARLY ? 900 : 300}>
          {t('plan.interval.yearly')}
        </Text>
      </Center>

      <Box mt={30}>
        <PlanTilesContainer
          plans={plans || []}
          interval={intervalType}
          selectPlan={handlePlanSelection}
          isPublic={true}
        />
      </Box>
    </Box>
  )
}
