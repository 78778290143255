import { MembershipStatus } from 'api/domain/entities/membership/membership-status'
import { UserRole } from 'api/domain/entities/user'
import { Member } from 'api/domain/entities/member/member'
import { UserToken } from 'api/dto/user-token'

export const ROUTES = {
  LOGIN: '/session/login',
  COMPLETE_PROFILE: '/member/complete-profile',
  PLANS: '/member/plans',
  MEMBER_INFO: '/member/infos',
  DASHBOARD: '/dashboard',
  DOCUMENTS: '/documents',
} as const

// Query parameter for showing feedback messages
export const REDIRECT_REASONS = {
  MEMBERSHIP_CANCELED: 'membership_canceled',
  NO_MEMBERSHIP: 'no_membership',
} as const

/**
 * Get the default redirect route based on user role and membership status
 */
export const getDefaultRedirectRoute = (user: UserToken | null, hasMembership: boolean): string => {
  if (!user) return ROUTES.LOGIN

  if (user.isTenantAdmin()) return ROUTES.DASHBOARD

  if (user.isMemberManager()) {
    return hasMembership ? ROUTES.MEMBER_INFO : ROUTES.PLANS
  }

  if (user.isMemberUser()) return ROUTES.MEMBER_INFO

  return ROUTES.LOGIN
}

/**
 * Check if user has the required role(s)
 */
export const hasRequiredRole = (user: UserToken | null, role: UserRole | UserRole[] | undefined): boolean => {
  if (!role || !user) return true

  return Array.isArray(role) ? role.some((r) => user.hasRequiredRole(r)) : user.hasRequiredRole(role)
}

/**
 * Check if user needs to complete their profile and return redirect path if needed
 */
export const needsProfileCompletion = (member: Member | null | undefined, currentPath: string): string | null => {
  if (!member) return null

  if (member.needsProfileCompletion() && currentPath !== ROUTES.COMPLETE_PROFILE) {
    return ROUTES.COMPLETE_PROFILE
  }

  if (!member.needsProfileCompletion() && currentPath === ROUTES.COMPLETE_PROFILE) {
    return ROUTES.PLANS
  }

  return null
}

/**
 * Check membership requirements and return redirect path with reason if needed
 */
export const checkMembershipRequirements = (
  user: UserToken | null,
  member: Member | null | undefined,
  currentPath: string,
  requireActiveMembership: boolean,
): { redirectTo: string | null; reason: string | null } => {
  if (!user || !member) return { redirectTo: null, reason: null }

  if (user.isMemberManager() || user.isMemberUser()) {
    // No membership at all
    const noMembership = !member.hasMembership

    // Has membership but not active (CANCELED or IN_PROGRESS)
    const hasInactiveMembership = member.hasMembership && member.membership?.status !== MembershipStatus.SUBSCRIBED

    // For pages requiring active membership, redirect if membership is inactive
    if (requireActiveMembership && hasInactiveMembership) {
      return {
        redirectTo: ROUTES.PLANS,
        reason: REDIRECT_REASONS.MEMBERSHIP_CANCELED,
      }
    }

    // For any page (except plans and profile completion), redirect if no membership
    if (noMembership && currentPath !== ROUTES.PLANS && currentPath !== ROUTES.COMPLETE_PROFILE) {
      return {
        redirectTo: ROUTES.PLANS,
        reason: REDIRECT_REASONS.NO_MEMBERSHIP,
      }
    }
  }

  return { redirectTo: null, reason: null }
}
