import { Box, Button, Drawer, MultiSelect, NumberInput, Select, Text, TextInput, Textarea } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { MemberFactory } from 'api/dto/member'
import { useListCategories } from 'api/query/category'
import { useCreateMember } from 'api/query/member'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'utils/date-time'
import { AddressAutocomplete } from 'components/address/address-autocomplete'
import { Address } from 'api/domain/entities/member'
import isEmail from 'validator/lib/isEmail'
import { VisibilityStatus } from 'api/domain/entities/visibility'
import { validateAddress } from 'utils/address'

interface AddMemberProps {
  opened: boolean
  close: () => void
}

type PartialMember = {
  name?: string
  address: Address
  phone?: string
  memberSince: DateTime
  accountingEmail?: string
  contact: {
    firstName: string
    lastName: string
    email: string
    jobTitle?: string
  }
  visibilityStatus: VisibilityStatus
  categoryIds?: string[]
  discount?: number
  note: string
}

export function AddMemberDrawer({ opened, close }: AddMemberProps) {
  const { t } = useTranslation()
  const { data: categories } = useListCategories()

  const { mutateAsync: createMember, isPending: isSubmitting } = useCreateMember()

  const form = useForm({
    initialValues: {
      accountingEmail: '',
      address: {
        formatted: '',
        line1: '',
        city: '',
        country: '',
        state: '',
        postalCode: '',
      } as Address,
      memberSince: DateTime.now(),
      visibilityStatus: VisibilityStatus.PUBLIC,
      note: '',
      contact: {
        firstName: '',
        lastName: '',
        email: '',
      },
      externalId: '',
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.accountingEmail) {
        errors.accountingEmail = t('common.required')
      }

      if (values.accountingEmail && !isEmail(values.accountingEmail)) {
        errors.accountingEmail = 'Invalid email'
      }

      if (!values.memberSince) {
        errors.memberSince = t('common.required')
      }

      if (!values.contact.firstName || values.contact.firstName.length < 3) {
        errors['contact.firstName'] = t('common.required')
      }

      if (!values.contact.lastName || values.contact.lastName.length < 3) {
        errors['contact.lastName'] = t('common.required')
      }

      if (!values.contact.email || values.contact.email.length < 3) {
        errors['contact.email'] = t('common.required')
      }

      if (values.contact.email && !isEmail(values.contact.email)) {
        errors['contact.email'] = 'Invalid email'
      }

      if (values.visibilityStatus === undefined) {
        errors.visibilityStatus = t('common.required')
      }

      if (!validateAddress(values.address)) {
        errors.address = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(member: PartialMember) {
    const createMemberDto = MemberFactory.toDto({
      ...member,
      memberSince: DateTime.fromISOString(member.memberSince.toISOString()),
    })
    try {
      await createMember(createMemberDto)
    } catch (error) {
      return
    }
    close()
    form.reset()
  }

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('member.add')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text size="sm">{t('member.add-description')}</Text>

              <Box sx={{ borderBottom: '1px solid #cecece' }} mt={20}>
                <Text size="md" fw={600} pb={10} w="100%">
                  General Informations
                </Text>
              </Box>

              <Box mt={10} display="flex">
                <TextInput w="100%" label={t('member.fields.externalId')} {...form.getInputProps('externalId')} />
              </Box>

              <Box mt={10} display="flex">
                <TextInput withAsterisk w="100%" label={t('member.fields.company')} {...form.getInputProps('name')} />
              </Box>

              <Box mt={10} display="flex">
                <TextInput w="100%" label={t('member.fields.phone')} {...form.getInputProps('phone')} />
              </Box>

              <Box mt={10} display="flex">
                <TextInput
                  withAsterisk
                  w="100%"
                  type="email"
                  label={t('member.fields.accountingEmail')}
                  {...form.getInputProps('accountingEmail')}
                />
              </Box>

              <Box mt={10} display="flex">
                <AddressAutocomplete
                  currentValue={form.values.address}
                  setValue={(value) => form.setFieldValue('address', value)}
                  error={Object.keys(form.errors).some((key) => key.startsWith('address'))}
                  errorMessage={Object.entries(form.errors)
                    .filter(([key]) => key.startsWith('address'))
                    .map(([, value]) => value)
                    .join(', ')}
                />
              </Box>

              <Box mt={10} display="flex">
                <DatePickerInput
                  withAsterisk
                  w="100%"
                  label={t('member.fields.memberSince')}
                  {...form.getInputProps('memberSince')}
                  value={form.values.memberSince?.date.toDate()}
                  onChange={(date) => {
                    form.setFieldValue('memberSince', DateTime.fromDate(date as Date))
                  }}
                />
              </Box>

              <Select
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.visibility')}
                data={[
                  { value: VisibilityStatus.PRIVATE, label: t('user.visibility.private') },
                  { value: VisibilityStatus.PUBLIC, label: t('user.visibility.public') },
                ]}
                {...form.getInputProps('visibilityStatus')}
              />

              <Box mt={10}>
                <MultiSelect
                  comboboxProps={{
                    // @ts-expect-error data-testid is not in the type
                    ['data-testid']: 'categories-select',
                  }}
                  label="Categories"
                  data={categories
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((category) => ({ value: category.id, label: category.name }))}
                  {...form.getInputProps('categoryIds')}
                />
              </Box>

              <Box mt={10}>
                <NumberInput
                  w="100%"
                  allowNegative={false}
                  prefix="$"
                  label={t('member.fields.discount')}
                  {...form.getInputProps('discount')}
                />
              </Box>

              <Box sx={{ borderBottom: '1px solid #cecece' }} mt={20}>
                <Text size="md" fw={600} pb={10} w="100%">
                  {t('member.fields.contact.title')}
                </Text>
              </Box>

              <Box mt={10} display="flex" sx={{ gap: 5 }}>
                <TextInput
                  w="100%"
                  withAsterisk
                  label={t('member.fields.contact.firstName')}
                  {...form.getInputProps('contact.firstName')}
                />
                <TextInput
                  w="100%"
                  withAsterisk
                  label={t('member.fields.contact.lastName')}
                  {...form.getInputProps('contact.lastName')}
                />
              </Box>
              <Box mt={10} display="flex">
                <TextInput
                  w="100%"
                  type="email"
                  withAsterisk
                  label={t('member.fields.contact.email')}
                  {...form.getInputProps('contact.email')}
                />
              </Box>
              <Box mt={10} display="flex">
                <TextInput
                  w="100%"
                  label={t('member.fields.contact.jobTitle')}
                  {...form.getInputProps('contact.jobTitle')}
                />
              </Box>

              <Box mt={10} display="flex">
                <Textarea w="100%" rows={3} label={t('member.fields.note')} {...form.getInputProps('note')} />
              </Box>
            </Box>

            <Box mt={20} display="flex" sx={{ justifyContent: 'flex-end' }}>
              <Button disabled={!form.isValid() || isSubmitting} loading={isSubmitting} type="submit" size="xs">
                {t('common.create')}
              </Button>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}
