import { Select } from '@mantine/core'
import { Box, Button, Drawer, Group, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { UserRole } from 'api/domain/entities/user'
import { VisibilityStatus } from 'api/domain/entities/visibility'
import { UserFactory } from 'api/dto/user'
import { useCreateMemberUser as useCreateMemberUser } from 'api/query/user'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface AddMemberUserProps {
  opened: boolean
  close: () => void
}

type PartialUser = {
  firstName: string
  lastName: string
  email: string
  role: UserRole
  visibilityStatus: VisibilityStatus
}

export function AddMemberUserDrawer({ opened, close }: AddMemberUserProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { mutateAsync: createMemberUser } = useCreateMemberUser(id)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: UserRole.MEMBER_USER,
      visibilityStatus: VisibilityStatus.PUBLIC,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.firstName || values.firstName.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.lastName || values.lastName.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.email || values.email.length < 1) {
        errors.name = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(partialUser: PartialUser) {
    setIsSubmitting(true)
    const createMemberUserDto = UserFactory.toDto(partialUser)
    try {
      await createMemberUser(createMemberUserDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    close()
    form.reset()
    setIsSubmitting(false)
  }

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('user.add')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('user.add-description')}</Text>
              <TextInput
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.firstName')}
                {...form.getInputProps('firstName')}
              />
              <TextInput
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.lastName')}
                {...form.getInputProps('lastName')}
              />
              <TextInput
                mt={10}
                withAsterisk
                w="100%"
                type="email"
                label={t('user.fields.email')}
                {...form.getInputProps('email')}
              />

              <Select
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.role')}
                data={[
                  { value: UserRole.MEMBER_MANAGER, label: t('user.roles.manager') },
                  { value: UserRole.MEMBER_USER, label: t('user.roles.user') },
                ]}
                {...form.getInputProps('role')}
              />

              <Select
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.visibility')}
                data={[
                  { value: VisibilityStatus.PRIVATE, label: t('user.visibility.private') },
                  { value: VisibilityStatus.PUBLIC, label: t('user.visibility.public') },
                ]}
                {...form.getInputProps('visibilityStatus')}
              />
            </Box>

            <Box>
              <Group mt={20} justify="flex-end">
                <Button disabled={!form.isValid() || isSubmitting} type="submit" size="xs" loading={isSubmitting}>
                  {t('common.create')}
                </Button>
              </Group>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}
