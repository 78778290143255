import { Button, Group, Modal, Text } from '@mantine/core'
import { Document } from 'api/domain/entities/document'
import { useTranslation } from 'react-i18next'

interface DeleteDocumentModalProps {
  isOpen: boolean
  onClose: () => void
  document?: Document
  onDelete: () => void
}

export function DeleteDocumentModal({ isOpen, onDelete, document, onClose }: DeleteDocumentModalProps) {
  const { t } = useTranslation()

  return (
    <Modal title={t('document.delete')} opened={isOpen} onClose={onClose} size="sm">
      <Text>
        {t('document.are-you-sure-delete-document', {
          document: document?.name,
        })}
      </Text>

      <Group mt={20} justify="flex-end">
        <Button variant="outline" size="xs" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button variant="outline" size="xs" color="red" onClick={onDelete}>
          {t('common.delete')}
        </Button>
      </Group>
    </Modal>
  )
}
