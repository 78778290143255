import { Alert, Button, Group, Modal, Text } from '@mantine/core'
import { Plan } from 'api/domain/entities/plan'
import { useTranslation } from 'react-i18next'

interface ExternalMembershipModalProps {
  isOpen: boolean
  onClose: () => void
  onSubscribePlan: (plan: Plan) => Promise<void>
  loading?: boolean
  currentPlan?: Plan
  plan?: Plan
  memberId?: string
}

export function ExternalMembershipModal({
  isOpen,
  currentPlan,
  plan,
  onClose,
  onSubscribePlan,
  loading,
}: ExternalMembershipModalProps) {
  const { t } = useTranslation()

  return (
    <Modal title={t('plan.convert_external_membership')} opened={isOpen} onClose={onClose} size="lg">
      <Alert color="blue" title={t('plan.external_member_notice')}>
        <Text size="sm">{t('plan.external_membership_explanation')}</Text>
      </Alert>

      <Text mt={20} size="sm">
        {t('plan.are_you_sure_convert', { plan: plan?.name })}
      </Text>

      <Text mt={20} size="sm">
        {t('plan.credit-card-required')}
      </Text>

      <Group mt={20} justify="flex-end">
        <Button variant="outline" size="xs" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button
          variant="outline"
          disabled={!plan}
          size="xs"
          color="green"
          loading={loading}
          onClick={async () => {
            if (plan) {
              try {
                await onSubscribePlan(plan)
              } catch (error) {
                // Error handling
              }
            }
            onClose()
          }}
        >
          {t('plan.subscribe')}
        </Button>
      </Group>
    </Modal>
  )
}
