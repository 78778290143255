import { Badge, Button, Flex, lighten, List, rem, Text, Tooltip, useMantineTheme } from '@mantine/core'
import { Box } from '@mantine/core'
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'
import { CommitmentType, Plan } from 'api/domain/entities/plan'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { useGetMember } from 'api/query/member'
import { useManageSubscription } from 'api/query/plan'
import { useAuth } from 'hooks/useAuth'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface PlanTileProps {
  index: number
  plan: Plan
  memberId?: string
  currentPlan?: Plan
  currentPriceId?: string
  interval: PriceInterval
  selectPlan: () => void
  isExternalMembership?: boolean
  isPublic?: boolean
  descriptionRef?: (element: HTMLDivElement | null) => void
  includesRef?: (element: HTMLDivElement | null) => void
  descriptionHeight?: number
  includesHeight?: number
}

export function PlanTile({
  plan,
  index,
  memberId,
  currentPlan,
  currentPriceId,
  interval,
  selectPlan,
  isExternalMembership,
  isPublic,
  descriptionRef,
  includesRef,
  descriptionHeight,
  includesHeight,
}: PlanTileProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const theme = useMantineTheme()
  const { data: member } = useGetMember(memberId ?? user?.memberId)
  const { mutateAsync: manageSubscription, isPending: isOpeningManage } = useManageSubscription()

  const priceWithInterval = plan.getPriceWithInterval(interval)

  const hasCurrentPlan = useMemo(() => !!currentPlan, [currentPlan])
  const isCurrentSelectedPrice = useMemo(
    () => currentPriceId === priceWithInterval?.paymentProviderId,
    [currentPriceId, priceWithInterval],
  )

  const isMostPopular = useMemo(() => plan.mostPopular, [plan.mostPopular])
  const hasSelectedColor = useMemo(
    () => isCurrentSelectedPrice || (!hasCurrentPlan && isMostPopular),
    [hasCurrentPlan, isCurrentSelectedPrice, isMostPopular],
  )
  const border = useMemo(() => {
    if (hasSelectedColor) {
      return `2px solid ${theme.colors[theme.primaryColor][4]}`
    }

    return `1px solid ${theme.colors.gray[4]}`
  }, [hasSelectedColor, theme.colors, theme.primaryColor])

  const boxColor = useMemo(() => {
    if (hasSelectedColor) {
      return lighten(theme.colors[theme.primaryColor][6], 0.6)
    }

    return theme.colors.gray[2]
  }, [hasSelectedColor, theme.colors, theme.primaryColor])

  if (!priceWithInterval) {
    return null
  }

  return (
    <Box pos="relative">
      {isMostPopular && !hasCurrentPlan && (
        <Text pos="absolute" top={-25} left={20} size="sm" c={theme.colors[theme.primaryColor][4]}>
          {t('plan.most-popular')}
        </Text>
      )}
      <Flex
        miw={350}
        maw={350}
        h="100%"
        direction="column"
        sx={{
          borderRadius: '8px',
        }}
      >
        <Box
          p="30px 20px"
          flex={0}
          sx={{
            borderLeft: border,
            borderRight: border,
            borderTop: border,
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
          }}
        >
          <Flex direction="column" h={95} justify="flex-start">
            <Text
              size="xl"
              fw={700}
              lh="24px"
              pb={5}
              c={hasSelectedColor ? theme.colors[theme.primaryColor][4] : undefined}
            >
              {plan.name}
            </Text>

            <Text fw={700} size="lg">
              {priceWithInterval ? `$${priceWithInterval.amount.toString()}` : ''}
              <Text
                ml={4}
                c="gray.6"
                component="span"
                size="12px"
              >{`/${t(`plan.interval.${interval.toLowerCase()}`).toLowerCase()}`}</Text>
            </Text>

            {/* Show commitment type badge if it's a monthly plan with yearly commitment */}
            {interval === PriceInterval.MONTHLY &&
              plan.availableCommitmentTypes?.includes(CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT) && (
                <Flex mt={5} align="center">
                  <Badge color="yellow" variant="light" size="sm" leftSection={<IconAlertTriangle size={12} />}>
                    {t('plan.commitment.yearly-label')}
                  </Badge>
                  <Tooltip label={t('plan.commitment.yearly-explanation')}>
                    <Text ml={5} c="gray.6" size="xs">
                      {t('plan.commitment.more-info')}
                    </Text>
                  </Tooltip>
                </Flex>
              )}
          </Flex>

          <Box
            mt={20}
            mb={10}
            maw={300}
            style={descriptionHeight ? { height: `${descriptionHeight}px`, overflow: 'hidden' } : { minHeight: '40px' }}
          >
            <Text ref={descriptionRef} size="sm">
              {plan.description}
            </Text>
          </Box>
          {isPublic ? (
            <Button data-testid={`subscribe-${index}`} variant="filled" onClick={() => selectPlan()}>
              {t('plan.subscribe')}
            </Button>
          ) : (
            <>
              {isCurrentSelectedPrice && !isExternalMembership && (
                <Button
                  variant="outline"
                  color="green"
                  loading={isOpeningManage}
                  onClick={() =>
                    manageSubscription({
                      memberId: memberId || member?.id,
                    })
                  }
                >
                  {t('member.plans.manage')}
                </Button>
              )}
              {(!isCurrentSelectedPrice || isExternalMembership) && (
                <Button
                  data-testid={`subscribe-${index}`}
                  variant="outline"
                  disabled={!!member?.membership?.subscriptionScheduleId}
                  onClick={() => selectPlan()}
                >
                  {hasCurrentPlan ? t('plan.update-subscription') : t('plan.subscribe')}
                </Button>
              )}
            </>
          )}
        </Box>
        <Box
          bg={boxColor}
          flex={1}
          p="20px 20px"
          sx={{
            borderLeft: border,
            borderRight: border,
            borderBottom: border,
            borderBottomRightRadius: '6px',
            borderBottomLeftRadius: '6px',
            minHeight: includesHeight ? `${includesHeight}px` : undefined,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            ref={includesRef}
            sx={{
              flex: '1',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              minHeight: plan.features?.length ? undefined : '40px',
            }}
          >
            <Text size="sm" fw={700}>
              {plan.includedPlanName
                ? `${t('plan.included-plan', {
                    replace: { plan: plan.includedPlanName },
                  })}:`
                : `${t('plan.includes')}:`}
            </Text>

            <List
              mt={20}
              spacing="md"
              size="sm"
              center
              icon={<IconCheck style={{ width: rem(16), height: rem(16) }} />}
            >
              {plan.features?.map((feature, i) => <List.Item key={`feature_${i}`}>{feature}</List.Item>)}
            </List>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
