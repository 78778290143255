/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Box, Button, Divider, Group, Modal, Tabs, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconMail } from '@tabler/icons-react'
import { useGetMember } from 'api/query/member'
import { useSendBulkActivationEmails } from 'api/query/member'
import { Loading } from 'components/loading/loading'
import { Infos } from 'components/tenant/infos'
import { Users } from 'components/tenant/users'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export function Administration() {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const [bulkEmailConfirmOpened, { open: openBulkEmailConfirm, close: closeBulkEmailConfirm }] = useDisclosure(false)
  const sendBulkEmails = useSendBulkActivationEmails()

  const { data: member, isLoading: isLoadingMember } = useGetMember(id)

  if (isLoadingMember) {
    return <Loading size="lg" />
  }

  return (
    <>
      <Title mb={30} order={2}>
        {t('common.menu.admin')}
      </Title>
      <Tabs keepMounted={false} variant="outline" defaultValue="infos">
        <Tabs.List>
          <Tabs.Tab p={10} value="infos">
            {t('admin.tabs.infos')}
          </Tabs.Tab>
          <Tabs.Tab p={10} value="users">
            {t('admin.tabs.users')}
          </Tabs.Tab>
          <Tabs.Tab p={10} value="email">
            {t('admin.tabs.bulk-email')}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel maw={600} value="infos">
          <Infos />
        </Tabs.Panel>
        <Tabs.Panel value="users">
          <Users />
        </Tabs.Panel>
        <Tabs.Panel maw={600} value="email">
          <Box mt={20} mb={30}>
            <Text size="sm" mb={20} c="dimmed">
              {t('admin.bulk-email.explanation')}
            </Text>

            <Group pb={20}>
              <Button
                leftSection={<IconMail size={15} />}
                onClick={openBulkEmailConfirm}
                loading={sendBulkEmails.isPending}
                size="xs"
                title={t('admin.bulk-email.tooltip')}
              >
                {t('admin.bulk-email.send-button')}
              </Button>
            </Group>
          </Box>

          {/* Bulk email confirmation modal */}
          <Modal
            opened={bulkEmailConfirmOpened}
            onClose={closeBulkEmailConfirm}
            title={t('admin.bulk-email.confirm-title')}
          >
            <Text mb={20}>{t('admin.bulk-email.confirm-description')}</Text>
            <Group justify="right">
              <Button variant="default" onClick={closeBulkEmailConfirm} size="xs">
                {t('common.cancel')}
              </Button>
              <Button
                color="blue"
                onClick={() => {
                  sendBulkEmails.mutate()
                  closeBulkEmailConfirm()
                }}
                loading={sendBulkEmails.isPending}
                size="xs"
              >
                {t('admin.bulk-email.confirm-button')}
              </Button>
            </Group>
          </Modal>
        </Tabs.Panel>
      </Tabs>
    </>
  )
}
