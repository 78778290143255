import { Autocomplete } from '@mantine/core'
import { Address } from 'api/domain/entities/member'
import { environment } from 'config/environment'
import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useTranslation } from 'react-i18next'

interface AddressAutocompleteProps {
  currentValue?: Address
  setValue: (value: Address) => void
  error?: boolean
  errorMessage?: string
}

export function AddressAutocomplete({ currentValue, setValue, error, errorMessage }: AddressAutocompleteProps) {
  const { t } = useTranslation()
  const { placePredictions, getPlacePredictions, placesService } = useGoogle({
    apiKey: environment.googleMapApiKey,
  })

  const [autocompleteValue, setAutocompleteValue] = useState('')
  const debounceCallback = debounce((value: string) => {
    if (typeof value !== 'undefined') {
      getPlacePredictions({
        input: value,
      })
    }
  }, 200)

  useEffect(() => {
    if (currentValue) {
      setAutocompleteValue(currentValue.formatted)
    }
  }, [currentValue])

  return (
    <Autocomplete
      data-testid="address-autocomplete"
      withAsterisk
      w="100%"
      value={autocompleteValue}
      label={t('member.fields.address')}
      error={error ? errorMessage : undefined}
      data={placePredictions.map((prediction) => ({
        value: prediction.place_id,
        label: prediction.description,
      }))}
      onChange={(value) => {
        setAutocompleteValue(value)
        debounceCallback(value)
      }}
      onOptionSubmit={(value) => {
        placesService?.getDetails(
          {
            placeId: value,
          },
          (placeDetails: any) => {
            const line1 =
              placeDetails.address_components.find((component: any) => component.types.includes('street_number'))
                ?.long_name +
              ' ' +
              placeDetails.address_components.find((component: any) => component.types.includes('route'))?.long_name
            const city = placeDetails.address_components.find((component: any) =>
              component.types.includes('locality'),
            )?.long_name

            const postalCode = placeDetails.address_components.find((component: any) =>
              component.types.includes('postal_code'),
            )?.long_name

            const stateOrProvince = placeDetails.address_components.find((component: any) =>
              component.types.includes('administrative_area_level_1'),
            )?.short_name

            const country = placeDetails.address_components.find((component: any) =>
              component.types.includes('country'),
            )?.short_name

            return setValue({
              formatted: placeDetails.formatted_address,
              city,
              line1,
              postalCode,
              state: stateOrProvince,
              country,
            })
          },
        )
      }}
    />
  )
}
