import { ActionIcon, Affix, Badge, Pill, Text, Title, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus } from '@tabler/icons-react'
import { Member } from 'api/domain/entities/member'
import { MembershipStatus } from 'api/domain/entities/membership/membership-status'
import { PaymentType } from 'api/domain/entities/membership/payment-type'
import { useListCategories } from 'api/query/category'
import { useListMembers } from 'api/query/member'
import { useListPlans } from 'api/query/plan'
import { AddMemberDrawer } from 'components/drawer/add.member'
import { Loading } from 'components/loading/loading'
import { FiltersModal } from 'components/modal/filters'
import { Table } from 'components/table'
import { AugmentedColumn } from 'components/table/column'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DateTime, DateTimeFormat } from 'utils/date-time'
import { getMembershipStatusColor, getMemberSourceColor, getNeedsValidationColor, getVariantColor } from 'utils/color'
import { MemberSource } from 'api/dto/source'
import { compareMembershipStatus } from 'utils/status'

export function Members() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const { data: members, isLoading: isLoadingMembers } = useListMembers()
  const { data: categories, isLoading: isLoadingCategories } = useListCategories()
  const { data: plans, isLoading: isLoadingPlans } = useListPlans()
  const [addMemberDrawerOpened, { open: openAddMemberDrawer, close: closeAddMemberDrawer }] = useDisclosure(false)
  const [filters, setFilters] = useState<Record<string, any>>({})
  const [filtersOpened, { open: openFilters, close: closeFilters }] = useDisclosure(false)

  const filteredMembers = useMemo(() => {
    if (!members) {
      return []
    }

    return members.filter((member) => {
      if (filters.categoryIds && filters.categoryIds.length > 0) {
        if (!member.categoryIds?.some((categoryId) => filters.categoryIds.includes(categoryId))) {
          return false
        }
      }

      if (filters.statuses && filters.statuses.length > 0) {
        if (!filters.statuses.includes(member.status)) {
          return false
        }
      }

      return true
    })
  }, [members, filters])

  const columns: AugmentedColumn<Member>[] = [
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
    },
    {
      id: 'source',
      accessorKey: 'source',
      header: t('member.columns.source'),
      size: 150,
      minSize: 150,
      filterBySearchbar: {
        enabled: true,
        isSuggestion: true,
        options: Object.values(MembershipStatus).map((status) => ({
          value: status,
          label: t(`member.source.${status}`),
        })),
        renderComponent: (props) => {
          const source = props.value as MemberSource
          const onClick = props.onClick
          const colors = getMemberSourceColor(theme, source)
          return (
            <Badge sx={{ cursor: 'pointer', ...colors }} size="sm" onClick={onClick}>
              {t(`member.source.${source}`)}
            </Badge>
          )
        },
      },
      cell: (col) => {
        const source = col.getValue() as MemberSource
        if (!source) {
          return null
        }

        const colors = getMemberSourceColor(theme, source)
        return (
          <Badge size="sm" sx={{ ...colors }}>
            {t(`member.source.${source}`)}
          </Badge>
        )
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: t('member.columns.name'),
      size: 400,
      minSize: 400,
      cell: (col) => {
        return <Text size="xs">{col.getValue() as string}</Text>
      },
      filterBySearchbar: {
        enabled: true,
      },
    },
    {
      id: 'stateAndCountry',
      accessorKey: 'stateAndCountry',
      header: t('member.columns.stateAndCountry'),
      size: 150,
      minSize: 150,
      cell: (col) => {
        return <Text size="xs">{col.getValue() as string}</Text>
      },
      filterBySearchbar: {
        enabled: true,
        compatibleFields: ['state', 'country'],
      },
    },
    {
      id: 'memberSince',
      accessorKey: 'memberSince',
      header: t('member.columns.memberSince'),
      accessorFn: (member) => member.memberSince.toISOString(),
      size: 100,
      minSize: 100,
      cell: (col) => (
        <Text size="xs">
          {DateTime.fromISOString(col.getValue() as string).toFormat(DateTimeFormat.YearMonthAndDay)}
        </Text>
      ),
      filterBySearchbar: {
        enabled: true,
        renderComponent: (props) => {
          const date = props.value as DateTime
          const onClick = props.onClick
          return (
            <Badge sx={{ cursor: 'pointer' }} size="sm" color="blue" onClick={onClick}>
              {date.toFormat(DateTimeFormat.YearMonthAndDay)}
            </Badge>
          )
        },
      },
    },
    {
      id: 'categories',
      header: t('member.columns.categories'),
      accessorKey: 'categoryIds',
      filterFn: 'arrIncludes',
      size: 100,
      minSize: 100,
      accessorFn: (member) => {
        const categoryIds = member.categoryIds
        if (!categoryIds) {
          return null
        }

        return categoryIds.map((categoryId) => {
          const category = categories.find((category) => category.id === categoryId)

          if (!category) {
            return null
          }

          return category.name
        })
      },
      cell: (col) => {
        const categories = col.getValue() as string[] | undefined

        return categories?.map((category) => (
          <Pill size="xs" mr={2}>
            {category}
          </Pill>
        ))
      },
      filterBySearchbar: {
        enabled: true,
        isSuggestion: true,
        renderComponent: (props) => {
          const category = props.value as string
          const onClick = props.onClick

          return (
            <Pill size="xs" mr={2} sx={{ cursor: 'pointer' }} onClick={onClick}>
              {category}
            </Pill>
          )
        },
      },
    },
    {
      id: 'mainContact',
      accessorKey: 'contact',
      header: t('member.columns.mainContact'),
      size: 150,
      minSize: 150,
      accessorFn: (member) => {
        const contact = member.contact

        if (!contact) {
          return null
        }

        return `${contact.firstName} ${contact.lastName}`
      },
      filterBySearchbar: {
        enabled: true,
      },
    },
    {
      id: 'membership',
      accessorKey: 'membership',
      header: t('member.columns.membership'),
      size: 300,
      minSize: 300,
      filterBySearchbar: {
        enabled: true,
        isSuggestion: true,
      },
      accessorFn: (member) => {
        const membership = member.membership

        if (!membership) {
          return null
        }

        const plan = plans?.find((plan) => plan.id === membership.planId)
        return plan?.name
      },
      cell: (col) => {
        const membership = col.getValue()

        if (!membership) {
          return null
        }

        return <Text size="xs">{membership as string}</Text>
      },
    },
    {
      id: 'renewalDate',
      header: t('member.columns.renewalDate'),
      accessorKey: 'membership',
      size: 100,
      minSize: 100,
      accessorFn: (member) => {
        const membership = member.membership

        if (!membership) {
          return null
        }

        const renewalDate = membership.endDate?.toFormat(DateTimeFormat.YearMonthAndDay)
        return renewalDate
      },
      cell: (col) => {
        const endDate = col.getValue() as string

        if (!endDate) {
          return null
        }

        return <Text size="xs">{endDate}</Text>
      },
      filterBySearchbar: {
        enabled: true,
      },
    },
    {
      id: 'paidBy',
      header: t('member.columns.paidBy'),
      accessorKey: 'membership',
      size: 100,
      minSize: 100,
      filterBySearchbar: {
        enabled: true,
        isSuggestion: true,
        options: Object.values(PaymentType).map((paymentType) => ({
          value: paymentType,
          label: t(`membership.status.${paymentType}`),
        })),
      },
      accessorFn: (member) => {
        const membership = member.membership

        if (!membership) {
          return null
        }

        return membership.paymentType
      },
      cell: (col) => {
        const paymentType = col.getValue() as string

        if (!paymentType) {
          return null
        }

        return <Text size="xs">{t(`membership.paid-by.${paymentType}`)}</Text>
      },
    },
    {
      id: 'status',
      accessorKey: 'membership.status',
      header: t('member.columns.status'),
      size: 150,
      minSize: 150,
      accessorFn: (member) => {
        const membership = member.membership

        if (!membership) {
          return MembershipStatus.PENDING
        }

        if (membership.needsValidation) {
          return MembershipStatus.NEEDS_VALIDATION
        }

        return membership.status
      },
      filterBySearchbar: {
        enabled: true,
        isSuggestion: true,
        options: Object.values(MembershipStatus).map((status) => ({
          value: status,
          label: t(`membership.status.${status}`),
        })),
        renderComponent: (props) => {
          const status = props.value as MembershipStatus
          const onClick = props.onClick
          const colors = getMembershipStatusColor(theme, status)
          return (
            <Badge sx={{ cursor: 'pointer', ...colors }} size="sm" onClick={onClick}>
              {t(`membership.status.${status}`)}
            </Badge>
          )
        },
      },
      cell: (col) => {
        const status = col.getValue() as MembershipStatus
        if (!status) {
          return null
        }

        const colors = getMembershipStatusColor(theme, status)
        return (
          <Badge size="sm" sx={{ ...colors }}>
            {t(`membership.status.${status}`)}
          </Badge>
        )
      },
      sortingFn: (rowA, rowB) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const statusA = rowA.getValue('status') as MembershipStatus
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const statusB = rowB.getValue('status') as MembershipStatus

        return compareMembershipStatus(statusA, statusB)
      },
    },
    {
      id: 'lastPaidAt',
      header: t('member.columns.lastPaidAt'),
      accessorKey: 'membership.lastPaidAt',
      size: 100,
      minSize: 100,
      accessorFn: (member) => {
        const membership = member.membership

        if (!membership) {
          return null
        }

        return membership.lastPaidAt?.toFormat(DateTimeFormat.YearMonthAndDay)
      },
      cell: (col) => {
        const lastPaidAt = col.getValue() as string

        if (!lastPaidAt) {
          return null
        }

        return <Text size="xs">{lastPaidAt}</Text>
      },
      sortUndefined: 'last',
    },
    {
      id: 'mainContactEmail',
      accessorKey: 'contact.email',
    },
    {
      id: 'phone',
      accessorKey: 'phone',
    },
    {
      id: 'address',
      accessorKey: 'address',
      accessorFn: (member) => {
        const address = member.address
        if (!address) {
          return null
        }

        return address.formatted
      },
    },
  ]

  if (isLoadingMembers || isLoadingCategories || isLoadingPlans) {
    return <Loading size="lg" />
  }

  return (
    <>
      <Title mb={30} order={2}>
        {t('member.title')}
      </Title>
      <Table
        tableId="members"
        columns={columns}
        data={filteredMembers}
        hidePagination
        columnVisibilityOverrides={{
          createdAt: false,
          mainContactEmail: false,
          phone: false,
          address: false,
        }}
        onRowClick={(row) => navigate(`/members/${row.original.id}`)}
        exportTable={{
          filename: 'members',
        }}
        defaultSort={{
          id: 'createdAt',
          desc: true,
        }}
      />
      <AddMemberDrawer opened={addMemberDrawerOpened} close={closeAddMemberDrawer} />

      <Affix bottom={20} right={20}>
        <ActionIcon
          radius={100}
          data-testid="add-member"
          display={addMemberDrawerOpened ? 'none' : 'block'}
          size="xl"
          onClick={openAddMemberDrawer}
        >
          <IconPlus size={17} />
        </ActionIcon>
      </Affix>

      <FiltersModal
        isOpen={filtersOpened}
        onClose={closeFilters}
        filters={filters}
        onFilter={(filters) => setFilters(filters)}
      />
    </>
  )
}
