/* eslint-disable @typescript-eslint/naming-convention */
import { ActionIcon, Box, Button, FileInput, Flex, Image, NumberInput, Select, Text, TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { MemberFactory } from 'api/dto/member'
import { useGetMember, useUpdateMember } from 'api/query/member'
import { Loading } from 'components/loading/loading'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'utils/date-time'
import { IconUpload } from '@tabler/icons-react'
import axios from 'config/axios'
import { AddressAutocomplete } from 'components/address/address-autocomplete'
import { Address } from 'api/domain/entities/member'
import { useLocation } from 'react-router-dom'
import { showNotification } from '@mantine/notifications'
import { VisibilityStatus } from 'api/domain/entities/visibility'
import { validateAddress } from 'utils/address'

type PartialMember = {
  name?: string
  address?: Address
  memberSince?: DateTime
  contact?: {
    firstName: string
    lastName: string
    email: string
    jobTitle?: string
  }
  note?: string
  phone?: string
  accountingEmail?: string
}

interface MemberInfosProps {
  onComplete?: () => void
}

export function MemberInfos({ onComplete }: MemberInfosProps) {
  const { user } = useAuth()
  const { t } = useTranslation()
  const location = useLocation()
  const isCompletingProfile = location.pathname === '/member/complete-profile'
  const { data: member, isLoading: isLoadingMember } = useGetMember(user?.memberId)
  const { mutateAsync: updateMember, isPending: isSubmitting } = useUpdateMember()
  const [file, setFile] = useState<File | null | undefined>()
  const [isUploading, setIsUploading] = useState(false)

  const form = useForm({
    initialValues: {
      ...member,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.name || values.name.length < 3) {
        errors.name = t('common.required')
      }

      if (!validateAddress(values.address)) {
        errors['address'] = t('common.required')
      }

      if (!values.visibilityStatus) {
        errors.visibilityStatus = t('common.required')
      }

      if (!values.phone) {
        errors.phone = t('common.required')
      }

      if (!values.accountingEmail) {
        errors.accountingEmail = t('common.required')
      }

      if (values.contact) {
        if (!values.contact.firstName) {
          errors['contact.firstName'] = t('common.required')
        }
        if (!values.contact.lastName) {
          errors['contact.lastName'] = t('common.required')
        }
        if (!values.contact.email) {
          errors['contact.email'] = t('common.required')
        }
      }

      return errors
    },
  })

  async function onSubmit(member: PartialMember) {
    try {
      const updateMemberDto = MemberFactory.toDto({
        ...member,
        memberSince: member.memberSince ? DateTime.fromISOString(member.memberSince.toISOString()) : undefined,
      })

      // If we're completing the profile and all required fields are filled, call onComplete
      if (isCompletingProfile && onComplete) {
        const hasValidAddress = validateAddress(member.address)

        if (hasValidAddress && member.phone && member.accountingEmail) {
          await updateMember(updateMemberDto)
          onComplete()
        } else {
          showNotification({
            title: t('member.infos.incomplete-profile'),
            message: t('member.infos.incomplete-profile-message'),
            color: 'yellow',
          })
        }
      } else {
        await updateMember(updateMemberDto)
      }
    } catch (error) {
      showNotification({
        title: t('member.infos.update-error'),
        message: t('member.infos.update-error-message'),
        color: 'red',
      })
    }
  }

  useEffect(() => {
    if (member) {
      form.setValues({
        ...member,
        memberSince: DateTime.fromISOString(member.memberSince.toISOString()),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member])

  if (isLoadingMember) {
    return <Loading size="lg" />
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box maw={700}>
        <Box pb={10} display="flex" sx={{ borderBottom: '1px solid #cecece', alignItems: 'center' }}>
          <Text size="xl" fw={600}>
            General
          </Text>
          {member?.logo && <Image ml={10} w={30} h={30} src={member?.logo} alt="company logo" />}
        </Box>

        <Box
          mt={10}
          sx={{
            gap: 5,
          }}
          display="flex"
        >
          <TextInput withAsterisk w="100%" label={t('member.fields.company')} {...form.getInputProps('name')} />
          <Flex w="100%" align="center">
            <FileInput
              clearable
              w="100%"
              label={t('member.fields.logo')}
              placeholder={t('member.fields.upload-logo')}
              accept="image/png,image/jpeg"
              onChange={setFile}
            />
            {file && (
              <Flex align="center" mt={20}>
                <Image ml={10} w={30} h={30} src={URL.createObjectURL(file)} alt="company logo" />
                <ActionIcon
                  ml={10}
                  variant="light"
                  loading={isUploading}
                  onClick={() => {
                    if (!file || !member) {
                      return
                    }

                    setIsUploading(true)
                    const fileReader = new FileReader()
                    fileReader.onloadend = async () => {
                      const base64Data = fileReader.result?.toString()

                      if (base64Data) {
                        let encoded = base64Data.replace(/^data:(.*,)?/, '')
                        if (encoded.length % 4 > 0) {
                          encoded += '='.repeat(4 - (encoded.length % 4))
                        }
                        try {
                          const result = await axios.post('/members/me/upload', encoded, {
                            headers: {
                              'file-name': file.name,
                            },
                          })

                          await updateMember(
                            MemberFactory.toDto({
                              ...member,
                              memberSince: member.memberSince
                                ? DateTime.fromISOString(member.memberSince.toISOString())
                                : undefined,
                              logo: result.data.url,
                            }),
                          )
                        } catch (error) {
                          console.error(error)
                        }

                        setFile(undefined)
                        setIsUploading(false)
                      }
                    }
                    fileReader.onerror = () => {
                      setIsUploading(false)
                    }
                    fileReader.readAsDataURL(file)
                  }}
                >
                  <IconUpload />
                </ActionIcon>
              </Flex>
            )}
          </Flex>
        </Box>

        <Box mt={10} display="flex">
          <TextInput
            withAsterisk
            w="100%"
            type="email"
            label={t('member.fields.accountingEmail')}
            {...form.getInputProps('accountingEmail')}
          />
        </Box>

        <Box mt={10} display="flex">
          <TextInput w="100%" label={t('member.fields.phone')} {...form.getInputProps('phone')} />
        </Box>

        <Box mt={10} display="flex">
          <AddressAutocomplete
            currentValue={form.values.address}
            setValue={(value) => form.setFieldValue('address', value)}
            error={Object.keys(form.errors).some((key) => key.startsWith('address'))}
            errorMessage={Object.entries(form.errors)
              .filter(([key]) => key.startsWith('address'))
              .map(([, value]) => value)
              .join(', ')}
          />
        </Box>

        <Select
          mt={10}
          withAsterisk
          w="100%"
          label={t('user.fields.visibility')}
          data={[
            { value: VisibilityStatus.PRIVATE, label: t('user.visibility.private') },
            { value: VisibilityStatus.PUBLIC, label: t('user.visibility.public') },
          ]}
          {...form.getInputProps('visibilityStatus')}
        />

        <Box mt={10} display="flex">
          <DatePickerInput
            w="100%"
            label={t('member.fields.memberSince')}
            {...form.getInputProps('memberSince')}
            value={form.values.memberSince?.date.toDate()}
            disabled
          />
        </Box>

        <Box sx={{ borderBottom: '1px solid #cecece' }} mt={20}>
          <Text size="xl" fw={600} pb={10} w="100%">
            {t('member.fields.contact.title')}
          </Text>
        </Box>

        <Box mt={10} display="flex" sx={{ gap: 5 }}>
          <TextInput
            withAsterisk
            w="100%"
            label={t('member.fields.contact.firstName')}
            {...form.getInputProps('contact.firstName')}
          />
          <TextInput
            withAsterisk
            w="100%"
            label={t('member.fields.contact.lastName')}
            {...form.getInputProps('contact.lastName')}
          />
        </Box>
        <Box mt={10} display="flex">
          <TextInput
            w="100%"
            type="email"
            withAsterisk
            label={t('member.fields.contact.email')}
            {...form.getInputProps('contact.email')}
          />
        </Box>
        <Box mt={10} display="flex">
          <TextInput w="100%" label={t('member.fields.contact.jobTitle')} {...form.getInputProps('contact.jobTitle')} />
        </Box>
        <Box
          pt={20}
          display="flex"
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          <Button disabled={user?.isMemberUser()} loading={isSubmitting} type="submit" size="xs">
            {t('common.update')}
          </Button>
        </Box>
      </Box>
    </form>
  )
}
