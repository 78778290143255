import { Anchor, Button, Text, ThemeIcon } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { Invoice } from 'api/domain/entities/invoice/invoice'
import { useListInvoices } from 'api/query/invoice'
import { PayInvoiceOutOfBandModal } from 'components/invoice/pay-invoice-out-of-band'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import { useTranslation } from 'react-i18next'

export function Invoices() {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { data: invoices, isLoading } = useListInvoices(id)
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | undefined>()

  if (isLoading) {
    return <Loading size="lg" />
  }

  const columns: ColumnDef<Invoice>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: 'Id',
      cell: (col) => <Text size="sm">{col.getValue() as string}</Text>,
    },
    {
      id: 'email',
      accessorKey: 'customer_email',
      header: 'Email',
      cell: (col) => <Text size="sm">{col.getValue() as string}</Text>,
    },
    {
      id: 'amount',
      accessorKey: 'amount_due',
      header: 'Amount',
      cell: (col) => <Text size="sm">{((col.getValue() as number) / 100).toFixed(2)}$</Text>,
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: (col) => <Text size="sm">{capitalize(col.getValue() as string)}</Text>,
    },
    {
      id: 'invoiceUrl',
      accessorKey: 'hosted_invoice_url',
      header: 'Invoice Url',
      cell: (col) =>
        col.getValue() ? (
          <Anchor size="sm" href={col.getValue() as string}>
            Invoice Url
          </Anchor>
        ) : null,
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: (p) =>
        p.row.original.status === 'open' && (
          <Button variant="outline" size="sm" onClick={() => setSelectedInvoiceId(p.row.original.id)}>
            {t('invoice.pay')}
          </Button>
        ),
    },
  ]

  return (
    <>
      <Table<Invoice> searchable={false} hidePagination data={invoices || []} columns={columns} />
      <PayInvoiceOutOfBandModal
        opened={!!selectedInvoiceId}
        onClose={() => setSelectedInvoiceId(undefined)}
        invoiceId={selectedInvoiceId}
      />
    </>
  )
}
