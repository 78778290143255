import { Alert, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { REDIRECT_REASONS } from 'utils/auth-utils'
import { IconInfoCircle } from '@tabler/icons-react'

export function MembershipFeedback() {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const reason = searchParams.get('reason')

  if (!reason) {
    return null
  }

  // Map reason to appropriate message
  let title = ''
  let message = ''
  let icon = <IconInfoCircle />

  switch (reason) {
    case REDIRECT_REASONS.MEMBERSHIP_CANCELED:
      title = t('membership.feedback.canceled.title')
      message = t('membership.feedback.canceled.message')
      break
    case REDIRECT_REASONS.NO_MEMBERSHIP:
      title = t('membership.feedback.no_membership.title')
      message = t('membership.feedback.no_membership.message')
      break
    default:
      return null
  }

  return (
    <Alert title={title} icon={icon} color="blue" mb={20}>
      <Text>{message}</Text>
    </Alert>
  )
}
