import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js'
import isEmpty from 'lodash/isEmpty'

/**
 * Formats a phone number string into a consistent, readable format
 * Preserves extensions (ext, #, extension) in the formatted output
 *
 * @param phoneNumber The phone number to format (can be in various formats)
 * @param defaultCountry The default country code to use if not specified in the number
 */
export function formatPhone(phone: string, defaultCountry: CountryCode = 'US'): string {
  if (isEmpty(phone)) {
    return ''
  }

  const phoneNumber = parsePhoneNumberFromString(phone, defaultCountry)
  if (phoneNumber) {
    return phoneNumber.formatNational()
  }

  return phone // Return the original input if parsing fails
}
