import { Select } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export function LanguageSelector() {
  const { i18n } = useTranslation()

  return (
    <Select
      size="xs"
      value={i18n.language.split('-')[0]}
      onChange={(value) => i18n.changeLanguage(value || undefined)}
      data={[
        { value: 'en', label: 'English' },
        { value: 'fr', label: 'Français' },
      ]}
    />
  )
}
