import { TextInput, Group, Button, Text, Box, Anchor, Image, Switch } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { IconPassword, IconMail, IconBuilding } from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'
import { TenantTitle } from 'components/tenant/tenant-title'
import { Logo } from 'components/logo'
import { Banner } from 'components/banner/banner'
import isEmail from 'validator/lib/isEmail'
import { AddressAutocomplete } from 'components/address/address-autocomplete'
import { Address } from 'api/domain/entities/member'
import { validateAddress } from 'utils/address'

enum RegistrationType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

interface RegisterFormValues {
  email: string
  password: string
  confirmPassword: string
  company: string
  address: Address
  firstName: string
  lastName: string
}

export const Register = () => {
  const { signUp } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [registrationType, setRegistrationType] = useState<RegistrationType>(RegistrationType.COMPANY)

  const isSmallDevice = useMediaQuery('(max-width: 864px)')

  const form = useForm<RegisterFormValues>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      company: '',
      address: {
        formatted: '',
      },
      firstName: '',
      lastName: '',
    },
    validate: {
      email: (value) => (isEmail(value) ? null : 'Invalid email'),
      password: (value) => (value.length > 0 ? null : 'Password is required'),
      // eslint-disable-next-line quotes
      confirmPassword: (value, values) => (value === values?.password ? null : "Password doesn't match"),
      company: (value) =>
        (registrationType === RegistrationType.COMPANY && value?.length) || 0 > 0 ? null : 'Company name is required',
      firstName: (value) => (value.length > 0 ? null : 'First name is required'),
      lastName: (value) => (value.length > 0 ? null : 'Last name is required'),
      address: (value) => (value && validateAddress(value) ? null : 'Address is required'),
    },
  })

  async function onSubmit(values: RegisterFormValues) {
    setIsLoading(true)
    await signUp(values.email, values.password, {
      ...values,
      family_name: values.lastName,
      given_name: values.firstName,
    })
    setIsLoading(false)
  }

  if (isSmallDevice) {
    return (
      <Box w="100%" display="flex" sx={{ flexDirection: 'column' }}>
        <Box pt={20} pl={30}>
          <TenantTitle />
        </Box>
        <Box
          pl={30}
          pr={30}
          mt={20}
          mb={20}
          h={140}
          sx={(theme) => ({
            background: theme.colors[theme.primaryColor][5],
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          })}
        >
          <Text ta="center" fw={400} c="white" size="30px">
            {t('authentication.register.title')}
          </Text>
        </Box>
        <RegisterForm
          form={form}
          isLoading={isLoading}
          onSubmit={onSubmit}
          registrationType={registrationType}
          setRegistrationType={setRegistrationType}
        />

        <Box>
          <Text ta="center" mt={20} size="sm">
            {t('authentication.register.already-have-account')}{' '}
            <Anchor underline="always" size="sm" onClick={() => navigate('/session/login')}>
              {t('authentication.register.login-now')}
            </Anchor>
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box pos="relative" left={-40} display="flex" sx={{ alignItems: 'center' }}>
      <Box
        w={500}
        display="flex"
        h={700}
        right={-80}
        sx={(theme) => ({
          background: theme.colors[theme.primaryColor][5],
          overflow: 'hidden',
          flexFlow: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          paddingTop: '237px',
          position: 'relative',
        })}
      >
        <Banner />
      </Box>

      <Box
        h={650}
        w={500}
        bg="white"
        display="flex"
        sx={{
          flex: 1,
          flexFlow: 'column',
          padding: '20px 30px',
          zIndex: 100,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
      >
        <Logo width={100} />
        <Box mt={10} mb={5} sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Text ta="center" fw={400} size="xl">
            {t('authentication.register.title')}
          </Text>
        </Box>

        <RegisterForm
          form={form}
          isLoading={isLoading}
          onSubmit={onSubmit}
          registrationType={registrationType}
          setRegistrationType={setRegistrationType}
        />

        <Box>
          <Text ta="center" mt={20} size="sm">
            {t('authentication.register.already-have-account')}{' '}
            <Anchor underline="always" size="sm" onClick={() => navigate('/session/login')}>
              {t('authentication.register.login-now')}
            </Anchor>
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

interface RegisterFormProps {
  form: UseFormReturnType<RegisterFormValues>
  onSubmit: (values: RegisterFormValues) => Promise<void>
  isLoading: boolean
  setRegistrationType: (type: RegistrationType) => void
  registrationType: RegistrationType
}

function RegisterForm({ form, isLoading, onSubmit, registrationType, setRegistrationType }: RegisterFormProps) {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box w="80%" maw={650}>
        {/*
          // Whether to show the company or individual registration form should be coming from the tenant
        <Box
          display="flex"
          sx={{
            justifyContent: 'center',
            marginBottom: '5px',
          }}
        >
          <Switch
            size="xl"
            onLabel="COMPANY"
            offLabel="INDIVIDUAL"
            checked={registrationType === RegistrationType.COMPANY}
            onChange={(e) =>
              setRegistrationType(e.target.checked ? RegistrationType.COMPANY : RegistrationType.INDIVIDUAL)
            }
            styles={{
              track: {
                padding: 20,
              },
            }}
          />
        </Box>*/}

        <form onSubmit={form.onSubmit(onSubmit)}>
          {registrationType === RegistrationType.COMPANY && (
            <TextInput
              withAsterisk
              size="sm"
              mb={5}
              sx={{
                input: {
                  borderRadius: '4px !important',
                },
              }}
              leftSection={<IconBuilding width={15} height={15} stroke="#cecece" />}
              label={t('authentication.register.fields.company') || ''}
              {...form.getInputProps('company')}
            />
          )}

          <Box mb={5} display="flex">
            <AddressAutocomplete
              currentValue={form.values.address}
              setValue={(value) => form.setFieldValue('address', value)}
              error={Object.keys(form.errors).some((key) => key.startsWith('address'))}
              errorMessage={Object.entries(form.errors)
                .filter(([key]) => key.startsWith('address'))
                .map(([, value]) => value)
                .join(', ')}
            />
          </Box>

          <Box mb={5} display="flex" sx={{ gap: 5 }}>
            <TextInput
              w="100%"
              withAsterisk
              label={t('authentication.register.fields.firstName')}
              {...form.getInputProps('firstName')}
            />
            <TextInput
              w="100%"
              withAsterisk
              label={t('authentication.register.fields.lastName')}
              {...form.getInputProps('lastName')}
            />
          </Box>
          <TextInput
            size="sm"
            withAsterisk
            mb={5}
            sx={{
              input: {
                borderRadius: '4px !important',
              },
            }}
            leftSection={<IconMail width={15} height={15} stroke="#cecece" />}
            label={t('authentication.register.fields.email') || ''}
            {...form.getInputProps('email')}
          />

          <TextInput
            mb={5}
            size="sm"
            withAsterisk
            sx={{
              input: {
                borderRadius: '4px !important',
              },
            }}
            leftSection={<IconPassword width={15} height={15} stroke="#cecece" />}
            type="password"
            label={t('authentication.register.fields.password') || ''}
            {...form.getInputProps('password')}
          />
          <TextInput
            mb={5}
            size="sm"
            withAsterisk
            sx={{
              input: {
                borderRadius: '4px !important',
              },
            }}
            leftSection={<IconPassword width={15} height={15} stroke="#cecece" />}
            type="password"
            label={t('authentication.register.fields.confirmPassword') || ''}
            {...form.getInputProps('confirmPassword')}
          />

          <Group mt={20} align="center">
            <Button
              w="100%"
              size="sm"
              radius="sm"
              type="submit"
              loading={isLoading}
              disabled={!form.isValid()}
              sx={{
                lineHeight: '22px',
                fontWeight: 400,
              }}
            >
              {t('authentication.register.submit')}
            </Button>
          </Group>
        </form>
      </Box>
    </Box>
  )
}
