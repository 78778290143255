import { ActionIcon, Badge, Box, Group, Text, Tooltip } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { useListMemberDocuments, useGetMemberDocumentUrl } from 'api/query/document'
import { Loading } from 'components/loading/loading'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Document } from 'api/domain/entities/document'
import { Table } from 'components/table/table'
import { ColumnDef } from '@tanstack/react-table'
import { DateTime, DateTimeFormat } from 'utils/date-time'

export function MemberDocumentList() {
  const { t } = useTranslation()
  const { data: documents, isLoading } = useListMemberDocuments()
  const [downloadingDocumentIds, setDownloadingDocumentIds] = useState<string[]>([])
  const getDocumentUrl = useGetMemberDocumentUrl()

  if (isLoading) {
    return <Loading />
  }

  const handleDownload = (document: Document) => {
    setDownloadingDocumentIds((prev) => [...prev, document.id])

    getDocumentUrl.mutate(document.id, {
      onSuccess: (url) => {
        // Open the download URL in a new tab
        window.open(url, '_blank')
        setDownloadingDocumentIds((prev) => prev.filter((id) => id !== document.id))
      },
      onError: () => {
        setDownloadingDocumentIds((prev) => prev.filter((id) => id !== document.id))
      },
    })
  }

  const columns: ColumnDef<Document>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: t('document.columns.name'),
      cell: (info) => (
        <Group gap="xs">
          <Text>{info.getValue() as string}</Text>
        </Group>
      ),
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: t('document.columns.description'),
      cell: (info) => <Text size="sm">{(info.getValue() as string) || '-'}</Text>,
    },
    {
      id: 'fileSize',
      accessorFn: (row) => row.getFormattedFileSize(),
      header: t('document.columns.size'),
      cell: (info) => <Text size="sm">{info.getValue() as string}</Text>,
    },
    {
      id: 'createdAt',
      accessorFn: (row) => DateTime.fromISOString(row.createdAt).toFormat(DateTimeFormat.YearMonthAndDay),
      header: t('document.columns.created'),
      cell: (info) => <Text size="sm">{info.getValue() as string}</Text>,
    },
    {
      id: 'actions',
      header: t('common.actions'),
      cell: (info) => (
        <Group gap="xs">
          <Tooltip label={t('document.download')}>
            <ActionIcon
              color="blue"
              onClick={() => handleDownload(info.row.original)}
              disabled={downloadingDocumentIds.includes(info.row.original.id)}
              loading={downloadingDocumentIds.includes(info.row.original.id)}
            >
              <IconDownload size="1rem" />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
    },
  ]

  return (
    <>
      {documents && documents.length > 0 ? (
        <Table
          columns={columns}
          data={documents}
          hidePagination
          exportTable={{
            filename: 'my-documents',
          }}
        />
      ) : (
        <Box sx={{ textAlign: 'center', padding: '2rem' }}>
          <Text size="lg" fw={500}>
            {t('document.no-documents')}
          </Text>
          <Text size="sm" color="dimmed" mt="sm">
            {t('document.no-documents-description')}
          </Text>
        </Box>
      )}
    </>
  )
}
