import { Text, Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { MemberInfos } from './member-infos'
import { useGetMember } from 'api/query/member'
import { useAuth } from 'hooks/useAuth'

export function CompleteProfile() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data: member } = useGetMember(user?.memberId)
  const isExternalMember = member?.membership?.isExternal

  return (
    <Box maw={800} mx="auto" mt={20}>
      <Text size="xl" mb={20}>
        {isExternalMember ? t('member.complete-profile.external-title') : t('member.complete-profile.title')}
      </Text>

      <Text mb={30} c="dimmed">
        {isExternalMember
          ? t('member.complete-profile.external-description')
          : t('member.complete-profile.description')}
      </Text>

      <MemberInfos onComplete={isExternalMember ? () => (window.location.href = '/member/plans') : undefined} />
    </Box>
  )
}
