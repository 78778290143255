import { Box, Text, Image, Center } from '@mantine/core'
import { IconBuilding } from '@tabler/icons-react'
import { PublicMember } from 'api/domain/entities/member/public-member'
import { usePublicMembers } from 'api/query/public'
import { LanguageSelector } from 'components/language/language-selector'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { AugmentedColumn } from 'components/table/column'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { formatPhone } from 'utils/phone'

export function PublicMembers() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: members, isLoading } = usePublicMembers()

  const columns: AugmentedColumn<PublicMember>[] = [
    {
      id: 'name',
      header: t('member.columns.name'),
      accessorKey: 'name',
      cell: (col) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box w={24} h={24}>
            {col.row.original.logo ? (
              <Image src={col.row.original.logo} alt="logo" radius="md" fit="contain" />
            ) : (
              <Center h="100%" bg="gray.1" style={{ borderRadius: 8 }}>
                <IconBuilding size={16} color="gray" />
              </Center>
            )}
          </Box>
          <Text size="xs">{col.getValue() as string}</Text>
        </Box>
      ),
    },
    {
      id: 'phoneNumber',
      accessorKey: 'phone',
      header: t('member.columns.phone'),
      cell: (col) => <Text size="xs">{formatPhone(col.getValue() as string)}</Text>,
    },
    {
      id: 'province',
      accessorKey: 'address.state',
      header: t('member.columns.province'),
      cell: (col) => <Text size="xs">{col.getValue() as string}</Text>,
      filterBySearchbar: {
        enabled: true,
        isSuggestion: true,
      },
    },
  ]

  if (isLoading) {
    return <Loading size="lg" />
  }

  return (
    <Box p="md" maw={1200} mx="auto">
      <Box mb="md" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LanguageSelector />
      </Box>
      <Table
        columns={columns}
        data={members || []}
        defaultSort={{ id: 'name', desc: false }}
        searchable
        hidePagination
        onRowClick={(row) => navigate(`/public-info/members/${row.original.id}`)}
      />
    </Box>
  )
}
