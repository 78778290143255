import { Box, Button, Drawer, Group, Select, Text, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { User, UserRole } from 'api/domain/entities/user'
import { VisibilityStatus } from 'api/domain/entities/visibility'
import { UserFactory } from 'api/dto/user'
import { useUpdateUser } from 'api/query/user'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface EditMemberUserProps {
  opened: boolean
  close: () => void
  user?: User
}

type PartialUser = {
  firstName?: string
  lastName?: string
  visibilityStatus?: VisibilityStatus
}

export function EditMemberUserDrawer({ opened, close, user }: EditMemberUserProps) {
  const { t } = useTranslation()
  const { mutateAsync: updateUser } = useUpdateUser(user?.id)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm({
    initialValues: {
      ...user,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.firstName || values.firstName.length < 1) {
        errors.firstName = t('common.required')
      }

      if (!values.lastName || values.lastName.length < 1) {
        errors.lastName = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(partialUser: PartialUser) {
    setIsSubmitting(true)
    const updateUserDto = UserFactory.toDto(partialUser)
    try {
      await updateUser(updateUserDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    close()
    form.reset()
    setIsSubmitting(false)
  }

  useEffect(() => {
    form.setValues({ ...user })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('member.edit')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('member.edit-description')}</Text>
              <TextInput
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.firstName')}
                {...form.getInputProps('firstName')}
              />
              <TextInput
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.lastName')}
                {...form.getInputProps('lastName')}
              />
              <TextInput
                mt={10}
                disabled
                withAsterisk
                w="100%"
                type="email"
                label={t('user.fields.email')}
                {...form.getInputProps('email')}
              />

              <Select
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.role')}
                data={[
                  { value: UserRole.MEMBER_MANAGER, label: t('user.roles.manager') },
                  { value: UserRole.MEMBER_USER, label: t('user.roles.user') },
                ]}
                {...form.getInputProps('role')}
              />

              <Select
                mt={10}
                withAsterisk
                w="100%"
                label={t('user.fields.visibility')}
                data={[
                  { value: VisibilityStatus.PRIVATE, label: t('user.visibility.private') },
                  { value: VisibilityStatus.PUBLIC, label: t('user.visibility.public') },
                ]}
                {...form.getInputProps('visibilityStatus')}
              />
            </Box>

            <Box
              pt={20}
              display="flex"
              sx={{
                borderTop: '1px solid #cecece',
                justifyContent: 'flex-end',
              }}
              flex={0}
            >
              <Button disabled={!form.isValid() || isSubmitting} loading={isSubmitting} type="submit" size="xs">
                {t('common.update')}
              </Button>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}
