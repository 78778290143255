export enum DocumentAccess {
  ALL_MEMBERS = 'ALL_MEMBERS',
  SPECIFIC_MEMBERS = 'SPECIFIC_MEMBERS',
  SPECIFIC_MEMBERSHIPS = 'SPECIFIC_MEMBERSHIPS',
}

export enum DocumentStatus {
  PENDING = 'PENDING',
  AVAILABLE = 'AVAILABLE',
  ERROR = 'ERROR',
}

export interface CreateDocumentDto {
  name: string
  description?: string
  accessType: DocumentAccess
  accessIds: string[]
}

export interface UpdateDocumentDto {
  name?: string
  description?: string
  accessType?: DocumentAccess
  accessIds?: string[]
}

export interface DocumentDto {
  id: string
  tenantId: string
  name: string
  description?: string
  fileType: DocumentType
  fileSize: number
  accessType: DocumentAccess
  accessIds: string[]
  downloadUrl?: string
  createdAt: string
  updatedAt?: string
  status: DocumentStatus
}

export interface DocumentUploadResult {
  documentId: string
}

export interface GetDocumentUrlResult {
  downloadUrl: string
}
