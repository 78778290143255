import { UserRole } from 'api/domain/entities/user'
import { useGetMember } from 'api/query/member'
import { Loading } from 'components/loading/loading'
import { useAuth } from 'hooks/useAuth'
import { ReactNode, useEffect } from 'react'
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  ROUTES,
  getDefaultRedirectRoute,
  hasRequiredRole,
  needsProfileCompletion,
  checkMembershipRequirements,
} from 'utils/auth-utils'

export function RequireAuthentication({
  children,
  role,
  requireActiveMembership = false,
}: {
  children: ReactNode | ReactNode[]
  role?: UserRole | UserRole[]
  requireActiveMembership?: boolean
}) {
  const { user, isAuthenticated, appIsHydrated } = useAuth()
  const { data: member, isLoading: isLoadingMember } = useGetMember(user?.memberId)
  const location = useLocation()

  // Handle race conditions in data loading
  useEffect(() => {
    // If user is authenticated but member query failed, log error
    if (isAuthenticated && user?.memberId && !member && !isLoadingMember) {
      console.error('Failed to load member data')
    }
  }, [isAuthenticated, user?.memberId, member, isLoadingMember])

  // Handle loading state
  if (isLoadingMember) {
    return <Loading size="lg" />
  }

  // Check authentication
  if (!isAuthenticated && appIsHydrated) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
  }

  // Check profile completion
  const profileRedirect = needsProfileCompletion(member, location.pathname)
  if (profileRedirect) {
    return <Navigate to={profileRedirect} replace />
  }

  // Check role requirements
  if (!hasRequiredRole(user, role)) {
    const redirectRoute = getDefaultRedirectRoute(user, !!member?.hasMembership)
    return <Navigate to={redirectRoute} replace />
  }

  // Check membership requirements
  const { redirectTo, reason } = checkMembershipRequirements(user, member, location.pathname, requireActiveMembership)

  if (redirectTo && reason) {
    // Add the reason as a query parameter for displaying feedback
    return <Navigate to={`${redirectTo}?reason=${reason}`} replace />
  }

  return <>{children}</>
}
