import {
  Button,
  Group,
  Modal,
  Text,
  Textarea,
  Grid,
  Stack,
  Alert,
  Tabs,
  Select,
  Radio,
  SegmentedControl,
} from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { DateInput } from '@mantine/dates'
import { MembershipStatus } from 'api/domain/entities/membership/membership-status'
import { Plan } from 'api/domain/entities/plan'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { PaymentType } from 'api/domain/entities/membership/payment-type'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'

interface ManageImportedMembershipModalProps {
  isOpen: boolean
  onClose: () => void
  onStatusChange: (
    status: MembershipStatus,
    note: string,
    startDate?: string,
    endDate?: string,
    planId?: string,
    priceId?: string,
    lastPaidAt?: string,
    paymentType?: PaymentType,
  ) => Promise<void>
  isLoading?: boolean
  currentPlan?: Plan
  plans?: Plan[]
}

type OperationType = 'cancel' | 'markPaid'

export function ManageImportedMembershipModal({
  isOpen,
  onClose,
  onStatusChange,
  isLoading = false,
  currentPlan,
  plans = [],
}: ManageImportedMembershipModalProps) {
  const { t } = useTranslation()
  const [note, setNote] = useState('')
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [lastPaidDate, setLastPaidDate] = useState<Date | null>(new Date())
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(currentPlan?.id || null)
  const [intervalType, setIntervalType] = useState<PriceInterval>(PriceInterval.YEARLY)
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.MANUAL)
  const [activeTab, setActiveTab] = useState<OperationType>('markPaid')
  const [error, setError] = useState('')

  // Calculate end date based on start date and selected plan
  useEffect(() => {
    if (startDate) {
      // Default to 1 year if no specific plan info is available
      const defaultEndDate = new Date(startDate)
      defaultEndDate.setFullYear(defaultEndDate.getFullYear() + 1)
      defaultEndDate.setDate(defaultEndDate.getDate() - 1)

      setEndDate(defaultEndDate)
    }
  }, [startDate, selectedPlanId])

  // Reset state when modal opens/closes
  useEffect(() => {
    if (isOpen) {
      setSelectedPlanId(currentPlan?.id || null)
      // Default to yearly subscription
      setIntervalType(PriceInterval.YEARLY)
    } else {
      setNote('')
      setStartDate(new Date())
      setEndDate(null)
      setLastPaidDate(new Date())
      setError('')
    }
  }, [isOpen, currentPlan])

  const handleClose = () => {
    setNote('')
    setStartDate(new Date())
    setEndDate(null)
    setLastPaidDate(new Date())
    setError('')
    onClose()
  }

  const isSubmitDisabled = !note.trim()

  const handleSubmit = async () => {
    if (isSubmitDisabled) {
      setError(t('common.required'))
      return
    }

    setError('')

    const status = activeTab === 'cancel' ? MembershipStatus.CANCELED : MembershipStatus.SUBSCRIBED

    // Convert dates to ISO strings for markPaid operation only
    const startDateString = activeTab === 'markPaid' && startDate ? startDate.toISOString() : undefined
    const endDateString = activeTab === 'markPaid' && endDate ? endDate.toISOString() : undefined
    const lastPaidDateString = activeTab === 'markPaid' && lastPaidDate ? lastPaidDate.toISOString() : undefined

    // Get the selected plan and associated price ID based on interval
    let planId: string | undefined = undefined
    let priceId: string | undefined = undefined

    if (activeTab === 'markPaid' && selectedPlanId) {
      const selectedPlan = plans.find((plan) => plan.id === selectedPlanId)

      if (selectedPlan) {
        planId = selectedPlan.id

        // Determine the price ID based on interval type
        if (intervalType === PriceInterval.MONTHLY && selectedPlan.monthlyPrice?.paymentProviderId) {
          priceId = selectedPlan.monthlyPrice.paymentProviderId
        } else if (intervalType === PriceInterval.YEARLY && selectedPlan.yearlyPrice?.paymentProviderId) {
          priceId = selectedPlan.yearlyPrice.paymentProviderId
        }
      }
    }

    await onStatusChange(status, note, startDateString, endDateString, planId, priceId, lastPaidDateString, paymentType)
    handleClose()
  }

  const getPlanOptions = () => {
    return plans.map((plan) => ({
      value: plan.id,
      label: plan.name,
    }))
  }

  const getFilteredPlanOptions = () => {
    // Filter plans based on the selected interval
    return plans
      .filter((plan) => {
        if (intervalType === PriceInterval.MONTHLY) {
          return !!plan.monthlyPrice?.paymentProviderId
        } else {
          return !!plan.yearlyPrice?.paymentProviderId
        }
      })
      .map((plan) => ({
        value: plan.id,
        label: plan.name,
      }))
  }

  const getSelectedPlan = () => {
    if (!selectedPlanId) return null
    return plans.find((plan) => plan.id === selectedPlanId)
  }

  // Check if the selected plan has the current interval option available
  const isIntervalAvailable = (interval: PriceInterval) => {
    const plan = getSelectedPlan()
    if (!plan) return false

    if (interval === PriceInterval.MONTHLY) {
      return !!plan.monthlyPrice?.paymentProviderId
    } else {
      return !!plan.yearlyPrice?.paymentProviderId
    }
  }

  return (
    <Modal opened={isOpen} onClose={handleClose} title={t('member.membership.manage-imported')} size="lg">
      <Stack mb={15} gap="md">
        <Alert icon={<IconAlertCircle size={16} />} color="blue" title={t('member.membership.external-member-context')}>
          {t('member.membership.manage-imported-explanation')}
        </Alert>
      </Stack>

      <Tabs value={activeTab} onChange={(value) => setActiveTab(value as OperationType)}>
        <Tabs.List grow mb="md">
          <Tabs.Tab value="markPaid" color="green">
            {t('member.membership.tab.mark-paid')}
          </Tabs.Tab>
          <Tabs.Tab value="cancel" color="red">
            {t('member.membership.tab.cancel')}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="markPaid">
          <Stack gap="md">
            <Text>{t('member.membership.mark-paid-prompt')}</Text>

            <Stack gap="xs">
              <Text size="sm" fw={600}>
                {t('member.membership.select-interval')}
              </Text>
              <SegmentedControl
                value={intervalType}
                onChange={(value) => {
                  setIntervalType(value as PriceInterval)
                  // Clear selected plan when interval changes
                  setSelectedPlanId(null)
                }}
                data={[
                  {
                    label: t('plan.interval.monthly'),
                    value: PriceInterval.MONTHLY,
                  },
                  {
                    label: t('plan.interval.yearly'),
                    value: PriceInterval.YEARLY,
                  },
                ]}
              />
            </Stack>

            <Stack gap="xs">
              <Text size="sm" fw={600}>
                {t('member.membership.select-plan')}
              </Text>
              <Select
                placeholder={t('member.membership.select-plan-placeholder')}
                data={getFilteredPlanOptions()}
                value={selectedPlanId}
                onChange={setSelectedPlanId}
              />
            </Stack>

            <Grid>
              <Grid.Col span={6}>
                <Stack gap="xs">
                  <Text size="sm" fw={600}>
                    {t('member.membership.last-paid-date')}
                  </Text>
                  <DateInput
                    value={lastPaidDate}
                    onChange={setLastPaidDate}
                    placeholder={t('member.membership.date-placeholder')}
                    clearable
                  />
                </Stack>
              </Grid.Col>
              <Grid.Col span={6}>
                <Stack gap="xs">
                  <Text size="sm" fw={600}>
                    {t('membership.paid-by.title')}
                  </Text>
                  <Select
                    value={paymentType}
                    onChange={(value) => setPaymentType(value as PaymentType)}
                    data={[
                      { value: PaymentType.MANUAL, label: t('membership.paid-by.manual') },
                      { value: PaymentType.CHECK, label: t('membership.paid-by.check') },
                    ]}
                  />
                </Stack>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={6}>
                <Stack gap="xs">
                  <Text size="sm" fw={600}>
                    {t('member.membership.start-date')}
                  </Text>
                  <DateInput
                    value={startDate}
                    onChange={setStartDate}
                    placeholder={t('member.membership.date-placeholder')}
                  />
                </Stack>
              </Grid.Col>
              <Grid.Col span={6}>
                <Stack gap="xs">
                  <Text size="sm" fw={600}>
                    {t('member.membership.end-date')}
                  </Text>
                  <DateInput
                    value={endDate}
                    onChange={setEndDate}
                    placeholder={t('member.membership.date-placeholder')}
                  />
                </Stack>
              </Grid.Col>
            </Grid>
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="cancel">
          <Text mb="md">{t('member.membership.cancel-prompt')}</Text>
        </Tabs.Panel>
      </Tabs>

      <Textarea
        value={note}
        onChange={(e) => setNote(e.currentTarget.value)}
        label={t('member.membership.note-label')}
        placeholder={t('member.membership.status-note-placeholder')}
        rows={4}
        my={20}
        required
        error={error}
      />

      <Group justify="flex-end">
        <Button variant="outline" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button
          variant="outline"
          loading={isLoading}
          onClick={handleSubmit}
          color={activeTab === 'cancel' ? 'red' : 'green'}
          disabled={isSubmitDisabled}
        >
          {activeTab === 'cancel' ? t('member.membership.confirm-cancel') : t('member.membership.confirm-paid')}
        </Button>
      </Group>
    </Modal>
  )
}
