import { Modal, Box, Checkbox, Group, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { IconGripVertical } from '@tabler/icons-react'
import './table-column-manager.css'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Table } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { AugmentedColumn } from './column'

// Table configuration interface
export interface TableConfig {
  columnVisibility: Record<string, boolean>
  columnOrder: string[]
  sorting: { id: string; desc: boolean }[]
}

interface SortableColumnItemProps {
  id: string
  label: string
  isVisible: boolean
  isRequired?: boolean
  onToggle: () => void
}

function SortableColumnItem({ id, label, isVisible, isRequired, onToggle }: SortableColumnItemProps) {
  const { t } = useTranslation()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Group
      ref={setNodeRef}
      style={style}
      p="md"
      bg={isVisible ? 'gray.0' : 'gray.1'}
      mb={12}
      sx={{
        borderRadius: 8,
        height: '50px',
        border: '1px solid #eaeaea',
        boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
        transition: 'all 0.2s ease',
      }}
      className="sortable-column-item"
    >
      <Box
        {...attributes}
        {...listeners}
        sx={{
          cursor: 'grab',
          display: 'flex',
          alignItems: 'center',
          color: '#aaa',
        }}
        className="handle-icon"
      >
        <IconGripVertical size={18} />
      </Box>
      <Checkbox
        checked={isVisible}
        onChange={onToggle}
        disabled={isRequired}
        label={label}
        styles={{
          root: { flex: 1 },
          label: {
            fontSize: '14px',
            fontWeight: 500,
            color: isVisible ? 'inherit' : '#888',
          },
        }}
      />
      {isRequired && (
        <Text
          size="xs"
          fw={600}
          c="blue.6"
          ml={-5}
          sx={{
            padding: '2px 8px',
            backgroundColor: 'rgba(51, 154, 240, 0.1)',
            borderRadius: '4px',
          }}
        >
          {t('common.required-column')}
        </Text>
      )}
    </Group>
  )
}

interface TableColumnManagerProps<T> {
  table: Table<T>
  columns: AugmentedColumn<T>[]
  columnOrder: string[]
  opened: boolean
  onClose: () => void
  onColumnOrderChange: (newOrder: string[]) => void
}

export function TableColumnManager<T>({
  table,
  columns,
  columnOrder,
  opened,
  onClose,
  onColumnOrderChange,
}: TableColumnManagerProps<T>) {
  const { t } = useTranslation()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id as string)
      const newIndex = columnOrder.indexOf(over.id as string)
      const newOrder = arrayMove(columnOrder, oldIndex, newIndex)
      onColumnOrderChange(newOrder)
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('common.manage-columns')}
      size="md"
      styles={{
        title: {
          fontSize: '18px',
          fontWeight: 600,
          marginBottom: '5px',
        },
        body: {
          padding: '20px',
        },
      }}
    >
      <Box mb={20}>
        <Text size="sm" c="dimmed" lh={1.5}>
          {t('common.default-columns-note')}
        </Text>
      </Box>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={columnOrder} strategy={verticalListSortingStrategy}>
          {columnOrder.map((colId) => {
            const column = columns.find((c) => c.id === colId)
            if (!column?.id) return null

            const foundColumn = table.getColumn(column.id)
            if (!foundColumn) return null

            return (
              <SortableColumnItem
                key={column.id}
                id={column.id}
                label={column.header?.toString() ?? column.id}
                isVisible={foundColumn.getIsVisible()}
                onToggle={() => {
                  foundColumn.toggleVisibility()
                }}
              />
            )
          })}
        </SortableContext>
      </DndContext>
    </Modal>
  )
}

/**
 * Loads table configuration from localStorage or creates a default configuration
 */
export function useTableConfig<T>({
  tableId,
  columns,
  columnVisibilityOverrides,
  defaultSort,
  initialVisibility,
}: {
  tableId?: string
  columns: AugmentedColumn<T>[]
  columnVisibilityOverrides?: Record<string, boolean>
  defaultSort?: { id: string; desc: boolean }
  initialVisibility?: Record<string, boolean>
}): [
  TableConfig,
  {
    setColumnVisibility: (
      updater: Record<string, boolean> | ((prev: Record<string, boolean>) => Record<string, boolean>),
    ) => void
    setColumnOrder: (newOrder: string[]) => void
    setSortingState: (updater: any) => void
  },
] {
  // Create a stable table ID for localStorage
  const storageId = useMemo(() => {
    if (tableId) return `table-config-${tableId}`

    // Generate a fallback ID based on column names if no tableId provided
    const columnSignature = columns
      .map((c) => c.id || '')
      .filter(Boolean)
      .join('-')

    return `table-config-${columnSignature.substring(0, 40)}`
  }, [tableId, columns])

  // Create default table configuration
  const defaultConfig = useMemo<TableConfig>(() => {
    // Get valid column IDs once
    const validColumnIds = columns
      .filter((col) => col.id! && (columnVisibilityOverrides || {})[col.id] !== false)
      .map((col) => col.id!)
      .filter((id): id is string => Boolean(id))

    const config: TableConfig = {
      columnVisibility: columnVisibilityOverrides || {},
      columnOrder: validColumnIds,
      sorting: defaultSort ? [defaultSort] : [],
    }

    // Apply initial visibility if provided
    if (initialVisibility) {
      config.columnVisibility = { ...config.columnVisibility, ...initialVisibility }
    }
    // Set all columns visible by default
    else {
      validColumnIds.forEach((colId) => {
        config.columnVisibility[colId] = true
      })
    }

    return config
  }, [columnVisibilityOverrides, columns, defaultSort, initialVisibility])

  // Load configuration from localStorage or use default
  const [tableConfig, setTableConfig] = useState<TableConfig>(() => {
    // Get valid column IDs for validation
    const validColumnIds = columns
      .filter((col) => col.id! && (columnVisibilityOverrides || {})[col.id] !== false)
      .map((col) => col.id!)
      .filter((id): id is string => Boolean(id))

    try {
      const savedConfig = localStorage.getItem(storageId)
      if (savedConfig) {
        const parsedConfig = JSON.parse(savedConfig) as TableConfig

        // Validate against current columns
        const validOrderedColumns = parsedConfig.columnOrder?.filter((id) => validColumnIds.includes(id)) || []

        // Find any new columns not in saved config
        const newColumns = validColumnIds.filter((id) => !validOrderedColumns.includes(id))

        // Clean up visibility state to only include valid columns
        const cleanedVisibility: Record<string, boolean> = columnVisibilityOverrides || {}
        validColumnIds.forEach((id) => {
          // Use saved visibility if available, otherwise use default
          if (parsedConfig.columnVisibility && id in parsedConfig.columnVisibility) {
            cleanedVisibility[id] = parsedConfig.columnVisibility[id]
          } else {
            // For new columns, make them visible by default
            cleanedVisibility[id] = true
          }
        })

        return {
          columnVisibility: cleanedVisibility,
          columnOrder: [...validOrderedColumns, ...newColumns],
          sorting: parsedConfig.sorting || defaultConfig.sorting,
        }
      }
    } catch (e) {
      console.warn('Failed to load table configuration:', e)
      // Fall back to default config on error
    }

    return defaultConfig
  })

  // Save configuration to localStorage when it changes
  useEffect(() => {
    try {
      localStorage.setItem(storageId, JSON.stringify(tableConfig))
    } catch (e) {
      console.warn('Failed to save table configuration:', e)
    }
  }, [tableConfig, storageId])

  // Create updater functions
  const setColumnVisibility = (
    updater: Record<string, boolean> | ((prev: Record<string, boolean>) => Record<string, boolean>),
  ) => {
    setTableConfig((prev) => ({
      ...prev,
      columnVisibility: typeof updater === 'function' ? updater(prev.columnVisibility) : updater,
    }))
  }

  const setColumnOrder = (newOrder: string[]) => {
    setTableConfig((prev) => ({
      ...prev,
      columnOrder: newOrder,
    }))
  }

  const setSortingState = (updater: any) => {
    setTableConfig((prev) => ({
      ...prev,
      sorting: typeof updater === 'function' ? updater(prev.sorting) : updater,
    }))
  }

  return [
    tableConfig,
    {
      setColumnVisibility,
      setColumnOrder,
      setSortingState,
    },
  ]
}
