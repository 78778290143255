import { ActionIcon, Affix, Text, ThemeIcon, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconPencil, IconPlus } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { Plan } from 'api/domain/entities/plan'
import { invalidatePlans, useListPlans, useUpdatePlanOrder } from 'api/query/plan'
import { AddPlanDrawer } from 'components/drawer/add.plan'
import { EditPlanDrawer } from 'components/drawer/edit.plan'
import { Loading } from 'components/loading/loading'
import { DnDTable } from 'components/table/dnd-table'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function Plans() {
  const { t } = useTranslation()
  const [opened, { open, close }] = useDisclosure(false)
  const { data: plans, isLoading: isLoadingPlans } = useListPlans()
  const { mutateAsync: updatePlanOrder } = useUpdatePlanOrder()
  const [planToEdit, setPlanToEdit] = useState<Plan | undefined>()
  const sortedPlans = useMemo(() => plans?.sort((a, b) => a.order - b.order), [plans])

  const columns: ColumnDef<Plan>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: t('plan.columns.name'),
      cell: (col) => <Text size="xs">{col.getValue() as string}</Text>,
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: t('plan.columns.description'),
      cell: (col) => <Text size="xs">{col.getValue() as string}</Text>,
    },
    {
      id: 'actions',
      header: t('plan.columns.actions'),
      cell: (p) => (
        <ThemeIcon
          size="md"
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setPlanToEdit(p.row.original)
          }}
        >
          <IconPencil data-testid={`edit-plan-${p.row.original.id}`} size={20} />
        </ThemeIcon>
      ),
    },
  ]

  async function updateOrders(data: Plan[]) {
    for (let i = 0; i < data.length; i++) {
      await updatePlanOrder({ ...data[i], order: i })
    }
    await invalidatePlans()
  }

  if (isLoadingPlans) {
    return <Loading size="lg" />
  }

  return (
    <>
      <Title mb={30} order={2}>
        {t('plan.title')}
      </Title>
      <DnDTable
        columns={columns}
        untaintedData={plans || []}
        data={sortedPlans || []}
        updateOrders={updateOrders}
        hidePagination
        exportTable={{
          filename: 'plans',
        }}
      />
      <Affix bottom={20} right={20}>
        <ActionIcon data-testid="add-plan" radius={100} display={opened ? 'none' : 'block'} size="xl" onClick={open}>
          <IconPlus size={17} />
        </ActionIcon>
      </Affix>
      <AddPlanDrawer opened={opened} close={close} />
      <EditPlanDrawer opened={!!planToEdit} close={() => setPlanToEdit(undefined)} plan={planToEdit} />
    </>
  )
}
