import { darken, MantineColor, MantineTheme, MantineThemeColors, parseThemeColor, rgba } from '@mantine/core'
import { MembershipStatus } from 'api/domain/entities/membership/membership-status'
import { MemberSource, UserSource } from 'api/dto/source'

export function getMembershipStatusColor(theme: MantineTheme, status: MembershipStatus) {
  let color: MantineColor = 'gray'
  switch (status) {
    case MembershipStatus.PENDING:
      color = 'blue'
      break
    case MembershipStatus.SUBSCRIBED:
      color = 'green'
      break
    case MembershipStatus.CANCELED:
      color = 'orange'
      break
    case MembershipStatus.NEEDS_VALIDATION:
      color = 'red'
      break
    case MembershipStatus.IN_PROGRESS:
      color = 'yellow'
      break
  }

  return getVariantColor(theme, color)
}

export function getMemberSourceColor(theme: MantineTheme, source: MemberSource) {
  let color = 'gray'
  switch (source) {
    case MemberSource.ADMIN:
      color = 'blue'
      break
    case MemberSource.IMPORTED:
      color = 'purple'
      break
    case MemberSource.SELF_REGISTERED:
      color = 'green'
      break
  }

  return getVariantColor(theme, color)
}

export function getUserSourceColor(theme: MantineTheme, source: UserSource) {
  let color = 'gray'
  switch (source) {
    case UserSource.ADMIN:
      color = 'blue'
      break
    case UserSource.IMPORTED:
      color = 'purple'
      break
    case UserSource.SELF_REGISTERED:
      color = 'green'
      break
    case UserSource.MEMBER:
      color = 'orange'
      break
  }

  return getVariantColor(theme, color)
}

export function getNeedsValidationColor(theme: MantineTheme) {
  const parsedColor = parseThemeColor({
    color: 'orange',
    theme,
  })

  return {
    background: rgba(parsedColor.value, 0.1),
    hover: rgba(parsedColor.value, 0.15),
    border: `1px solid ${parsedColor.value}`,
    color: darken(parsedColor.value, 0.1),
  }
}

export function getVariantColor(theme: MantineTheme, color: MantineColor) {
  const parsedColor = parseThemeColor({
    color,
    theme,
  })

  return {
    background: rgba(parsedColor.value, 0.1),
    hover: rgba(parsedColor.value, 0.15),
    border: `1px solid ${parsedColor.value}`,
    color: darken(parsedColor.value, 0.1),
  }
}
