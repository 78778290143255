import { Button, Group, Modal, Text } from '@mantine/core'
import { Category } from 'api/domain/entities/category'
import { useTranslation } from 'react-i18next'

interface DeleteCategoryModalProps {
  isOpen: boolean
  onClose: () => void
  category?: Category
  onDelete: () => void
}

export function DeleteCategoryModal({ isOpen, onDelete, category, onClose }: DeleteCategoryModalProps) {
  const { t } = useTranslation()

  return (
    <Modal title="Delete member" opened={isOpen} onClose={onClose} size="sm">
      <Text>
        {t('category.are-you-sure-delete-category', {
          category: category?.name,
        })}
      </Text>

      <Group mt={20} justify="flex-end">
        <Button variant="outline" size="xs" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button data-testid="confirm-category-delete" variant="outline" size="xs" color="red" onClick={onDelete}>
          {t('common.delete')}
        </Button>
      </Group>
    </Modal>
  )
}
