/* eslint-disable @typescript-eslint/naming-convention */
import { Group, Text, ThemeIcon, UnstyledButton, useMantineColorScheme } from '@mantine/core'
import { IconSettings } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

interface LinksProps {
  icon: React.ReactNode
  color?: string
  trans: string
  href: string
}

function MainLink({ icon, color, trans: trans, href }: LinksProps) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { colorScheme } = useMantineColorScheme()
  const navigate = useNavigate()
  const isActive = pathname.includes(href)

  return (
    <UnstyledButton
      style={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },

        backgroundColor: isActive ? (colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]) : 'unset',
      })}
      onClick={() => navigate(href)}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{t(trans)}</Text>
      </Group>
    </UnstyledButton>
  )
}

export function AdminLinks() {
  return <MainLink icon={<IconSettings size="1rem" />} trans="common.menu.admin" href="/tenants/me" />
}
