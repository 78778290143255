/* eslint-disable @typescript-eslint/naming-convention */
import { lighten } from '@mantine/core'
import { createStyles, getStylesRef } from '@mantine/emotion'

export const useSearchBarStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },

  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #dfe1e5',
    borderRadius: theme.radius.md,
    padding: '10px 16px',
    backgroundColor: theme.white,
    height: '46px',
    transition: 'box-shadow 200ms ease',
    '&:focus-within': {
      backgroundColor: 'white',
      border: '1px solid #9aa0a6',
    },

    position: 'relative',

    zIndex: 11,
  },

  searchIcon: {
    color: '#5f6368',
    marginRight: theme.spacing.xs,
  },

  filterTagsGroup: {
    marginLeft: theme.spacing.xs,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
  },

  filterBadge: {
    padding: '0 8px',
    height: '24px',
    lineHeight: '24px',
    fontWeight: 500,
    fontSize: '13px',

    backgroundColor: lighten(theme.colors[theme.primaryColor][9], 0.3),
    borderRadius: theme.radius.md,
    border: `1px solid ${lighten(theme.colors[theme.primaryColor][5], 0.1)}`,
  },

  clearIcon: {
    cursor: 'pointer',
    color: '#70757a',
    '&:hover': {
      color: '#202124',
    },
  },

  closeIcon: {
    cursor: 'pointer',
    color: '#fff',
  },

  searchInput: {
    ref: getStylesRef('searchInput'),
    fontSize: '14px',
    height: '24px',
    minHeight: '24px',
    marginLeft: theme.spacing.xs,
    width: '100%',
    '&::placeholder': {
      color: '#70757a',
    },
  },

  textInputRoot: {
    flex: 1,
  },

  dropdown: {
    position: 'fixed',
    top: 'calc(var(--search-bar-height, 46px) + 0px)', // Seamless connection
    left: 0,
    width: '100%',
    zIndex: 10,
    border: '1px solid #dfe1e5',
    borderTop: 'none', // Create a continuous look
    backgroundColor: theme.white,
    marginTop: 0,
    maxHeight: '450px',
    overflow: 'hidden',
    borderBottomRightRadius: theme.radius.md,
    borderBottomLeftRadius: theme.radius.md,
  },

  // New section container for grouping results
  sectionContainer: {
    borderBottom: '1px solid #e8eaed',
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  // Content area within a section
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
  },

  dropdownLabel: {
    fontSize: '13px',
    fontWeight: 500,
    padding: '8px 16px',
    color: '#70757a',
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #e8eaed',
  },

  dropdownItem: {
    padding: '8px 16px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'background-color 100ms ease',
    borderBottom: '1px solid #f1f3f4',
    '&:hover': {
      backgroundColor: '#f1f3f4',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  dropdownText: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#202124',
  },

  dropdownSubtext: {
    fontSize: '12px',
    color: '#70757a',
    marginTop: 4,
  },

  suggestionBox: {
    padding: '10px 16px',
    borderBottom: '1px solid #f1f3f4',
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  suggestionText: {
    fontSize: '13px',
    fontWeight: 500,
    color: '#202124',
    marginBottom: '8px',
  },

  badgeGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px', // Increased spacing between badges
    marginTop: '6px',
  },

  suggestionBadge: {
    cursor: 'pointer',
    fontWeight: 400,
    border: '1px solid #e0e0e0', // Add subtle border
    '&:hover': {
      backgroundColor: '#e8eaed',
      border: '1px solid #d0d0d0',
    },

    textTransform: 'capitalize',
  },

  suggestionBadgeContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '4px 8px', // Reduced padding to avoid clutter
    borderRadius: '0', // Remove rounded corners
    backgroundColor: '#f1f3f4',
    border: '1px solid #e0e0e0',
    '&:hover': {
      backgroundColor: '#e8eaed',
      border: '1px solid #d0d0d0',
    },
  },

  scrollArea: {
    maxHeight: '450px',
  },
}))
