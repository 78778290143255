import { ActionIcon, Badge, Group, Text, Tooltip } from '@mantine/core'
import { IconDownload, IconEdit, IconTrash } from '@tabler/icons-react'
import { useListDocuments, useDeleteDocument, useGetDocumentUrl } from 'api/query/document'
import { useListPlans } from 'api/query/plan'
import { Loading } from 'components/loading/loading'
import { useTranslation } from 'react-i18next'
import { DocumentAccess, DocumentStatus } from 'api/dto/document-dto'
import { useState } from 'react'
import { EditDocumentModal } from './edit-document-modal'
import { Document } from 'api/domain/entities/document'
import { DeleteDocumentModal } from 'components/modal/delete.document'
import { Table } from 'components/table/table'
import { ColumnDef } from '@tanstack/react-table'
import { DateTime, DateTimeFormat } from 'utils/date-time'

export function DocumentList() {
  const { t } = useTranslation()
  const { data: documents, isLoading } = useListDocuments()
  const { data: plans, isLoading: plansLoading } = useListPlans()
  const [editingDocument, setEditingDocument] = useState<Document | null>(null)
  const [documentToDelete, setDocumentToDelete] = useState<Document | null>(null)
  const [downloadingDocumentIds, setDownloadingDocumentIds] = useState<string[]>([])
  const getDocumentUrl = useGetDocumentUrl()
  const deleteDocument = useDeleteDocument()

  if (isLoading) {
    return <Loading />
  }

  const handleEdit = (document: Document) => {
    setEditingDocument(document)
  }

  const handleDelete = (document: Document) => {
    setDocumentToDelete(document)
  }

  const confirmDelete = () => {
    if (documentToDelete) {
      deleteDocument.mutate(documentToDelete.id, {
        onSuccess: () => {
          setDocumentToDelete(null)
        },
      })
    }
  }

  const handleDownload = (document: Document) => {
    setDownloadingDocumentIds((prev) => [...prev, document.id])

    getDocumentUrl.mutate(document.id, {
      onSuccess: (url) => {
        // Open the download URL in a new tab
        window.open(url, '_blank')
        setDownloadingDocumentIds((prev) => prev.filter((id) => id !== document.id))
      },
      onError: () => {
        setDownloadingDocumentIds((prev) => prev.filter((id) => id !== document.id))
      },
    })
  }

  const getDocumentStatus = (document: Document) => {
    switch (document.status) {
      case DocumentStatus.AVAILABLE:
        return { label: t('document.status.available'), color: 'green' }
      case DocumentStatus.PENDING:
        return { label: t('document.status.pending'), color: 'orange' }
      case DocumentStatus.ERROR:
        return { label: t('document.status.error'), color: 'red' }
      default:
        return { label: document.status, color: 'gray' }
    }
  }

  const columns: ColumnDef<Document>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: t('document.columns.name'),
      cell: (info) => (
        <Group gap="xs">
          <Text>{info.getValue() as string}</Text>
        </Group>
      ),
    },
    {
      id: 'fileSize',
      accessorFn: (row) => row.getFormattedFileSize(),
      header: t('document.columns.size'),
      cell: (info) => <Text size="sm">{info.getValue() as string}</Text>,
    },
    {
      id: 'accessType',
      accessorFn: (row) => row.getAccessTypeDisplay(),
      header: t('document.columns.access'),
      cell: (info) => {
        const document = info.row.original
        const accessType = document.accessType

        if (accessType === DocumentAccess.ALL_MEMBERS) {
          return <Badge color="blue">{t('document.access.all-members')}</Badge>
        }

        if (accessType === DocumentAccess.SPECIFIC_MEMBERSHIPS) {
          const membershipIds = document.accessIds || []

          // No memberships selected
          if (membershipIds.length === 0) {
            return <Badge color="orange">{t('document.access.no-memberships')}</Badge>
          }

          if (!plans || plansLoading) {
            return <Badge color="orange">{t('document.access.loading-memberships')}</Badge>
          }

          // Find plan names for the selected membership IDs
          const planNames = membershipIds
            .map((id) => plans.find((plan) => plan.id === id)?.name)
            .filter(Boolean) as string[]

          if (planNames.length === 0) {
            return <Badge color="orange">{t('document.access.no-memberships')}</Badge>
          }

          // Display first 2 plan names and a +X more badge if needed
          return (
            <Group gap="xs">
              {planNames.slice(0, 2).map((name, i) => (
                <Badge key={i} color="orange">
                  {name}
                </Badge>
              ))}
              {planNames.length > 2 && <Badge color="gray">+{planNames.length - 2}</Badge>}
            </Group>
          )
        }

        // Default fallback - just show the display value
        return <Badge color="orange">{info.getValue() as string}</Badge>
      },
    },
    {
      id: 'status',
      header: t('document.columns.status'),
      cell: (info) => {
        const status = getDocumentStatus(info.row.original)
        return <Badge color={status.color}>{status.label}</Badge>
      },
    },
    {
      id: 'createdAt',
      accessorFn: (row) => DateTime.fromISOString(row.createdAt).toFormat(DateTimeFormat.YearMonthAndDay),
      header: t('document.columns.created'),
      cell: (info) => <Text size="sm">{info.getValue() as string}</Text>,
    },
    {
      id: 'actions',
      header: t('common.actions'),
      cell: (info) => (
        <Group gap="xs">
          <Tooltip label={t('document.download')}>
            <ActionIcon
              color="blue"
              onClick={() => handleDownload(info.row.original)}
              disabled={downloadingDocumentIds.includes(info.row.original.id)}
              loading={downloadingDocumentIds.includes(info.row.original.id)}
            >
              <IconDownload size="1rem" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('common.edit')}>
            <ActionIcon color="blue" onClick={() => handleEdit(info.row.original)}>
              <IconEdit size="1rem" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('common.delete')}>
            <ActionIcon color="red" onClick={() => handleDelete(info.row.original)}>
              <IconTrash size="1rem" />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        data={documents || []}
        hidePagination
        exportTable={{
          filename: 'documents',
        }}
      />

      {editingDocument && <EditDocumentModal document={editingDocument} onClose={() => setEditingDocument(null)} />}
      <DeleteDocumentModal
        isOpen={!!documentToDelete}
        document={documentToDelete || undefined}
        onClose={() => setDocumentToDelete(null)}
        onDelete={confirmDelete}
      />
    </>
  )
}
