import { ActionIcon, Alert, Checkbox, Divider, NumberInput, Select, Textarea } from '@mantine/core'
import { Box, Button, Drawer, Group, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconArrowDown, IconArrowUp, IconInfoCircle, IconPlus, IconX } from '@tabler/icons-react'
import { CommitmentType } from 'api/domain/entities/plan'
import { Price } from 'api/domain/entities/plan/price'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { PlanFactory } from 'api/dto/plan'
import { useCreatePlan, useListPlans } from 'api/query/plan'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AddPlanProps {
  opened: boolean
  close: () => void
}

type PartialPlan = {
  name: string
  description: string
  monthlyPrice?: Price
  yearlyPrice?: Price
  availableCommitmentTypes: CommitmentType[]
}

export function AddPlanDrawer({ opened, close }: AddPlanProps) {
  const { t } = useTranslation()
  const { mutateAsync: createPlan } = useCreatePlan()
  const { data: plans } = useListPlans()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Calculate available commitment types based on price selections
  const calculateCommitmentTypes = (
    hasMonthly: boolean,
    hasYearly: boolean,
    allowMonthlyWithYearlyCommitment: boolean = false,
  ) => {
    const types: CommitmentType[] = []

    if (hasMonthly) {
      types.push(CommitmentType.MONTHLY)
    }

    if (hasYearly) {
      types.push(CommitmentType.YEARLY)
    }

    // Only add this option if both monthly price exists and user has opted in to this option
    if (hasMonthly && allowMonthlyWithYearlyCommitment) {
      types.push(CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT)
    }

    return types
  }

  // Initialize form with default monthly commitment type
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      mostPopular: false,
      tenantId: '',
      monthlyPrice: {
        amount: 0,
        currency: 'CAD',
        interval: PriceInterval.MONTHLY,
        paymentProviderId: '',
        commitmentType: CommitmentType.MONTHLY,
      },
      monthlyCommitmentType: CommitmentType.MONTHLY,
      hasMonthlyPrice: false,
      yearlyPrice: {
        amount: 0,
        currency: 'CAD',
        interval: PriceInterval.YEARLY,
        paymentProviderId: '',
        commitmentType: CommitmentType.YEARLY,
      },
      hasYearlyPrice: false,
      features: [] as string[],
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.name || values.name.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.description || values.description.length < 1) {
        errors.description = t('common.required')
      }

      if (!values.hasMonthlyPrice && !values.hasYearlyPrice) {
        errors.hasMonthlyPrice = t('common.required')
        errors.hasYearlyPrice = t('common.required')
      }

      return errors
    },
  })

  // Effect to automatically update commitmentTypes when price options change
  useEffect(() => {
    const { hasMonthlyPrice, hasYearlyPrice, monthlyCommitmentType } = form.values
    // When form values change, calculate commitment types automatically
    const types: CommitmentType[] = []

    if (hasMonthlyPrice) {
      // Add the selected monthly commitment type
      types.push(monthlyCommitmentType)
    }

    if (hasYearlyPrice) {
      types.push(CommitmentType.YEARLY)
    }

    // Update available commitment types in the form whenever the price options change
    if (types.length > 0) {
      form.setFieldValue('availableCommitmentTypes', types)
    }
  }, [form.values.hasMonthlyPrice, form.values.hasYearlyPrice, form.values.monthlyCommitmentType])

  async function onSubmit(values: typeof form.values) {
    setIsSubmitting(true)

    // Calculate commitment types based on selected prices and commitment type
    const types: CommitmentType[] = []

    if (values.hasMonthlyPrice) {
      types.push(values.monthlyCommitmentType)
    }

    if (values.hasYearlyPrice) {
      types.push(CommitmentType.YEARLY)
    }

    // Create the plan object with the appropriate structure
    const plan: PartialPlan = {
      name: values.name,
      description: values.description,
      availableCommitmentTypes: types, // Use calculated commitment types
    }

    if (values.hasMonthlyPrice) {
      // Set the commitment type from the dropdown selection
      plan.monthlyPrice = {
        ...values.monthlyPrice,
        commitmentType: values.monthlyCommitmentType,
      }
    }

    if (values.hasYearlyPrice) {
      plan.yearlyPrice = values.yearlyPrice
    }

    const createPlanDto = PlanFactory.toDto({
      ...plan,
      tenantId: values.tenantId || 'default', // This should be set properly in a real implementation
      order: plans?.length,
      mostPopular: values.mostPopular,
      features: values.features,
    })

    try {
      await createPlan(createPlanDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    close()
    form.reset()
    setIsSubmitting(false)
  }

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('plan.add')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('plan.add-description')}</Text>
              <TextInput mt={10} withAsterisk w="100%" label={t('plan.fields.name')} {...form.getInputProps('name')} />
              <Textarea
                mt={10}
                rows={3}
                withAsterisk
                w="100%"
                label={t('plan.fields.description')}
                {...form.getInputProps('description')}
              />

              <Select
                mt={10}
                label={t('plan.fields.included-plan')}
                data={plans?.map((plan) => ({
                  value: plan.id,
                  label: plan.name,
                }))}
                {...form.getInputProps('includedPlanId')}
              />

              <Checkbox
                mt={20}
                label={t('plan.fields.most-popular')}
                {...form.getInputProps('mostPopular')}
                checked={form.values.mostPopular}
                onChange={(event) => form.setFieldValue('mostPopular', event.currentTarget.checked)}
              />

              <Box display="flex" pb={6} sx={{ alignItems: 'center' }} mt={20}>
                <Text size="xl" fw={600}>
                  {t('plan.features')}
                </Text>

                <ActionIcon
                  data-testid="add-feature"
                  ml={10}
                  size="sm"
                  onClick={() => {
                    form.insertListItem('features', '')
                  }}
                >
                  <IconPlus size={16} />
                </ActionIcon>
              </Box>
              <Divider />
              <Text size="xs" fw={400} pb={10} mt={15} mb={15}>
                {t('plan.feature-explanations')}
              </Text>
              {form.getValues().features.map((_, index) => (
                <Box mt={10} display="flex" sx={{ gap: 5, alignItems: 'center' }}>
                  <Box>
                    <ActionIcon
                      size="sm"
                      disabled={index === 0}
                      onClick={() => {
                        if (index > 0) {
                          form.reorderListItem('features', { from: index, to: index - 1 })
                        }
                      }}
                    >
                      <IconArrowUp size={16} />
                    </ActionIcon>
                    <ActionIcon
                      size="sm"
                      disabled={index === form.values.features.length - 1}
                      onClick={() => {
                        if (index < form.values.features.length - 1) {
                          form.reorderListItem('features', { from: index, to: index + 1 })
                        }
                      }}
                    >
                      <IconArrowDown size={16} />
                    </ActionIcon>
                  </Box>
                  <Textarea
                    data-testid={`feature-${index}`}
                    withAsterisk
                    rows={2}
                    w="100%"
                    {...form.getInputProps(`features.${index}`)}
                  />
                  <ActionIcon
                    onClick={() => {
                      form.removeListItem('features', index)
                    }}
                  >
                    <IconX size={20} />
                  </ActionIcon>
                </Box>
              ))}

              <Box component="section" mt={20}>
                <Text size="xl" fw={700} pb={10}>
                  {t('plan.pricing')}
                </Text>

                <Divider />

                <Text mt={15} mb={15} size="xs">
                  {t('plan.pricing-explanation')}
                </Text>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                  {/* Monthly Price Card */}
                  <Box sx={{ border: '1px solid #e9ecef', borderRadius: 8, padding: 16, background: 'white' }}>
                    <Text component="h4" fw={600} size="sm" pb={10}>
                      {t('plan.fields.monthly-price')}
                    </Text>

                    <Box display="flex" sx={{ gap: 10, alignItems: 'flex-start' }}>
                      <NumberInput
                        data-testid="monthly-price"
                        placeholder={t('plan.fields.price-placeholder')}
                        label={t('plan.fields.amount')}
                        onChange={(value) => {
                          const numValue = typeof value === 'string' ? parseFloat(value) : value || 0
                          const hasMonthlyPrice = numValue > 0
                          form.setFieldValue('hasMonthlyPrice', hasMonthlyPrice)
                          form.setFieldValue('monthlyPrice.amount', numValue)
                        }}
                        value={form.values.monthlyPrice.amount > 0 ? form.values.monthlyPrice.amount : undefined}
                        sx={{ flex: 2 }}
                      />
                      <TextInput
                        data-testid="monthly-price-currency"
                        disabled
                        label={t('plan.fields.currency')}
                        value={form.values.monthlyPrice.currency}
                        sx={{ flex: 1 }}
                      />
                    </Box>

                    {/* Monthly Commitment Type Dropdown */}
                    {form.values.hasMonthlyPrice && (
                      <Box mt={15}>
                        <Select
                          data-testid="monthly-commitment-type"
                          label={
                            <Text fw={500} size="sm">
                              {t('plan.commitment.type') || 'Commitment Type'}
                            </Text>
                          }
                          description={
                            t('plan.commitment.type-description') || 'Select the commitment term for monthly payments'
                          }
                          data={[
                            {
                              value: CommitmentType.MONTHLY,
                              label: t('plan.commitment.monthly'),
                            },
                            {
                              value: CommitmentType.MONTHLY_WITH_YEARLY_COMMITMENT,
                              label: t('plan.commitment.monthly-with-yearly-commitment'),
                            },
                          ]}
                          value={form.values.monthlyCommitmentType}
                          onChange={(value) => {
                            if (value) {
                              form.setFieldValue('monthlyCommitmentType', value as CommitmentType)
                            }
                          }}
                        />
                      </Box>
                    )}
                  </Box>

                  {/* Yearly Price Card */}
                  <Box sx={{ border: '1px solid #e9ecef', borderRadius: 8, padding: 16, background: 'white' }}>
                    <Text component="h4" fw={600} size="sm" pb={10}>
                      {t('plan.fields.yearly-price')}
                    </Text>

                    <Box display="flex" sx={{ gap: 10, alignItems: 'flex-start' }}>
                      <NumberInput
                        data-testid="yearly-price"
                        placeholder={t('plan.fields.price-placeholder')}
                        label={t('plan.fields.amount')}
                        onChange={(value) => {
                          const numValue = typeof value === 'string' ? parseFloat(value) : value || 0
                          const hasYearlyPrice = numValue > 0
                          form.setFieldValue('hasYearlyPrice', hasYearlyPrice)
                          form.setFieldValue('yearlyPrice.amount', numValue)
                        }}
                        value={form.values.yearlyPrice.amount > 0 ? form.values.yearlyPrice.amount : undefined}
                        sx={{ flex: 2 }}
                      />
                      <TextInput
                        data-testid="yearly-price-currency"
                        disabled
                        label={t('plan.fields.currency')}
                        value={form.values.yearlyPrice.currency}
                        sx={{ flex: 1 }}
                      />
                    </Box>

                    {/* Yearly commitment badge */}
                    {form.values.hasYearlyPrice && (
                      <Box mt={15}>
                        <Text size="xs" fw={500} c="dimmed">
                          {t('plan.commitment.includes')}
                        </Text>
                        <Box mt={5}>
                          <Box
                            sx={{ padding: '4px 8px', background: '#e6fcf5', borderRadius: 4, display: 'inline-block' }}
                          >
                            <Text size="xs" fw={500}>
                              {t('plan.commitment.yearly')}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              <Group mt={20} justify="flex-end">
                <Button disabled={!form.isValid() || isSubmitting} loading={isSubmitting} type="submit" size="xs">
                  {t('common.create')}
                </Button>
              </Group>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}
