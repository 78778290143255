/* eslint-disable @typescript-eslint/naming-convention */
import { rem } from '@mantine/core'
import { createStyles } from '@mantine/emotion'

export const useTenantNotFoundStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(80),
    paddingBottom: rem(80),
  },

  title: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: rem(38),
    color: theme.colors.red[6],
    marginBottom: theme.spacing.xl,
  },

  description: {
    maxWidth: rem(540),
    margin: 'auto',
    marginBottom: rem(24),
    textAlign: 'center',
  },

  controls: {
    marginTop: rem(24),
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing.md,
  },
}))
