import { DateTime } from 'utils/date-time'
import { Membership } from '../membership'
import { MemberDto } from 'api/dto'
import { Contact } from '../contact/contact'
import { MemberStatus } from './member-status'
import { Plan } from '../plan'
import dayjs from 'dayjs'
import { VisibilityStatus } from '../visibility'
import { validateAddress } from 'utils/address'
import { MemberSource } from 'api/dto/source'

export type Address = {
  formatted: string
  line1?: string
  city?: string
  country?: string
  state?: string
  postalCode?: string
}

export type PartialMember = {
  name?: string
  address?: Address
  phone?: string
  memberSince?: DateTime
  accountingEmail?: string
  visibilityStatus?: VisibilityStatus
  contact?: {
    firstName: string
    lastName: string
    email: string
    jobTitle?: string
  }
  note?: string
}

export class Member {
  id: string
  tenantId: string
  name: string
  address: Address
  phone: string
  memberSince: DateTime
  categoryIds: string[]
  membership?: Membership
  contact?: Contact
  status: MemberStatus
  accountingEmail?: string
  logo?: string
  note?: string
  createdAt?: string
  discount?: number
  visibilityStatus?: VisibilityStatus
  externalId?: string

  source?: MemberSource

  constructor(memberDto: MemberDto) {
    this.id = memberDto.id
    this.tenantId = memberDto.tenantId
    this.name = memberDto.name
    this.address = memberDto.address
    this.phone = memberDto.phone
    this.contact = memberDto.contact ? new Contact(memberDto.contact) : undefined
    this.memberSince = DateTime.fromISOString(memberDto.memberSince)
    this.categoryIds = memberDto.categoryIds
    // Get latest memberships by start date
    if (memberDto.memberships.length) {
      this.membership = new Membership(
        memberDto.memberships.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)))[0],
      )
    }
    this.status = memberDto.status
    this.accountingEmail = memberDto.accountingEmail
    this.logo = memberDto.logo
    this.note = memberDto.note
    this.createdAt = memberDto.createdAt
    this.discount = memberDto.discount
    this.visibilityStatus = memberDto.visibilityStatus
    this.externalId = memberDto.externalId

    this.source = memberDto.source
  }

  isActive() {
    return this.status === MemberStatus.ACTIVE || !this.status
  }

  isInactive() {
    return this.status === MemberStatus.INACTIVE
  }

  currentPlan(plans: Plan[]) {
    return plans.find((plan) => plan.id === this.membership?.planId)
  }

  get hasMembership() {
    return !!this.membership && this.membership.isSubscribed()
  }

  get hasUnpaidMembership() {
    return this.membership?.hasUnpaidMembership
  }

  get canChangeSubscription() {
    return !this.membership?.subscriptionScheduleId && !this.hasUnpaidMembership
  }

  get stateAndCountry() {
    if (!this.address) return ''

    if (this.address.state && this.address.country) {
      return `${this.address.state}, ${this.address.country}`
    }

    return this.address.state || this.address.country || ''
  }

  // Add helper method to check if member needs to complete their profile
  needsProfileCompletion(): boolean {
    // Only validate members with external payment type
    if (!this.membership?.paymentType || !this.membership.isExternal) {
      return false
    }

    // Check if address fields are filled
    const hasValidAddress = validateAddress(this.address)

    return !hasValidAddress || !this.phone || !this.accountingEmail
  }
}
