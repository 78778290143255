/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState, useRef, ReactNode, ComponentType, useMemo, KeyboardEvent } from 'react'
import { Box, TextInput, Text, Badge, Paper, ScrollArea, Group, useMantineTheme, Transition, Pill } from '@mantine/core'
import { IconSearch, IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { Row } from '@tanstack/react-table'
import { useSearchBarStyles } from './enhanced-search-bar.styles'
import { useDebouncedValue, useClickOutside } from '@mantine/hooks'
import { rankItem } from '@tanstack/match-sorter-utils'

export interface FilterTag {
  id: string
  column: string
  value: string
  label: string
  renderComponent?: React.ComponentType<any>
}

export interface FilterValue {
  value: string
  label?: string
}

// Define a simpler interface for the filterable columns
export interface FilterableColumn {
  id: string
  header: string | React.ReactNode
  values: (string | FilterValue)[]
  isSuggestion?: boolean
  compatibleFields?: string[]
  renderComponent?: React.ComponentType<any>
  filterBySearchbar?: {
    options?: { label: string; value: any }[]
  }
}

export interface EnhancedSearchBarProps<T extends object> {
  filteredRows: Row<T>[]
  allRows: Row<T>[]
  globalFilter: string | number | readonly string[] | undefined
  setGlobalFilter: (value: string) => void
  filterableColumns?: FilterableColumn[]
}

export function EnhancedSearchBar<T extends object>(props: EnhancedSearchBarProps<T>) {
  // Common filter options
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const { classes } = useSearchBarStyles()
  const { setGlobalFilter, allRows, filterableColumns = [] } = props

  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch] = useDebouncedValue(searchValue, 350)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [activeFilters, setActiveFilters] = useState<FilterTag[]>([])
  const [debouncedFilters] = useDebouncedValue(activeFilters, 500)
  const [currentOperator, setCurrentOperator] = useState<string | null>(null)
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 })

  const searchInputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const searchContainerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useClickOutside(() => setDropdownVisible(false), null, [containerRef.current])

  // Update dropdown position when search bar position changes
  useEffect(() => {
    function updateDropdownPosition() {
      if (searchContainerRef.current) {
        const rect = searchContainerRef.current.getBoundingClientRect()
        // Set CSS variable for sticky dropdown
        document.documentElement.style.setProperty('--search-bar-height', `${rect.height}px`)

        setDropdownPosition({
          top: rect.bottom - 4,
          left: rect.left,
          width: rect.width,
        })
      }
    }

    // Initial position update
    updateDropdownPosition()

    // Update position on scroll and resize with performance throttling
    const throttledUpdate = () => {
      let ticking = false
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateDropdownPosition()
          ticking = false
        })
        ticking = true
      }
    }

    window.addEventListener('scroll', throttledUpdate)
    window.addEventListener('resize', throttledUpdate)

    return () => {
      window.removeEventListener('scroll', throttledUpdate)
      window.removeEventListener('resize', throttledUpdate)
    }
  }, [])

  // Get available filterable columns
  const availableColumns = useMemo(() => filterableColumns.filter((col) => col.filterBySearchbar), [filterableColumns])

  // Extract unique values from filterable columns for suggestions - limit to 5 options per column
  const filterSuggestions = useMemo(
    () =>
      availableColumns
        .map((column) => {
          if (column.isSuggestion && column.values.length > 0) {
            return {
              id: column.id,
              header: column.header,
              values: column.values,
              renderComponent: column.renderComponent,
            }
          }
        })
        .filter(Boolean) as FilterableColumn[],
    [availableColumns],
  )

  // Apply filters whenever debounced values change
  useEffect(() => {
    if (debouncedFilters.length > 0 || debouncedSearch.trim() !== '') {
      // Create a combined filter object that includes both active filters and current search
      const filterObject = {
        search: debouncedSearch.trim(),
        filters: debouncedFilters,
      }

      // Pass the complete filter object to the global filter
      setGlobalFilter(JSON.stringify(filterObject))
    } else {
      setGlobalFilter('')
    }
  }, [debouncedFilters, debouncedSearch, setGlobalFilter])

  // For direct application (like on Enter key press)
  const applySearchFilter = () => {
    if (activeFilters.length > 0 || searchValue.trim() !== '') {
      // Create a combined filter object that includes both active filters and current search
      const filterObject = {
        search: searchValue.trim(),
        filters: activeFilters,
      }

      // Pass the complete filter object to the global filter
      setGlobalFilter(JSON.stringify(filterObject))
    } else {
      setGlobalFilter('')
    }
  }

  // Handle key down events - specifically for Backspace key to remove labels when search is empty
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Check if backspace is pressed and search text is empty
    if (event.key === 'Backspace' && searchValue === '') {
      // If there are active filters, remove the last one
      if (activeFilters.length > 0) {
        const lastFilter = activeFilters[activeFilters.length - 1]
        removeFilterTag(lastFilter.id)
      }
    }

    if (event.key === 'Enter') {
      applySearchFilter()
      // Optionally close the dropdown after search is applied
      setDropdownVisible(false)
    }
  }

  // Parse input to detect operators like "column:"
  const handleInputChange = (value: string) => {
    setSearchValue(value)

    // Show dropdown when typing
    if (value.trim() !== '') {
      setDropdownVisible(true)
    } else {
      setDropdownVisible(false)
    }

    // Detect if user is typing an operator (e.g. "column:")
    const operatorMatch = value.match(/(\w+):$/)
    if (operatorMatch) {
      setCurrentOperator(operatorMatch[1])
    } else {
      setCurrentOperator(null)
    }
  }

  // Handle adding a filter tag
  const addFilterTag = (column: string, value: string, label: string, renderComponent?: React.ComponentType<any>) => {
    // Check if this filter already exists
    const filterExists = activeFilters.some((filter) => filter.column === column && filter.value === value)

    if (!filterExists) {
      const newFilter: FilterTag = {
        id: `${column}-${value}`,
        column,
        value,
        label,
        renderComponent,
      }

      setActiveFilters([...activeFilters, newFilter])
      setSearchValue('')
      setDropdownVisible(false)
      // No need for setTimeout - useEffect with debouncedFilters will handle this
    }
  }

  // Handle removing a filter tag
  const removeFilterTag = (filterId: string) => {
    setActiveFilters(activeFilters.filter((filter) => filter.id !== filterId))
    // No need for setTimeout - useEffect with debouncedFilters will handle this
  }

  // Handle selecting a suggestion from the dropdown
  const handleSuggestionClick = (
    column: string,
    value: string | FilterValue,
    header: ReactNode,
    renderComponent?: React.ComponentType<any>,
  ) => {
    const headerString = typeof header === 'string' ? header : column
    const valueString = typeof value === 'string' ? value : value.value
    const labelString = typeof value === 'string' ? value : value.label || value.value

    addFilterTag(column, valueString, `${headerString}: ${labelString}`, renderComponent)
  }

  // Find search matches from all rows, limited to 7 results
  const getSearchMatches = () => {
    if (!searchValue.trim()) return []

    // Extract a sample of matched rows
    const matches: { id: string; value: string; column: string; header: string | ReactNode }[] = []
    const searchLower = searchValue.toLowerCase()
    const maxResults = 7

    // Create a set of column IDs already in suggestions for faster lookups
    const suggestionIds = new Set(filterSuggestions.map((s) => s.id))

    // Find columns that we can search in
    const searchableColumns = availableColumns.filter((col) => !suggestionIds.has(col.id))

    // Search through rows until we reach maxResults
    for (const row of allRows) {
      if (matches.length >= maxResults) break

      const rowData = row.original as Record<string, any>

      // Check each searchable column
      for (const column of searchableColumns) {
        if (matches.length >= maxResults) break

        const fieldId = column.id
        const value = rowData[fieldId]

        // Check if this is a compatible field we should search
        const isCompatible = !column.compatibleFields?.length || column.compatibleFields.includes(fieldId)

        if (
          isCompatible &&
          typeof value === 'string' &&
          rankItem(value.toLowerCase(), searchLower, { threshold: 2 }).passed
        ) {
          matches.push({
            id: `${row.id}-${fieldId}`,
            value,
            column: fieldId,
            header: column.header,
          })
        }
      }
    }

    return matches
  }

  // Render dropdown content based on current input
  const renderDropdownContent = () => {
    // If user is typing a column operator, show column value suggestions
    if (currentOperator) {
      const matchingColumn = availableColumns.find((col) => col.id?.toLowerCase() === currentOperator.toLowerCase())

      if (matchingColumn) {
        return (
          <>
            <Text className={classes.dropdownLabel}>
              {t('common.filter-by', 'Filter by')} {matchingColumn.header?.toString().toLowerCase()}:
            </Text>
            {matchingColumn.values.slice(0, 7).map((value, idx) => {
              const valueString = typeof value === 'string' ? value : value.value
              const labelString = typeof value === 'string' ? value : value.label || value.value

              // Use custom component if provided
              if (matchingColumn.renderComponent) {
                const CustomComponent = matchingColumn.renderComponent
                return (
                  <Box
                    key={idx}
                    className={classes.dropdownItem}
                    onClick={() =>
                      handleSuggestionClick(matchingColumn.id, value, matchingColumn.header, CustomComponent)
                    }
                  >
                    <CustomComponent
                      value={valueString}
                      label={typeof value === 'string' ? undefined : value.label}
                      onClick={() =>
                        handleSuggestionClick(matchingColumn.id, value, matchingColumn.header, CustomComponent)
                      }
                    />
                  </Box>
                )
              }

              return (
                <Box
                  key={idx}
                  className={classes.dropdownItem}
                  onClick={() => handleSuggestionClick(matchingColumn.id, value, matchingColumn.header)}
                >
                  {labelString}
                </Box>
              )
            })}
          </>
        )
      }
    }

    // Group search matches by column
    const searchMatches = getSearchMatches()
    const hasMatches = searchMatches.length > 0

    // Create map to organize matches by column
    const matchesByColumn: Record<
      string,
      Array<{ id: string; value: string; column: string; header: string | ReactNode }>
    > = {}

    // Populate the map with matches
    if (hasMatches) {
      for (const match of searchMatches) {
        if (!matchesByColumn[match.column]) {
          matchesByColumn[match.column] = []
        }
        matchesByColumn[match.column].push(match)
      }
    }

    return (
      <>
        {/* Search matches grouped by column */}
        {hasMatches &&
          Object.entries(matchesByColumn).map(([column, matches]) => (
            <Box key={column} className={classes.sectionContainer}>
              <Text className={classes.dropdownLabel}>{matches[0].header}</Text>
              <Box className={classes.sectionContent}>
                {matches.map((match) => (
                  <Box
                    key={match.id}
                    className={classes.dropdownItem}
                    onClick={() => {
                      // Apply the search on match click
                      setSearchValue(match.value)
                      applySearchFilter()
                      setDropdownVisible(false)
                    }}
                  >
                    <Text className={classes.dropdownText} truncate>
                      {match.value}
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}

        {/* Filter suggestions - only show if we have a limited number of matches */}
        {searchMatches.length < 4 && filterSuggestions.length > 0 && (
          <Box className={classes.sectionContainer}>
            <Text className={classes.dropdownLabel}>{t('common.filter-by', 'Filter by')}</Text>
            <Box className={classes.sectionContent}>
              {filterSuggestions.map((suggestion) => (
                <Box key={suggestion.id} className={classes.suggestionBox}>
                  <Text className={classes.suggestionText}>{suggestion.header}</Text>
                  <Group className={classes.badgeGroup}>
                    {suggestion.values.map((value, idx) => {
                      const valueString = typeof value === 'string' ? value : value.value
                      const labelString = typeof value === 'string' ? value : value.label || value.value

                      // Use custom component if provided
                      if (suggestion.renderComponent) {
                        const CustomComponent = suggestion.renderComponent
                        return (
                          <CustomComponent
                            value={valueString}
                            label={typeof value === 'string' ? undefined : value.label}
                            onClick={() =>
                              handleSuggestionClick(suggestion.id, value, suggestion.header, CustomComponent)
                            }
                          />
                        )
                      }

                      return (
                        <Pill
                          key={idx}
                          className={classes.suggestionBadge}
                          onClick={() => handleSuggestionClick(suggestion.id, value, suggestion.header)}
                        >
                          {labelString}
                        </Pill>
                      )
                    })}
                  </Group>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {/* If no content at all, show a message */}
        {!hasMatches && filterSuggestions.length === 0 && (
          <Box className={classes.dropdownItem}>
            <Text c="dimmed" size="sm" ta="center">
              {t('common.type-to-search', 'Type to search or press Enter to search')}
            </Text>
          </Box>
        )}
      </>
    )
  }

  return (
    <Box className={classes.root} ref={containerRef}>
      {/* Main search area */}
      <Box className={classes.searchContainer} ref={searchContainerRef}>
        <IconSearch className={classes.searchIcon} size={20} />

        {/* Active filter tags */}
        {activeFilters.length > 0 && (
          <Group className={classes.filterTagsGroup} style={{ gap: '4px' }}>
            {activeFilters.map((filter) => {
              // Use custom component if available
              if (filter.renderComponent) {
                const CustomComponent = filter.renderComponent
                return (
                  <CustomComponent
                    key={filter.id}
                    value={filter.value}
                    label={filter.label.split(': ')[1]} // Extract the value part after colon
                    onClick={() => removeFilterTag(filter.id)}
                  />
                )
              }

              // Default Badge component
              return (
                <Badge
                  size="sm"
                  key={filter.id}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    removeFilterTag(filter.id)
                  }}
                >
                  {filter.label.split(': ')[1]}
                </Badge>
              )
            })}
          </Group>
        )}

        {/* Search input */}
        <TextInput
          value={searchValue}
          onChange={(e) => handleInputChange(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={t('common.search', 'Search...')}
          onFocus={() => {
            if (searchValue.trim() !== '' || filterableColumns.length > 0) {
              setDropdownVisible(true)
            }
          }}
          ref={searchInputRef}
          variant="unstyled"
          classNames={{
            root: classes.textInputRoot,
            input: classes.searchInput,
          }}
        />

        {/* Clear button */}
        {searchValue && (
          <IconX
            size={18}
            className={classes.clearIcon}
            onClick={() => {
              setSearchValue('')
              setDropdownVisible(false)
              // Apply empty search immediately when clearing
              setGlobalFilter('')
            }}
          />
        )}
      </Box>

      {/* Dropdown with search suggestions */}
      <Transition mounted={dropdownVisible} transition="fade" duration={150}>
        {(styles) => (
          <Paper
            shadow="none"
            radius="none"
            withBorder
            className={classes.dropdown}
            ref={dropdownRef}
            style={{
              ...styles,
              // Apply dynamic positioning - fallback to CSS vars if not available
              top: dropdownPosition.top || undefined,
              left: dropdownPosition.left || undefined,
              width: dropdownPosition.width ? `${dropdownPosition.width}px` : '100%',
            }}
          >
            <ScrollArea scrollbarSize={6} type="auto" className={classes.scrollArea}>
              {renderDropdownContent()}
            </ScrollArea>
          </Paper>
        )}
      </Transition>
    </Box>
  )
}
