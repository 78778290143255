import { Button, Group, Modal, Select, Stack, TextInput, Textarea, MultiSelect, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { DocumentAccess } from 'api/dto/document-dto'
import { Document } from 'api/domain/entities/document'
import { useUpdateDocument } from 'api/query/document'
import { useListPlans } from 'api/query/plan'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'

interface EditDocumentModalProps {
  document: Document
  onClose: () => void
}

export function EditDocumentModal({ document, onClose }: EditDocumentModalProps) {
  const { t } = useTranslation()
  const updateDocument = useUpdateDocument()
  const { data: plans, isLoading: plansLoading } = useListPlans()
  const [membershipOptions, setMembershipOptions] = useState<{ value: string; label: string }[]>([])

  // Prepare membership options from plans
  useEffect(() => {
    if (plans) {
      const options = plans.map((plan) => ({
        value: plan.id,
        label: plan.name,
      }))
      setMembershipOptions(options)
    }
  }, [plans])

  const form = useForm({
    initialValues: {
      name: document.name,
      description: document.description || '',
      accessType: document.accessType,
      accessIds: document.accessIds,
    },
    validate: {
      name: (value) => (value ? null : t('common.required')),
    },
  })

  const handleSubmit = form.onSubmit((values) => {
    updateDocument.mutate(
      {
        id: document.id,
        document: values,
      },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  })

  return (
    <Modal opened={true} onClose={onClose} title={t('document.edit')} size="lg">
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextInput
            label={t('document.fields.name')}
            placeholder={t('document.fields.name')}
            required
            {...form.getInputProps('name')}
          />

          <Textarea
            label={t('document.fields.description')}
            placeholder={t('document.fields.description')}
            {...form.getInputProps('description')}
          />

          <Select
            label={t('document.fields.accessType')}
            placeholder={t('document.fields.accessType')}
            data={[
              { value: DocumentAccess.ALL_MEMBERS, label: t('document.access.all-members') },
              { value: DocumentAccess.SPECIFIC_MEMBERS, label: t('document.access.specific-members') },
              { value: DocumentAccess.SPECIFIC_MEMBERSHIPS, label: t('document.access.specific-memberships') },
            ]}
            required
            onChange={(value) => {
              form.setFieldValue('accessType', value as DocumentAccess)
              // Reset accessIds when changing access type
              form.setFieldValue('accessIds', [])
            }}
            value={form.values.accessType}
          />

          {form.values.accessType === DocumentAccess.SPECIFIC_MEMBERSHIPS && (
            <>
              {plansLoading ? (
                <Text size="sm" color="dimmed">
                  Loading memberships...
                </Text>
              ) : (
                <MultiSelect
                  label={t('document.fields.memberships')}
                  placeholder={t('document.fields.select-memberships')}
                  data={membershipOptions}
                  required
                  {...form.getInputProps('accessIds')}
                />
              )}
            </>
          )}

          <Group justify="flex-end">
            <Button variant="outline" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button type="submit" loading={updateDocument.isPending}>
              {t('common.save')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}
