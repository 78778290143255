import { useMemo, useState, ReactNode, useCallback, FC, memo } from 'react'
import { Box, Table as MantineTable, Text, TextInput, Select, ActionIcon, ActionIconProps } from '@mantine/core'
import { TableColumnManager, useTableConfig } from './table-column-manager'
import { EnhancedSearchBar, FilterableColumn } from './enhanced-search-bar'
import {
  useReactTable,
  Row,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  FilterFn,
  TableState,
  Column,
  Table as ReactTable,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { useTableStyles } from './table.styles'
import { IconArrowUp, IconArrowDown, IconSettings, IconDownload, IconBuildingSkyscraper } from '@tabler/icons-react'
import { DatePickerInput } from '@mantine/dates'
import dayjs from 'dayjs'
import { exportToCsv } from 'utils/export'

import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import { AugmentedColumn } from './column'

interface TableEmptyStateProps {
  message?: string
}

const TableEmptyState: FC<TableEmptyStateProps> = ({ message }) => {
  const { t } = useTranslation()
  return (
    <Box
      py={50}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Text size="sm" fw={500}>
        {message || t('common.no-data-available')}
      </Text>
    </Box>
  )
}

declare module '@tanstack/react-table' {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}
interface ITableProps<T extends object> {
  tableId?: string
  columns: AugmentedColumn<T>[]
  data: T[]
  setFilteredRows?: (rows: T[]) => void
  defaultSort?: { id: string; desc: boolean }
  columnVisibilityOverrides?: Record<string, boolean>
  actionButtons?: (rows: Row<T>[]) => ReactNode[]
  onRowClick?: (row: Row<T>) => void
  searchable?: boolean
  hidePagination?: boolean

  title?: React.ReactNode
  multiple?: boolean
  selected?: string | string[]

  exportTable?: {
    filename: string
    customParser?: (row: any) => any
  }

  emptyStateMessage?: string
}

// Define a default UI for filtering

export function Table<T extends object>(props: ITableProps<T>) {
  const { t } = useTranslation()
  const [globalFilter, setGlobalFilter] = useState('')
  const [filters, setFilters] = useState<Record<string, string[]>>({})
  const [rowSelection, setRowSelection] = useState({})
  const [columnManagerOpened, setColumnManagerOpened] = useState(false)

  const { classes, cx } = useTableStyles({
    hasFilters: Object.keys(filters).length > 0,
  })
  const {
    tableId,
    columns,
    data,
    actionButtons,
    defaultSort,
    columnVisibilityOverrides,
    onRowClick,
    searchable = true,
    multiple,
    exportTable,
    hidePagination,
    title,
  } = props

  // Set up table configuration with the useTableConfig hook
  const [tableConfig, { setColumnVisibility, setColumnOrder }] = useTableConfig({
    tableId,
    columnVisibilityOverrides,
    columns,
    defaultSort,
  })

  // Enhanced filter that supports both text search and column-specific filters
  const enhancedFilter: FilterFn<any> = (row, columnId, filterValue, addMeta) => {
    // If no filter value, return all rows
    if (!filterValue) return true

    try {
      // Try to parse the filter as JSON (new enhanced format)
      const filterObj = JSON.parse(filterValue as string)

      // Apply column-specific filters first
      if (filterObj.filters && filterObj.filters.length > 0) {
        for (const filter of filterObj.filters) {
          const cellValue = row.getValue(filter.column)

          // If the cell doesn't have a value, it doesn't match the filter
          if (cellValue === undefined || cellValue === null) {
            return false
          }

          // Simple equality check for now - could be enhanced for different operators
          const stringCellValue = String(cellValue).toLowerCase()
          const stringFilterValue = String(filter.value).toLowerCase()

          if (!stringCellValue.includes(stringFilterValue)) {
            return false
          }
        }
      }

      // Then apply the general search term if present
      if (filterObj.search && filterObj.search.trim() !== '') {
        let rowMatches = false

        // Check if any column in the row matches the search term
        row.getAllCells().forEach((cell) => {
          const value = cell.getValue()
          if (value !== undefined && value !== null) {
            const valueStr = String(value).toLowerCase()
            if (valueStr.includes(filterObj.search.toLowerCase())) {
              rowMatches = true
            }
          }
        })

        return rowMatches
      }

      return true
    } catch (e) {
      // If JSON parsing fails, fall back to the default fuzzy filter
      const itemRank = rankItem(row.getValue(columnId), filterValue)
      addMeta({ itemRank })
      return itemRank.passed
    }
  }

  const table = useReactTable<T>({
    filterFns: {
      fuzzy: enhancedFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: !hidePagination ? getPaginationRowModel() : undefined,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: multiple,
    columns,
    data,
    state: {
      globalFilter,
      rowSelection,
      columnVisibility: searchable ? tableConfig.columnVisibility : columnVisibilityOverrides,
      columnOrder: tableConfig.columnOrder,
    },
    globalFilterFn: 'fuzzy',
    initialState: {
      pagination: !hidePagination ? { pageSize: 10 } : undefined,
      columnVisibility: columnVisibilityOverrides,
      sorting: defaultSort ? [defaultSort] : [],
    },
    onColumnVisibilityChange: setColumnVisibility,
  })

  const {
    getHeaderGroups,
    getCanPreviousPage,
    getCanNextPage,
    nextPage,
    previousPage,
    getState,
    setPageSize,
    getRowModel,
  } = table

  // Helper function to get columns that can be filtered as enum-like values
  const getFilterableColumns = useCallback(() => {
    const filterableColumns: FilterableColumn[] = []
    const rows = getRowModel().rows

    columns.forEach((column) => {
      if (column.id && column.filterBySearchbar?.enabled) {
        // Get all unique values for this column
        const columnValues = new Set<string>()

        // Collect unique string values from the data
        rows.forEach((row) => {
          const value = row.getValue(column.id!)
          if (typeof value === 'string' && value.trim() !== '') {
            columnValues.add(value)
          }
        })

        // If we have a reasonable number of unique values, consider it enum-like
        const values = Array.from(columnValues)
        // Create a properly formatted FilterableColumn
        const filterableColumn: FilterableColumn = {
          id: column.id,
          header: typeof column.header === 'string' ? column.header : column.id,
          values: values,
          isSuggestion: column.filterBySearchbar.isSuggestion,
          renderComponent: column.filterBySearchbar.renderComponent,
          compatibleFields: column.filterBySearchbar.compatibleFields
            ? column.filterBySearchbar.compatibleFields
            : [column.id],
          filterBySearchbar: column.filterBySearchbar || {
            options: values.map((value) => ({ label: value, value })),
          },
        }

        filterableColumns.push(filterableColumn)
      }
    })

    return filterableColumns
  }, [columns, getRowModel])

  const columnsMap = useMemo(
    () =>
      columns
        .filter((c) => !!c.id)
        .map((c) => ({ [c.id!]: c }))
        .reduce((prev, val) => Object.assign(prev, val), {}),
    [columns],
  )

  const ExportButton = ({ ...props }: ActionIconProps) => {
    return (
      <ActionIcon
        disabled={getRowModel().rows.length === 0}
        onClick={() =>
          exportToCsv(
            getRowModel().rows.map((row) => {
              let currentRow: any = {}
              row.getAllCells().forEach((cell) => {
                const columnId = cell.column.id
                currentRow[columnId] = row.getValue(columnId)
              })

              if (exportTable?.customParser) {
                currentRow = exportTable.customParser(currentRow)
              }

              return currentRow
            }),
            `${exportTable?.filename}.csv`,
          )
        }
        {...props}
      >
        <IconDownload size={20} />
      </ActionIcon>
    )
  }

  return (
    <>
      {searchable && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <EnhancedSearchBar
            filteredRows={getRowModel().rows}
            allRows={table.getCoreRowModel().rows}
            globalFilter={getState().globalFilter}
            setGlobalFilter={setGlobalFilter}
            filterableColumns={getFilterableColumns()}
          />

          {exportTable && <ExportButton size={40} ml={10} />}

          {/* Column Manager Button */}
          <ActionIcon ml={10} size={40} onClick={() => setColumnManagerOpened(true)} title={t('common.manage-columns')}>
            <IconSettings size={20} />
          </ActionIcon>

          {actionButtons && (
            <Box
              ml={10}
              display="flex"
              sx={{
                alignItems: 'center',
              }}
            >
              {actionButtons(getRowModel().rows)}
            </Box>
          )}
        </Box>
      )}

      <Box data-testid="test-table" className={classes.container}>
        <MantineTable sx={{ borderSpacing: 0 }} highlightOnHover>
          <MantineTable.Thead>
            {getHeaderGroups().map((headerGroup) => (
              <MantineTable.Tr className={classes.header} key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const column = columnsMap[header.column.id]

                  return (
                    <MantineTable.Th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width: header.getSize(),
                        minWidth: column?.minSize,
                        maxWidth: column?.maxSize,
                      }}
                    >
                      <Box display="flex" sx={{ flexDirection: 'column' }}>
                        <Box
                          {...{
                            style: header.column.getCanSort()
                              ? {
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  userSelect: 'none',
                                }
                              : {},
                            className: classes.headerText,
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <IconArrowUp style={{ marginLeft: '.375rem' }} />,
                            desc: <IconArrowDown style={{ marginLeft: '.375rem' }} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </Box>
                      </Box>
                    </MantineTable.Th>
                  )
                })}
              </MantineTable.Tr>
            ))}
          </MantineTable.Thead>
          {getRowModel().rows.length > 0 ? (
            <MantineTable.Tbody>
              {getRowModel().rows.map((row) => {
                return (
                  <MantineTable.Tr
                    key={row.id}
                    sx={{
                      cursor: onRowClick ? 'pointer' : 'default',
                    }}
                    onClick={() => onRowClick?.(row)}
                  >
                    {row.getVisibleCells().map((cell) => {
                      let styles = {}
                      if (columnsMap[cell.column.id].maxSize) {
                        styles = {
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }
                      }

                      return (
                        <MantineTable.Td
                          key={cell.id}
                          className={classes.text}
                          style={{
                            ...styles,
                            verticalAlign: 'middle',
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </MantineTable.Td>
                      )
                    })}
                  </MantineTable.Tr>
                )
              })}
            </MantineTable.Tbody>
          ) : (
            <MantineTable.Tbody>
              <MantineTable.Tr>
                <MantineTable.Td colSpan={table.getAllColumns().length}>
                  <TableEmptyState message={props.emptyStateMessage} />
                </MantineTable.Td>
              </MantineTable.Tr>
            </MantineTable.Tbody>
          )}
        </MantineTable>
      </Box>

      {!props.hidePagination && (
        <Box className={classes.pagination}>
          <Box className={cx(classes.footerBox, 'start')}>
            <Box className={classes.footerBox}>
              <Text
                className={classes.footerText}
              >{`${getRowPosition(true, getState(), getRowModel().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('common.to')}
              </Text>
              <Text
                ml={3}
                className={classes.footerText}
              >{`${getRowPosition(false, getState(), getRowModel().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('common.of')}
              </Text>
              <Text ml={3} className={classes.footerText}>
                {getRowModel().rows.length}
              </Text>
            </Box>
            <Box>
              <Select
                size="sm"
                value={getState().pagination.pageSize.toString()}
                data={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                ]}
                onChange={(e) => setPageSize(Number(e))}
              />
            </Box>
          </Box>
          <Box className={classes.footerBox}>
            <Text
              className={cx(classes.footerText, classes.footerTextButton, getCanPreviousPage() ? 'active' : 'inactive')}
              sx={getCanPreviousPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanPreviousPage()) {
                  return
                }
                previousPage()
              }}
            >
              {t('common.previous')}
            </Text>
            <Text
              ml={21}
              className={cx(classes.footerText, classes.footerTextButton, getCanNextPage() ? 'active' : 'inactive')}
              sx={getCanNextPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanNextPage()) {
                  return
                }
                nextPage()
              }}
            >
              {t('common.next')}
            </Text>
          </Box>
        </Box>
      )}

      {/* Column Manager Modal - using only visible columns */}
      <TableColumnManager
        table={table}
        columns={columns}
        columnOrder={tableConfig.columnOrder}
        opened={columnManagerOpened}
        onClose={() => setColumnManagerOpened(false)}
        onColumnOrderChange={setColumnOrder}
      />
    </>
  )
}

function getRowPosition(firstRow: boolean, tableState: TableState, totalRowCount: number) {
  const {
    pagination: { pageIndex, pageSize },
  } = tableState

  const index = +pageIndex + 1

  if (index === 1) {
    if (totalRowCount === 0) {
      return 0
    }

    return firstRow ? 1 : index * pageSize > totalRowCount ? totalRowCount : index * pageSize
  }

  return firstRow ? pageIndex * pageSize : index * pageSize > totalRowCount ? totalRowCount : index * pageSize
}

function Filter({ column, table }: { column: Column<any, any>; table: ReactTable<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  if (typeof firstValue === 'string' && firstValue.includes('-') && dayjs(firstValue).isValid()) {
    return (
      <DatePickerInput
        mt={3}
        pr={6}
        size="xs"
        value={column.getFilterValue() as any}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={'DD-MM-YYYY'}
      />
    )
  }

  return typeof firstValue === 'number' ? (
    <Box mt={3} display="flex" pr={6}>
      <TextInput
        w={50}
        size="xs"
        type="number"
        value={((column.getFilterValue() as any)?.[0] ?? '') as string}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          column.setFilterValue((old: any) => [e.target.value, old?.[1]])
        }
      />
      <TextInput
        w={50}
        type="number"
        size="xs"
        ml={3}
        value={((column.getFilterValue() as any)?.[1] ?? '') as string}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          column.setFilterValue((old: any) => [old?.[0], e.target.value])
        }
      />
    </Box>
  ) : (
    <TextInput
      mt={3}
      pr={6}
      size="xs"
      type="text"
      value={(column.getFilterValue() ?? '') as string}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        column.setFilterValue(e.target.value)
      }}
      placeholder={'Search...'}
    />
  )
}
