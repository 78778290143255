import { UserRole } from 'api/domain/entities/user'

export interface ITokenUserDto {
  id?: string
  email: string
  isAdmin: boolean
  memberId?: string
  tenantId?: string
  role: UserRole
}

export class UserToken implements ITokenUserDto {
  id?: string
  email: string
  isAdmin: boolean
  memberId?: string
  tenantId?: string
  role: UserRole

  constructor(userToken: ITokenUserDto) {
    this.id = userToken.id
    this.email = userToken.email
    this.isAdmin = userToken.isAdmin
    this.memberId = userToken.memberId
    this.tenantId = userToken.tenantId
    this.role = userToken.role
  }

  hasRequiredRole(requiredRole: UserRole): boolean {
    return this.role === requiredRole
  }

  isMemberManager(): boolean {
    return this.role === UserRole.MEMBER_MANAGER
  }

  isMemberUser(): boolean {
    return this.role === UserRole.MEMBER_USER
  }

  isSuperAdmin(): boolean {
    return this.isAdmin
  }

  isTenantAdmin(): boolean {
    return this.role === UserRole.TENANT_ADMIN
  }
}
